import { FormEvent, useEffect, useState } from 'react'

import { Page } from '../../components'
import { getBackend } from '../../lib/icp'

const getParams = () => {
  const query = new URLSearchParams(window.location.search)

  return {
    videoId: query.get('videoId') || '',
    thumbnailTransactionId: query.get('thumbnailTransactionId') || '',
    videoTransactionId: query.get('videoTransactionId') || '',
    videoLength: query.get('videoLength') || '',
  }
}

const Migrate = () => {
  const params = getParams()
  const [videoId, setVideoId] = useState(params.videoId)
  const [thumbTid, setThumbTid] = useState(params.thumbnailTransactionId)
  const [videoTid, setVideoTid] = useState(params.videoTransactionId)
  const [videoLength, setVideoLength] = useState(params.videoLength)
  const [updating, setUpdating] = useState(false)
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    updateVideo()
  }, [])

  const updateVideo = async () => {
    if (videoId && thumbTid && videoTid) {
      setUpdating(true)
      const { updateVideoAssets } = await getBackend()
      const assets = [
        {
          category: 'hls',
          url: videoTid,
        },
      ]

      if (videoLength) {
        assets.push({
          category: 'length',
          url: videoLength,
        })
      }

      await updateVideoAssets(videoId, 'ar', thumbTid, assets)
      setUpdating(false)
      setVideoId('')
      setThumbTid('')
      setVideoTid('')
      setVideoLength('')
      setUpdated(true)
    }
  }

  const update = async (e: FormEvent) => {
    e.preventDefault()
    updateVideo()
  }
  return (
    <Page title="Migrate">
      <h1>Migrate Videos</h1>

      {updated && <p id="video-updated">Video updated!</p>}

      <form onSubmit={update}>
        <input
          autoFocus
          disabled={updating}
          placeholder="video id"
          value={videoId}
          onChange={(e) => setVideoId(e.target.value)}
        />
        <input
          disabled={updating}
          placeholder="thumbnail transaction id"
          value={thumbTid}
          onChange={(e) => setThumbTid(e.target.value)}
        />
        <input
          disabled={updating}
          placeholder="video transaction id"
          value={videoTid}
          onChange={(e) => setVideoTid(e.target.value)}
        />

        <button disabled={updating} type="submit">
          Update video
        </button>
      </form>
    </Page>
  )
}

export default Migrate
