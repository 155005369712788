export const TRANSLATIONS_JA = {
  homePageTitle: 'みんなのための分散ソーシャルメディア',
  footer: '<1>@DSocialApp</1>によって構築されました',

  headerMyChannel: '私のチャンネル',
  headerUpload: 'アップロード',
  headerMyAccount: 'マイアカウント',
  headerSignUpOrLogin: 'サインアップ/ログイン',

  accountErrorUserNameEmpty: 'ユーザー名を入力して下さい',
  accountErrorProfilePhotoFailed:
    '新しいプロフィール写真のアップロードに失敗しました、もう一度やり直してください',
  accountErrorUserNameAlreadyTaken:
    'このユーザー名はすでに取られています、お試しください',
  accountError: 'エラーが発生しました。もう一度やり直してください',
  accountErrorValidImage: '有効なJPG / PNGファイルを選択してください',
  accountErrorFailedResize:
    '写真のサイズ変更に失敗しました、もう一度やり直してください',
  accountPageTitle: 'マイアカウント',
  accountUpdated: 'あなたのアカウントが更新されました',
  accountUserNameLabel: 'ユーザー名',
  accountChannelNameLabel: 'チャネル名',
  accountProfilePhotoLabel: 'プロフィール写真',
  accountResizingPhoto: '写真のサイズ変更...',
  accountDone: '終わり',
  accountCancel: 'キャンセル',
  accountLoadingPhoto: '写真を読み込む...',
  accountChoosePhoto: '写真を選択',
  accountUploadingPhoto: 'プロフィール写真のアップロード...',
  accountUpdatingAccountDetails: 'アカウントの詳細を更新します...',
  accountUpdateAccountDetails: 'アカウントの詳細を更新します',
  accountLogout: 'ログアウト',

  channelFailedToLoad: 'Failed to load channel info, please refresh the page',
  channelFailedToLoadVideos:
    'ビデオのロードに失敗しました、ページを更新してください',
  subscribers: '{{number}} 加入',
  subscribersK: '{{number}}K 加入',
  subscribersM: '{{number}}M 加入',
  subscribersNone: '購読者はありません',
  subscribe: '申し込む',
  subscribed: '購読した',
  channelNoVideos: 'まだビデオがアップロードされていません',

  views: '{{number}} ビュー',
  viewsK: '{{number}}K ビュー',
  viewsM: '{{number}}M ビュー',
  viewsNone: 'ビューはありません',

  loginPageTitle: 'ログイン/登録',
  loginIfNotRegistered: '前に登録していない場合',
  loginSignUpButton: 'サインアップ',
  loginIfRegistered: 'すでに登録されている場合:',
  loginButton: 'ログイン',

  uploadDropHere: 'ここに落ちる...',
  uploadFailedResize:
    '写真のサイズ変更に失敗しました、もう一度やり直してください',
  uploadErrorSelectThumbnail:
    'サムネイルを選択してください（JPG / PNG、1280x720）',
  uploadErrorTransactionId: 'ビデオIDを入力してください',
  uploadErrorVideoFile: 'ビデオを選択してください（MP4のみ、最大1080p）',
  uploadErrorFailedUpload:
    'サムネイルアップロードに失敗しました、もう一度やり直してください...',
  uploadErrorVideoFailedUpload:
    'ビデオアップロードに失敗しました、もう一度やり直してください...',
  uploadPageTitle: 'あなたのビデオをアップロードしてください',
  uploadInstallArConnect:
    '最初にArConnect拡張機能をインストールしてください。 まだ行っていない場合は、<1>入門</1>ガイドに従ってください',
  uploadFirstTime:
    'これがDSocialにアップロードしたときは、この<1>はじめに</1>ガイドを最初に読んでください',
  uploadThumbnailUploaded: 'サムネイルアップロード',
  uploadThumbnailUploading:
    'サムネイルのアップロード、 {{progress}}% 終わり...',
  uploadVideoUploaded: 'ビデオアップロード',
  uploadVideoUploading: 'ビデオアップロード、 {{progress}}% 終わり...',
  uploadVideoCreated: 'ビデオ作成',
  uploadVideoCreating: 'ビデオレコードを作成する...',
  uploadSelectThumbnail: 'サムネイルを選択してください',
  uploadResizingPhoto: '写真のサイズ変更...',
  uploadDone: '終わり',
  uploadCancel: 'キャンセル',
  uploadDropLabel:
    'ここであなたのサムネイルを落としてください（理想的には1280x720）...',
  uploadSelectThumbnailError: 'JPG / PNGサムネイルを選択してください',
  uploadYourVideoWith:
    '<1> DSocial Uploader App </1>でビデオをアップロードし、アップロードしたら、ビデオIDをコピーして下のペーストをコピーします',
  uploadSelectVideo: 'ビデオを選択してください（MP4のみ、最大1080p',
  uploadPlaceholderTransactionId: 'ビデオIDをここに貼り付けます...',
  uploadDropVideo: 'ここでビデオをドロップします（MP4のみ、最大1080p）...',
  uploadErrorDropVideo: 'ビデオを選択してください（MP4のみ、最大1080p）',
  uploadEnterTitle: 'ビデオのタイトルを入力してください:',
  uploadPlaceholderTitle: 'ビデオのタイトルを入力してください...',
  uploadEnterVideoDesc: 'ビデオの説明を入力してください（オプション）:',
  uploadPlaceholderDesc: '説明を入力してください...',
  uploadButton: 'アップロード',
  uploadFileTooBig: 'ビデオアップロードは2GBに制限されています',

  uploaderAppPageTitle: 'ダウンロード DSocial Uploader App',
  uploaderAppDesc:
    'DSOCialは人々に力を返金します。ソーシャルアップローダアプリを使用してビデオをストリーミングの準備ができて、ビデオをArweaveブロックチェーンに直接アップロードする.',
  uploaderAppDownload: '{{os}} のダウンロード',
  uploaderAppNotAvailable:
    'DSocial Uploader App 今すぐMacでも利用可能です、WindowsおよびLinuxのバージョンはもうすぐ外になります.',

  video1Like: '1 お気に入り',
  videoLikes: '{{likes}} いいね',
  videoLike: 'お気に入り',
  videoComments: '{{comments}} コメント',
  videoAddPublicComment: 'パブリックコメントを追加してください...',
  videoCancelButton: 'キャンセル',
  videoSubmitButton: 'コメント',

  gettingStartedTitle: 'クリエイターとして始める',
  gettingStartedPara1: '社会へようこそ!',
  gettingStartedPara2:
    'DSocialのビデオとサムネイルはARWeaveブロックチェーンに保存されています。これは特に永続的な保管専用の検閲抵抗ブロックチェーンです。これはあなたのビデオとサムネイルがあなたを長生きさせることを意味します。そのように、あなたはDSocialにビデオをアップロードするために$ ARトークンを持つ財布を必要とするでしょう、それは非常に単純なことを心配しないでください。問題がある場合は、Twitterで<1>@dsocialapp</1>で私たちに手を差し伸べる.',
  gettingStartedPara3:
    'If you already have $AR tokens in a wallet, you can go back to the <1>upload page</1>.',
  gettingStartedHeading1:
    'Step 1: Install ArConnect Extension & Create/Load Your Wallet',
  gettingStartedPara4:
    "Install ArConnect Extension to manage your digital wallet. It's easy to use on any Chrome/Firefox browser.",
  gettingStartedGetArConnect: 'Create/load wallet on ArConnect',
  gettingStartedHeading2: 'Step 2: Top Up Wallet with $AR Tokens',
  gettingStartedPara5:
    'Adding $AR tokens with a debit/credit card is easy with either Ramp Network or Transak. But if you prefer to use one of the exchanges, ARDrive put together a helpful list of exchanges that support $AR tokens <1>here</1>.',
  gettingStartedPara6:
    'Note: DSocial do NOT receive any commission or benefit from you using these services. These are just the easiest way to top up with $AR tokens.',
  gettingStartedTransak: 'Purchase AR using Transak',
  gettingStartedRamp: 'Purchase AR using the Ramp Network',
  gettingStartedHeading3: 'Step 3: Install DSocial Uploader App',
  gettingStartedPara7:
    "To upload videos you'll need to install the <1>DSocial Uploader App</1>",
  gettingStartedHeading4: 'Step 3: Add your video to DSocial',
  gettingStartedPara8:
    'Go back to the <1>upload page</1> and upload your first video!',

  notFoundError: 'エラーが発生しました',
  notFoundTitle: 'ページが見つかりません',
  notFoundContent: 'このページは見つかりません。<1>ホームページ</1>に戻る',

  topTitle: 'トップチャンネル',

  searchPlaceholder: 'ビデオを検索...',

  sorting_recommended: 'サブスクリプション',
  sorting_hot: '熱い',
  sorting_most_viewed: '最も見られました',
  sorting_top_rated: 'トップレート',
  sorting_newest: '最新',
  sorting_daily: '毎日',
  sorting_weekly: '毎週',
  sorting_monthly: '毎月',
  sorting_yearly: '毎年',
  sorting_all_time: 'いつも',

  feedbackSendFeedback: 'フィードバックを送信します',
  feedbackCloseForm: 'フォームを閉じます',
  feedbackFeedbackType: 'タイプ',
  feedbackName: '名前',
  feedback: 'フィードバック',
  feedbackPlaceholder: 'ここにあなたのフィードバックを入力してください...',
  feedbackSendFeedbackFormButton: 'フィードバックを送信します 👋',
  feedbackFeedbackType_general: '全般的',
  feedbackFeedbackType_bug: 'バグ',
  feedbackFeedbackType_idea: 'アイディア',
  feedbackSubmitted: 'ありがとうございました！送信されたフィードバック',

  sideMenuHome: '家',
  sideMenuUpload: 'アップロード',
  sideMenuMyChannel: '私のチャンネル',
  sideMenuMyAccount: 'マイアカウント',
  sideMenuLoginOrRegister: 'ログイン',
  sideMenuOpen: '開ける',
  sideMenuClose: '選ぶ',
  sideMenuReviewFlagged: 'フラグ付きコンテンツを確認します',
  sideMenuLogout: 'ログアウト',
}
