import { useEffect, useState } from 'react'

import { Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import { getBackend } from '../../lib/icp'
import { UserId } from '../../types/backend/dsocial/dsocial.did'

const Subscriptions = () => {
  const [subs, setSubs] = useState<UserId[]>([])
  useEffect(() => {
    getBackend().then(({ getSubscriptions }) => {
      getSubscriptions()
        .then((rawSubs) => {
          console.warn('getSubscriptions result', rawSubs)
          const results: UserId[] =
            rawSubs.length > 0 && rawSubs[0] ? rawSubs[0] : []
          setSubs(results)
        })
        .catch((err) => {
          console.warn('error: getSubscriptions', err)
        })
    })
  }, [])

  return (
    <Page title={`Subscriptions | DSocial`}>
      <h1>Subscriptions</h1>

      {subs.map((userId, i) => (
        <p>{userId}</p>
      ))}
    </Page>
  )
}

export default withAuthCheck(Subscriptions)
