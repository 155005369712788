export enum SortBy {
  Recommended = 'recommended',
  Hot = 'hot',
  MostViewed = 'most_viewed',
  TopRated = 'top_rated',
  Newest = 'newest',
}

export enum TimeRange {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  AllTime = 'all_time',
}
