import { ChangeEvent, FormEvent, useState } from 'react'
import { IoArrowUpCircleOutline } from 'react-icons/io5'
import { BiSearch } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import { useTranslation, useUser } from '../../hooks'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import pages, { generateUrl } from '../../pages'
import Button from '../Button'
import CachedImage from '../CachedImage'
import Logo from '../Logo'
import SideMenu from '../SideMenu'

import styles from './Header.module.scss'

const Search = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(params.get('q') || '')
  const [focussed, setFocussed] = useState(false)
  const history = useHistory()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!query || !query.trim()) return

    history.push(`${pages.search.path}?q=${encodeURIComponent(query)}`)
  }

  const onBlur = () => setFocussed(false)
  const onFocus = () => setFocussed(true)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  return (
    <form
      onSubmit={onSubmit}
      className={`${styles.search} ${focussed ? styles.focus : ''}`}
    >
      <label htmlFor="search">
        <BiSearch />
        <input
          id="search"
          type="text"
          onChange={onChange}
          value={query}
          placeholder={t('searchPlaceholder')}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </label>
    </form>
  )
}

const Header = () => {
  const { t } = useTranslation()
  const { loading, userData, loggedIn } = useUser()
  const channelPath =
    loggedIn && userData?.userName
      ? generateUrl(pages.channel.path, { userName: userData?.userName })
      : ''

  return (
    <>
      <div className={styles.header}>
        <SideMenu />
        <Logo />

        <Search />
        {!loading && (
          <div className={styles.right}>
            {loggedIn ? (
              <div className={styles.loggedIn}>
                <Link
                  to={pages.account.path}
                  title={t('headerMyAccount')}
                  className={styles.account}
                >
                  <CachedImage
                    src={generateProfilePhotoUrl(userData?.profilePhoto)}
                    alt={t('headerMyAccount')}
                  />
                </Link>
              </div>
            ) : (
              <div className={styles.loggedOut}>
                <Button small href={pages.login.path} subtle>
                  {t('headerSignUpOrLogin')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.spacer} />
    </>
  )
}

export default Header
