import React from 'react'
import { useEffect } from 'react'
import { getBackend } from '../../lib/icp'

const Rpc = () => {
  // @ts-ignore
  const { ReactNativeWebView: webView } = window
  const messageHandler = webView || window
  useEffect(() => {
    // @ts-ignore
    // window.alert(webView)
    if (messageHandler) {
      // @ts-ignore
      // window.alert('getBackend')
      getBackend()
        .then((actor) => {
          // @ts-ignore
          window.icpActor = actor
          // window.alert('icpActor')
          messageHandler.postMessage(JSON.stringify({ id: 'icpActorReady' }))
          // window.alert('postMessage')
        })
        .catch(() => {
          // window.alert('getBackendActor failed')
        })
    }
  }, [messageHandler])

  return <p>RPC...</p>
}

export default Rpc
