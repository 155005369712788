import { MouseEventHandler, ReactNode } from 'react'

import ClipLoader from 'react-spinners/ClipLoader'
import { Link } from 'react-router-dom'

import styles from './Button.module.scss'

const Button = ({
  children,
  href,
  backgroundColor,
  type,
  loading,
  onClick,
  disabled,
  icon,
  secondary,
  cancel,
  small,
  inline,
  subtle,
  className,
  invert
}: ButtonProps) => {
  let cn = `${styles.button} ${className || ''} ${
    secondary ? styles.secondary : ''
  }  ${cancel ? styles.cancel : ''}`

  if (small) cn += ` ${styles.small}`
  if (inline) cn += ` ${styles.inline}`
  if (subtle) cn += ` ${styles.subtle}`
  if (invert) cn += ` ${styles.invert}`

  if (href) {
    if (href.startsWith('http')) {
      return (
        <a
          href={href}
          target="_blank"
          rel="nofollow noreferrer"
          className={cn}
          style={{ backgroundColor: backgroundColor || undefined }}
        >
          {children}
        </a>
      )
    }

    return (
      <Link
        to={href}
        className={cn}
        style={{ backgroundColor: backgroundColor || undefined }}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        {children}
      </Link>
    )
  }

  return (
    <button
      className={cn}
      type={type || 'button'}
      disabled={disabled || loading}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor || undefined,
      }}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {children}

      {loading && (
        <div className={styles.loading}>
          <ClipLoader color="white" loading={loading} size={15} />
        </div>
      )}
    </button>
  )
}

interface ButtonProps {
  children: ReactNode
  href?: string
  backgroundColor?: string | null
  type?: 'submit' | 'button' | 'reset'
  loading?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  icon?: ReactNode
  secondary?: boolean
  cancel?: boolean
  small?: boolean
  inline?: boolean
  subtle?: boolean
  className?: string
  invert?: boolean
}

export default Button
