import CurrencyInput from 'react-currency-input-field'
import {
  FormEvent,
  useEffect,
  useState,
} from 'react'

import { Button, ErrorBox, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import {
  getBackend,
} from '../../lib/icp'
import { useTranslation, useUser } from '../../hooks'
import { UserMonitizationSetting, WalletAddress, MonitizationSetting } from '../../types/backend/dsocial/dsocial.did'

import styles from './Monetisation.module.scss'

const Monetisation = () => {
  const { t } = useTranslation()
  const { userData, loading: loadingUser } = useUser()
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState('')
  const [icpAddress, setIcpAddress] = useState('')
  const [userMonitizationSettings, setUserMonitizationSettings] = useState<
    UserMonitizationSetting | null | undefined
  >()
  const [monthlySubscription, setMonthlySubscription] = useState(5.99)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setError('')
    setLoading(true)
    setUpdating(true)

    try {
      const backend = await getBackend()

      const newAddresses: WalletAddress[] = []
      const newSettings: MonitizationSetting[] = []
      if (icpAddress) {
        newAddresses.push({
          id: 'icp',
          address: icpAddress,
        })
      }
      if (monthlySubscription > 0) {
        newSettings.push({
          key: 'monthlySub',
          value: monthlySubscription.toFixed(2),
        })
      }
      console.log('newAddresses', newAddresses)
      console.log('newSettings', newSettings)
      if (newAddresses.length > 0 || newSettings.length > 0) {
        try {
          await backend.updateUserMonitizationSettings(
            newAddresses,
            newSettings,
          )
          console.log('success updateUserMonitizationSettings')
        } catch (e) {
          console.error('updateUserMonitizationSettings', e)
          return
        }
      }
    } catch (e) {
      setError(t('accountError'))
      setLoading(false)
    }
    setLoading(false)
    setUpdating(false)
  }

  useEffect(() => {
    if (!loadingUser) {
      if (userData?.id) {
        const load = async () => {
          try {
            const backend = await getBackend()
            const userMonitizationSettingsResult =
              await backend.getUserMonitizationSettings()
            if (
              userMonitizationSettingsResult &&
              userMonitizationSettingsResult.length > 0 &&
              userMonitizationSettingsResult[0]
            ) {
              const [currentUserMonitizationSettings] =
                userMonitizationSettingsResult
              if (currentUserMonitizationSettings) {
                setUserMonitizationSettings(currentUserMonitizationSettings)
                const { addresses, settings } = currentUserMonitizationSettings
                const icp = addresses.find(({ id }) => id === 'icp')

                if (icp) {
                  setIcpAddress(icp.address)
                }

                const monthlySub = settings.find(({ key }) => key === "monthlySub")
                console.log('settings', settings, monthlySub)
                if (monthlySub) {
                  setMonthlySubscription(Number(monthlySub.value))
                }
              }
            }
          } catch (e) {
            console.error(e)
          }
          setLoading(false)
        }
        load()
      } else {
        setLoading(false)
      }
    }
  }, [setMonthlySubscription, loadingUser, userData?.id])

  return (
    <Page title={`${t('monetisationPageTitle')} | DSocial`}>
      <h1>{t('monetisationPageTitle')}</h1>

      {error && <ErrorBox>{error}</ErrorBox>}
      {updated && <p>{t('monetisationUpdated')}</p>}

      <form onSubmit={onSubmit} className={styles.form}>
        <table>
          <tr>
            <td style={{ paddingTop: 5, paddingRight: 10, maxWidth: 200 }}>
              {t('monetisationICPAddress')}
              <br />
              <span style={{ fontSize: '0.7rem', color: '#999' }}>{t('monetisationICPAddressDesc')}</span>
            </td>
            <td style={{ paddingTop: 5 }}>
              <input
                disabled={loading}
                type="text"
                value={icpAddress}
                onChange={(e) => setIcpAddress(e.target.value)}
                style={{ minWidth: 300 }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: 5, paddingRight: 10, maxWidth: 200 }}>
              {t('monetisationMonthlySubscription')}
              <br />
              <span style={{ fontSize: '0.7rem', color: '#999' }}>{t('monetisationMonthlySubscriptionDesc')}</span>
            </td>
            <td style={{ paddingTop: 5 }}>
              {!loading && <CurrencyInput
                id="monthlySub"
                name="monthlySub"
                placeholder="$0.00"
                defaultValue={monthlySubscription || 4.99}
                decimalsLimit={2}
                prefix="$"
                onValueChange={(value) => {
                  console.log(value)
                  setMonthlySubscription(value ? Number(value) : 0.00)
                }}
                // suffix="/month"
              />}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={styles.buttons}>
                <Button loading={loading} type="submit">
                  {updating &&
                    t('monetisationUpdatingDetails')}
                  {(!loading || !updating) &&
                    t('monetisationUpdateDetails')}
                </Button>
              </div>
            </td>
          </tr>
        </table>
      </form>
    </Page>
  )
}

export default withAuthCheck(Monetisation)
