import CurrencyInput from 'react-currency-input-field'
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import Cropper from 'react-easy-crop'

import { Button, CachedImage, ErrorBox, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import pages from '../../pages'
import {
  getBackend,
  getUserData,
  updateAuthChangedListeners,
} from '../../lib/icp'
import { useTranslation, useUser } from '../../hooks'

import styles from './Account.module.scss'
import generateProfilePhotoUrl, {
  preloadProfilePhoto,
} from '../../lib/profilePhoto'
import { getCroppedImg } from '../../lib/canvas'
import { create } from 'ipfs-http-client'
import { IPFS_CONFIG } from '../../config'
import { getBackendActor } from '../../lib/agent'
import { UserMonitizationSetting, WalletAddress, MonitizationSetting } from '../../types/backend/dsocial/dsocial.did'

const PROFILE_PHOTO_FILE_TYPES = [
  'image/jpeg .jpeg',
  'image/jpeg .jpg',
  'image/png .png',
]

const ipfs = create(IPFS_CONFIG)

const ipfsUpload = async (
  file: any,
  onUploadProgress?: (progress: number) => void,
): Promise<string | null> => {
  let i = 0
  const max = 3

  for (; i < max; i += 1) {
    try {
      const result = await ipfs.add(file, {
        progress: (bytes: number) => {
          onUploadProgress && onUploadProgress(bytes / file.size)
        },
        pin: true,
      })
      return result.path
    } catch (e) {
      console.log('failed to upload, trying again...', i + 1, e)
      await new Promise((resolve) => setTimeout(resolve, 500 * (i + 1)))
    }
  }

  return null
}

const AccountDetails = () => {
  const { t } = useTranslation()
  const inputFile = useRef<HTMLInputElement>(null)
  const { userData, loading: loadingUser } = useUser()
  const history = useHistory()
  const [updated, setUpdated] = useState(false)
  const [userName, setUserName] = useState(userData?.userName)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState('')
  const [channelName, setChannelName] = useState(userData?.displayName)
  const [profilePhoto, setProfilePhoto] = useState(userData?.profilePhoto)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imageToCrop, setImageToCrop] = useState<string | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [pixels, setPixels] = useState(null)
  const [loadingChoosePhoto, setLoadingChoosePhoto] = useState(false)
  const [resizingPhoto, setResizingPhoto] = useState(false)
  const [uploadingPhoto, setUploadingPhoto] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setPixels(croppedAreaPixels)
  }, [])

  const onChangeUserName = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value)
    setError('')
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setError('')
    setUpdated(false)

    if (!userName || !userName.trim()) {
      setError(t('accountErrorUserNameEmpty'))
      return
    }

    setLoading(true)
    setUpdating(true)

    // Upload profile photo if updated
    let newProfilePhotoHash = profilePhoto
    if (croppedImage) {
      setUploadingPhoto(true)
      const blob = await (await fetch(croppedImage)).blob()
      const newProfileHash = await ipfsUpload(blob, (progress) => {
        console.log('profile upload', progress)
      })

      if (!newProfileHash) {
        setUploadingPhoto(false)
        setError(t('accountErrorProfilePhotoFailed'))
        setLoading(false)
        setUpdating(false)
        return
      }

      newProfilePhotoHash = newProfileHash
      setUploadingPhoto(false)
      preloadProfilePhoto(newProfilePhotoHash, croppedImage)
      setProfilePhoto(newProfilePhotoHash)
      setCroppedImage(null)
    }

    try {
      const backend = await getBackend()
      const isUserNameAvailable = await backend.checkUsernameAvailable(
        userName || '',
      )
      const updateAccountDetails = isUserNameAvailable
        ? await backend.updateUser(
            newProfilePhotoHash || '',
            userName || '',
            channelName || '',
          )
        : null

      if (
        !isUserNameAvailable ||
        updateAccountDetails?.status === 'user-name-already-registered'
      ) {
        setError(t('accountErrorUserNameAlreadyTaken'))
        setLoading(false)
      } else if (updateAccountDetails?.status === 'update-user-success') {
        const userData = await getUserData()
        updateAuthChangedListeners(true, userData)
        setLoading(false)
        setUpdated(true)
      }
    } catch (e) {
      setError(t('accountError'))
      setLoading(false)
    }
    setUpdating(false)
  }

  const choosePhoto = () => {
    inputFile?.current?.click()
  }

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    setImageToCrop(null)
    setPixels(null)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    if (!e.target.files || e.target.files.length === 0) return

    const imageFile = e.target.files[0]

    if (
      PROFILE_PHOTO_FILE_TYPES.filter(
        (fileType) => fileType.indexOf(imageFile.type) !== -1,
      ).length === 0
    ) {
      setError(t('accountErrorValidImage'))
      return
    }

    setLoadingChoosePhoto(true)
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setImageToCrop(reader.result as string)
        setLoadingChoosePhoto(false)
      },
      false,
    )
    reader.readAsDataURL(imageFile)
  }

  const failedResize = () => {
    setResizingPhoto(false)
    setError(t('accountErrorFailedResize'))
  }

  const showResult = async () => {
    if (!imageToCrop) return

    setResizingPhoto(true)

    try {
      let newCroppedImage = await getCroppedImg(imageToCrop!, pixels)

      // Resize the image
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const maxSize = 176
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height
            height = maxSize
          }
        }
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')

        if (!ctx) {
          failedResize()
          return
        }

        ctx.drawImage(image, 0, 0, width, height)
        newCroppedImage = canvas.toDataURL('image/jpeg')

        console.log('done', { croppedImage })
        setCroppedImage(newCroppedImage)
        setResizingPhoto(false)
        setImageToCrop(null)
      }
      image.onerror = failedResize
      image.src = newCroppedImage
    } catch (e) {
      console.error(e)
      setResizingPhoto(false)
    }
  }

  const cancelEdit = () => {
    setImageToCrop(null)
    setCroppedImage(null)
    setPixels(null)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
  }

  return (
    <Page title={`${t('accountPageTitle')} | DSocial`}>
      <h1>{t('accountPageTitle')}</h1>

      {error && <ErrorBox>{error}</ErrorBox>}
      {updated && <p>{t('accountUpdated')}</p>}

      <form onSubmit={onSubmit} className={styles.form}>
        <table>
          <tr>
            <td>{t('accountUserNameLabel')}</td>
            <td>
              <input
                autoFocus
                disabled={loading}
                type="text"
                value={userName}
                onChange={onChangeUserName}
              />
            </td>
          </tr>
          <tr>
            <td>{t('accountChannelNameLabel')}</td>
            <td>
              <input
                disabled={loading}
                type="text"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td valign="top">{t('accountProfilePhotoLabel')}</td>
            <td>
              {imageToCrop ? (
                <div className={styles.cropper}>
                  <div className={styles.cropperContainer}>
                    <Cropper
                      image={imageToCrop}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 4}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      cropShape="round"
                      showGrid={false}
                    />
                  </div>
                  <div className={styles.cropperButtons}>
                    <Button
                      onClick={showResult}
                      loading={resizingPhoto}
                      small
                      secondary
                    >
                      {resizingPhoto
                        ? t('accountResizingPhoto')
                        : t('accountDone')}
                    </Button>
                    <Button
                      onClick={cancelEdit}
                      disabled={resizingPhoto}
                      small
                      cancel
                    >
                      {t('accountCancel')}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {profilePhoto || croppedImage ? (
                    <CachedImage
                      className={`${styles.profilePhoto} ${
                        loading ? styles.disabled : ''
                      }`}
                      src={
                        croppedImage || generateProfilePhotoUrl(profilePhoto)
                      }
                      onClick={!loading ? choosePhoto : undefined}
                    />
                  ) : null}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onSelectFile}
                    accept=".png,.jpg,.jpeg"
                  />
                  <Button
                    onClick={choosePhoto}
                    disabled={loading}
                    loading={loadingChoosePhoto}
                    small
                    secondary
                  >
                    {loadingChoosePhoto
                      ? t('accountLoadingPhoto')
                      : t('accountChoosePhoto')}
                  </Button>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={styles.buttons}>
                <Button loading={loading} type="submit">
                  {uploadingPhoto && t('accountUploadingPhoto')}
                  {updating &&
                    !uploadingPhoto &&
                    t('accountUpdatingAccountDetails')}
                  {!uploadingPhoto &&
                    (!loading || !updating) &&
                    t('accountUpdateAccountDetails')}
                </Button>

                {!loading ? (
                  <Button href={pages.logout.path} cancel>
                    {t('accountLogout')}
                  </Button>
                ) : null}
              </div>
            </td>
          </tr>
        </table>
      </form>
    </Page>
  )
}

export default withAuthCheck(AccountDetails)
