const isLocal = false //window.location.host === 'localhost:3000'
const pick = <T>(localValue: T, prodValue: T) =>
  isLocal ? localValue : prodValue

const ENV = isLocal ? 'LOCAL' : 'PROD'

const IDENTITY_PROVIDER_URL = pick(
  `http://localhost:8000?canisterId=rwlgt-iiaaa-aaaaa-aaaaa-cai`,
  'https://identity.ic0.app/',
)

// pick(
//   `http://localhost:8000?canisterId=rwlgt-iiaaa-aaaaa-aaaaa-cai`,
//   'https://identity.ic0.app/',
// )

const BACKEND_CANISTER_ID = pick(
  'rwlgt-iiaaa-aaaaa-aaaaa-cai',
  'mw5fv-3qaaa-aaaaj-aacba-cai',
)

const FEEDBACK_BACKEND_CANISTER_ID = pick('', 'catlt-2yaaa-aaaan-qagja-cai')

const IPFS_BASE_URL =
  /*pick(
  'http://localhost:8080/ipfs/',*/
  'https://infura-ipfs.io/ipfs/'
// ,
// )

const ASSET_BASE_URL = IPFS_BASE_URL

const USE_WALLET = true // pick(false, true)

const AR_WEAVE_CONFIG = {
  host: 'arweave.net',
  port: 443,
  protocol: 'https',
  logging: true,
  logger: console.log,
}

// /*pick(
//   {
//     host: '127.0.0.1',
//     port: 1984,
//     protocol: 'http',
//     logging: true,
//     logger: console.log,
//   },*/
//   // {
//   //   host: 'arweave.net',
//   //   port: 443,
//   //   protocol: 'https',
//   //   logging: true,
//   //   logger: console.log,
//   // }
// // )

const AR_WEAVE_HOST = 'https://arweave.net/'

// 'https://arweave.net/'

const IPFS_CONFIG =
  //pick(
  // { url: 'http://127.0.0.1:5001' },
  {
    host: 'infura-ipfs.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization:
        'Basic ' +
        new Buffer(
          '1ukY0n80PYUAX41plglHkht8Uce:a005aeb764555716f4f0ba6c0e0d6ca6',
        ).toString('base64'),
    },
  }
// ,
// )

const AR_ENABLED = false

// try {
//   const val = window.localStorage.getItem('ar_test')
//   if (val === 'yes') {
//     console.log('ar_test: from storage yes')
//     AR_ENABLED = true
//   } else if (val === 'no') {
//     console.log('ar_test: from storage no')
//   } else {
//     console.log('ar_test: not in storage')
//     AR_ENABLED = Math.random() < 0.5
//     const newValue = AR_ENABLED ? 'yes' : 'no'
//     window.localStorage.setItem('ar_test', newValue)
//   }
//   console.log('ar_test: running', AR_ENABLED)
// } catch (e) {
//   console.warn('ar_test: failed', e)
// }

const CLOUD_UPLOAD = true

const CS_CDN = {
  before:
    'https://firebasestorage.googleapis.com/v0/b/ilecstdybtaosa.appspot.com/o/',
  after: 'https://dsocial.b-cdn.net/',
}

const CLOUD_TRANSCODING = true

const AR_CDN = {
  before: 'https://arweave.net/',
  after: 'https://arweave.net/',
  // after: 'https://dsocial-arweave.b-cdn.net/',
}

const BADGE_CHECKOUT_ID = pick(
  'fb27e30e-042e-450e-a466-c2f9c87a8c6b',
  'e8976620-56d0-460c-a8ab-c497200b8fae',
)

const VERIFIED_PRICE_ID = pick(
  'price_1K2XgyCx8st68diAJznLG1ly',
  'price_1K2qWZCx8st68diAPYzcR7cz',
)

const FUNCTIONS_URL = pick(
  'http://localhost:5001/ilecstdybtaosa/us-central1',
  'https://us-central1-ilecstdybtaosa.cloudfunctions.net',
)

const IC_HOST = pick('http://localhost:8000/', 'https://boundary.ic0.app/')

const IS_LOCAL = isLocal

export {
  FUNCTIONS_URL,
  VERIFIED_PRICE_ID,
  BADGE_CHECKOUT_ID,
  ENV,
  IDENTITY_PROVIDER_URL,
  BACKEND_CANISTER_ID,
  FEEDBACK_BACKEND_CANISTER_ID,
  ASSET_BASE_URL,
  USE_WALLET,
  AR_WEAVE_CONFIG,
  IPFS_CONFIG,
  IPFS_BASE_URL,
  AR_WEAVE_HOST,
  AR_ENABLED,
  CLOUD_UPLOAD,
  CS_CDN,
  CLOUD_TRANSCODING,
  AR_CDN,
  IC_HOST,
  IS_LOCAL,
}
