import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../../components'
import { getBackend } from '../../lib/icp'
import {
  User,
  UserMonitizationSetting,
} from '../../types/backend/dsocial/dsocial.did'

interface Record {
  user: User
  setting: UserMonitizationSetting
}

const FirstPayable = () => {
  const [settings, setSettingsList] = useState<Record[]>()
  useEffect(() => {
    const load = async () => {
      const backend = await getBackend()
      const settingsList = await backend.getUserMonitizationSettingsList()

      const results = await Promise.all(
        settingsList.map(async ([userId, setting]) => {
          const result = await backend.getUser(userId)

          const user =
            result && result.length > 0 && result[0] ? (result[0] as User) : {}

          return {
            user,
            setting,
          } as Record
        }),
      )

      results.sort((a, b) => {
        return Number(a.setting.updated - b.setting.updated)
      })

      setSettingsList(results)
    }

    load()
  }, [])
  return (
    <Page title="First Payable">
      <p>{settings?.length} addresses</p>
      <table>
        <tr>
          <td>User</td>
          <td>When</td>
          <td>Address</td>
        </tr>
        {settings?.map(({ user, setting }) => (
          <tr key={user.id}>
            <td>
              <Link to={`@${user.userName}`}>
                {user.displayName || user.userName}
              </Link>
            </td>
            <td>
              {moment(Number(setting.updated) / 1000000).format('LL HH:mm')}
            </td>
            <td>asdasd</td>
          </tr>
        ))}
      </table>
    </Page>
  )
}

export default FirstPayable
