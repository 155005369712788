export const TRANSLATIONS_HI = {
  homePageTitle: 'सभी के लिए विकेंद्रीकृत सोशल मीडिया',
  footer: 'निर्माता <1>@DSocialApp<1>',

  headerMyChannel: 'मेरा चैनल',
  headerUpload: 'अपलोड ',
  headerMyAccount: 'मेरा खाता',
  headerSignUpOrLogin: 'साइन अप करें/लॉग इन करें',

  accountErrorUserNameEmpty: 'कृपया एक नाम दर्ज करें',
  accountErrorProfilePhotoFailed:
    'नई प्रोफ़ाइल फ़ोटो अपलोड करने में विफल, कृपया पुनः प्रयास करें',
  accountErrorUserNameAlreadyTaken:
    'यह नाम पहले ही लिया जा चुका है, कृपया दूसरा प्रयास करें',
  accountError: 'कोई त्रुटि हुई, कृपया पुन: प्रयास करें',
  accountErrorValidImage: 'कृपया JPG/PNG फ़ाइल चुनें',
  accountErrorFailedResize:
    'फ़ोटो का आकार बदलने में विफल, कृपया पुनः प्रयास करें',
  accountPageTitle: 'मेरा खाता',
  accountUpdated: 'आपका खाता अपडेट हो गया है',
  accountUserNameLabel: 'उपयोगकर्ता का नाम',
  accountChannelNameLabel: 'चैनल का नाम',
  accountProfilePhotoLabel: 'प्रोफाइल फोटो',
  accountResizingPhoto: 'फोटो का आकार बदलना...',
  accountDone: 'संपन्न हुआ',
  accountCancel: 'रद्द करे',
  accountLoadingPhoto: 'फोटो लोड हो रहा है...',
  accountChoosePhoto: 'फोटो चुनें',
  accountUploadingPhoto: 'प्रोफ़ाइल लोड फ़ोटो हो रहा है...',
  accountUpdatingAccountDetails: 'खाता विवरण अपडेट किया जा रहा है...',
  accountUpdateAccountDetails: 'खाता विवरण अपडेट करें',
  accountLogout: 'लॉग आउट करे',

  channelFailedToLoad:
    'चैनल कि जानकारी लोड करने में विफल, कृपया पेज को रीफ्रेश करें',
  channelFailedToLoadVideos:
    'वीडियो को लोड करने में विफल, कृपया पेज को रीफ्रेश करें',
  subscribers: '{{number}} सब्स्क्राइबर ',
  subscribersK: '{{number}} हज़ार सब्स्क्राइबर',
  subscribersM: '{{number}} लाख सब्स्क्राइबर',
  subscribersNone: 'कोई सब्स्क्राइबर नहीं',
  subscribe: 'सब्स्क्राइब करे',
  subscribed: 'सब्सक्राइब हो गया है',
  channelNoVideos: 'अभी तक कोई वीडियो अपलोड नहीं किया गया है',

  views: '{{number}} देखा गया',
  viewsK: '{{number}}हज़ार बार देखा गया',
  viewsM: '{{number}}लाख बार देखा गया',
  viewsNone: 'देखा गया',

  loginPageTitle: 'लॉग इन करें/रजिस्टर करें',
  loginIfNotRegistered: 'यदि आपने पहले DSocial के साथ रजिस्टर नहीं किया है:',
  loginSignUpButton: 'Internet Identity के साथ साइन अप करें ',
  loginIfRegistered: 'यदि आपने पहले DSocial के साथ रजिस्टर किया है:',
  loginButton: ' Internet Identity के साथ लॉग इन करें ',

  uploadDropHere: 'यहां छोड़ें...',
  uploadFailedResize: 'फ़ोटो का आकार बदलने में विफल, कृपया पुनः प्रयास करें',
  uploadErrorSelectThumbnail: 'कृपया एक थंबनेल चुनें (JPG/PNG, 1280x720)',
  uploadErrorTransactionId: 'कृपया वीडियो ID दर्ज करें',
  uploadErrorVideoFile: 'कृपया एक वीडियो चुनें (केवल MP4, अधिकतम 1080p)',
  uploadErrorFailedUpload:
    'थंबनेल अपलोड करने में विफल, कृपया पुन: प्रयास करें...',
  uploadErrorVideoFailedUpload:
    'वीडियो अपलोड करने में विफल, कृपया पुनः प्रयास करें...',
  uploadPageTitle: 'अपना वीडियो अपलोड करें',
  uploadInstallArConnect:
    'कृपया पहले ArConnect एक्सटेंशन इंस्टॉल करें। यदि आपने पहले से नहीं किया है तो <1>GettingStarted</1> मार्गदर्शिका का पालन करें।',
  uploadFirstTime:
    'यदि आपने पहली बार DSocial पर अपलोड किया है, तो पहले यह <1>Getting Started</1> मार्गदर्शिका पढ़ें',
  uploadThumbnailUploaded: 'थंबनेल अपलोड किया गया',
  uploadThumbnailUploading: 'थंबनेल अपलोड हो रहा है, {{progress}}% हो गया...',
  uploadVideoUploaded: 'वीडियो अपलोड किया गया',
  uploadVideoUploading: 'वीडियो अपलोड हो रहा है, {{progress}}% हो गया...',
  uploadVideoCreated: 'वीडियो बनाया गया',
  uploadVideoCreating: 'वीडियो रिकॉर्ड बन रहा है...',
  uploadSelectThumbnail: 'थंबनेल चुनें:',
  uploadResizingPhoto: 'फोटो का आकार बदले...',
  uploadDone: 'संपन्न हुआ',
  uploadCancel: 'रद्द करे',
  uploadDropLabel: 'अपना थंबनेल यहां छोड़ें (आदर्श रूप से 1280x720 .))...',
  uploadSelectThumbnailError: 'कृपया एक JPG/PNG थंबनेल चुनें',
  uploadYourVideoWith:
    'अपना वीडियो <1>DSSocial Uploader App</1> के साथ अपलोड करें, एक बार अपलोड हो जाने पर, वीडियो आईडी को कॉपी करें और नीचे पेस्ट करें.',
  uploadSelectVideo: 'वीडियो चुनें (केवल MP4, अधिकतम 1080p):',
  uploadPlaceholderTransactionId: 'अपनी वीडियो ID यहां पेस्ट करें...',
  uploadDropVideo: 'अपना वीडियो यहां छोड़ें (केवल MP4, अधिकतम 1080p)...',
  uploadErrorDropVideo: 'कृपया वीडियो चुनें (केवल MP4, अधिकतम 1080p)',
  uploadEnterTitle: 'वीडियो शीर्षक दर्ज करें:',
  uploadPlaceholderTitle: 'वीडियो शीर्षक दर्ज करें...',
  uploadEnterVideoDesc: 'वीडियो विवरण दर्ज करें (वैकल्पिक):',
  uploadPlaceholderDesc: 'विवरण दर्ज करें...',
  uploadButton: 'अपलोड करे',
  uploadFileTooBig: 'वीडियो अपलोड 2GB एमबी तक सीमित हैं',

  video1Like: '1 पसंद किया गया',
  videoLikes: '{{likes}} पसंद किया गया',
  videoLike: 'पसंद किया गया',
  videoComments: '{{comments}} कमेंट',
  videoAddPublicComment: 'सार्वजनिक कमेंट करें ...',
  videoCancelButton: 'रद्द करे',
  videoSubmitButton: 'कमेंट',

  notFoundError: 'कोई त्रुटि हुई',
  notFoundTitle: 'पेज हटाया गया',
  notFoundContent: 'यह पेज नहीं मिला है। <1>homepage</1> पर वापस जाएं.',

  topTitle: 'शीर्ष चैनल',

  searchPlaceholder: 'वीडियो खोजें...',

  sorting_recommended: 'सदस्यता',
  sorting_hot: 'गर्म',
  sorting_most_viewed: 'सबसे ज्यादा देखा गया',
  sorting_top_rated: 'टॉप रेटेड',
  sorting_newest: 'नवीनतम',
  sorting_daily: 'दैनिक',
  sorting_weekly: 'साप्ताहिक',
  sorting_monthly: 'महीने के',
  sorting_yearly: 'सालाना',
  sorting_all_time: 'पूरा समय',

  feedbackSendFeedback: 'प्रतिक्रिया भेजें',
  feedbackCloseForm: 'बंद रूप',
  feedbackFeedbackType: 'प्रकार',
  feedbackName: 'नाम',
  feedback: 'प्रतिपुष्टि',
  feedbackPlaceholder: 'यहां अपनी प्रतिक्रिया दर्ज करें...',
  feedbackSendFeedbackFormButton: 'प्रतिक्रिया भेजें 👋',
  feedbackFeedbackType_general: 'आम',
  feedbackFeedbackType_bug: 'तंग करना',
  feedbackFeedbackType_idea: 'विचार',
  feedbackSubmitted: 'शुक्रिया!फीडबैक प्रस्तुत किया गया',

  sideMenuHome: 'घर',
  sideMenuUpload: 'डालना',
  sideMenuMyChannel: 'मेरा चैनल',
  sideMenuMyAccount: 'मेरा खाता',
  sideMenuLoginOrRegister: 'लॉग इन करें',
  sideMenuOpen: 'खुला',
  sideMenuClose: 'बंद करे',
  sideMenuReviewFlagged: 'ध्वजांकित सामग्री की समीक्षा करें',
  sideMenuLogout: 'लॉग आउट करे',
}
