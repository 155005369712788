export const TRANSLATIONS_RU = {
  homePageTitle: 'Децентрализованные социальные сети для всех',
  footer: 'Построен <1>@DSocialApp<1>',

  headerMyChannel: 'Мой канал',
  headerUpload: 'Загрузить',
  headerMyAccount: 'Мой аккаунт',
  headerSignUpOrLogin: 'Зарегестрироваться войти',

  accountErrorUserNameEmpty: 'Пожалуйста введите имя пользователя',
  accountErrorProfilePhotoFailed:
    'Новая фотография профиля не удалось загрузить, пожалуйста, попробуйте еще раз',
  accountErrorUserNameAlreadyTaken:
    'Это имя пользователя уже занято, пожалуйста, попробуйте другой',
  accountError: 'Произошла ошибка. Пожалуйста, попробуйте еще раз',
  accountErrorValidImage: 'Пожалуйста, выберите действительный файл JPG / PNG',
  accountErrorFailedResize:
    'Не удалось разместить фото, пожалуйста, попробуйте еще раз',
  accountPageTitle: 'Мой аккаунт',
  accountUpdated: 'Ваша учетная запись теперь обновляется',
  accountUserNameLabel: 'Имя пользователя',
  accountChannelNameLabel: 'Название канала',
  accountProfilePhotoLabel: 'Аватар',
  accountResizingPhoto: 'Размеры фото...',
  accountDone: 'Выполнено',
  accountCancel: 'Отмена',
  accountLoadingPhoto: 'Загрузка фото...',
  accountChoosePhoto: 'Выбрать фото',
  accountUploadingPhoto: 'Загрузка профиля Фото...',
  accountUpdatingAccountDetails: 'Обновление деталей аккаунта...',
  accountUpdateAccountDetails: 'Обновить данные учетной записи',
  accountLogout: 'Выйти',

  channelFailedToLoad:
    'Не удалось загрузить информацию канала, пожалуйста, обновите страницу',
  channelFailedToLoadVideos:
    'Не удалось загрузить видео, пожалуйста, обновите страницу',
  subscribers: '{{number}} подписчики',
  subscribersK: '{{number}}K подписчики',
  subscribersM: '{{number}}M подписчики',
  subscribersNone: 'Нет подписчиков',
  subscribe: 'Подписывайся',
  subscribed: 'Подписаться',
  channelNoVideos: 'Никакие видео не загружены.',

  views: '{{number}} Просмотры',
  viewsK: '{{number}}K Просмотры',
  viewsM: '{{number}}M Просмотры',
  viewsNone: 'Нет взглядов',

  loginPageTitle: 'Войти / Регистрация',
  loginIfNotRegistered: 'Если вы не зарегистрированы раньше:',
  loginSignUpButton: 'Подписаться',
  loginIfRegistered: 'Если вы уже зарегистрированы',
  loginButton: 'Авторизоваться',

  uploadDropHere: 'Отбрасывать здесь...',
  uploadFailedResize:
    'Не удалось разместить фото, пожалуйста, попробуйте еще раз',
  uploadErrorSelectThumbnail:
    'Пожалуйста, выберите эскиз (JPG / PNG, 1280x720)',
  uploadErrorTransactionId: 'Пожалуйста, введите идентификатор видео',
  uploadErrorVideoFile: 'Пожалуйста, выберите видео (только MP4, MAX 1080P)',
  uploadErrorFailedUpload:
    'Миниатюра не удалось загрузить, пожалуйста, попробуйте еще раз...',
  uploadErrorVideoFailedUpload:
    'Видео не удалось загрузить, пожалуйста, попробуйте еще раз...',
  uploadPageTitle: 'Загрузите свое видео',
  uploadInstallArConnect:
    'Пожалуйста, сначала установите расширение Arconnect.Следуйте руководству <1> Начало работы </ 1> Если вы уже.',
  uploadFirstTime:
    'Если это первый раз, когда вы загрузили в DSocial, прочитайте это <1> Начало работы </ 1> Первым.',
  uploadThumbnailUploaded: 'Миниатюра загружена',
  uploadThumbnailUploading: 'Ungyail Загрузка, {{progress}}%...',
  uploadVideoUploaded: 'Загружено видео',
  uploadVideoUploading: 'Загрузка видео, {{progress}}%...',
  uploadVideoCreated: 'Видео создано',
  uploadVideoCreating: 'Создание записи видео...',
  uploadSelectThumbnail: 'Выберите миниатюру:',
  uploadResizingPhoto: 'Размеры фото...',
  uploadDone: 'Выполнено',
  uploadCancel: 'Отмена',
  uploadDropLabel: 'Снимите эскиз здесь (в идеале 1280x720)...',
  uploadSelectThumbnailError: 'Пожалуйста, выберите jpg / png миниатюру',
  uploadYourVideoWith:
    'Загрузите свое видео с помощью App Dsocial Uployer App </ 1> После загрузки скопируйте идентификатор видео и вставьте ниже.',
  uploadSelectVideo: 'Выберите видео (только MP4, MAX 1080P):',
  uploadPlaceholderTransactionId: 'Вставьте свой видео ID здесь...',
  uploadDropVideo: 'Выложите видео здесь (только MP4, 1080p Max)...',
  uploadErrorDropVideo: 'Пожалуйста, выберите видео (только MP4, MAX 1080P)',
  uploadEnterTitle: 'Введите название видео:',
  uploadPlaceholderTitle: 'Введите название видео...',
  uploadEnterVideoDesc: 'Введите описание видео (необязательно):',
  uploadPlaceholderDesc: 'Введите описание...',
  uploadButton: 'Загрузить',
  uploadFileTooBig: 'Загрузка видео ограничена до 2GB',

  uploaderAppPageTitle: 'Download DSocial Uploader App',
  uploaderAppDesc:
    'DSocial gives the power back to the people. Use the DSocial Uploader App to process videos ready for streaming & upload your video straight to the ARWeave blockchain.',
  uploaderAppDownload: 'Download for {{os}}',
  uploaderAppNotAvailable:
    'DSocial Uploader App is only available for Mac right now, Windows and Linux version will be out soon.',

  video1Like: '1 вроде',
  videoLikes: '{{likes}} любит',
  videoLike: 'Нравиться',
  videoComments: '{{comments}} Комментарии',
  videoAddPublicComment: 'Добавить публичный комментарий...',
  videoCancelButton: 'Отмена',
  videoSubmitButton: 'Комментарий',

  gettingStartedTitle: 'Getting Started as a Creator',
  gettingStartedPara1: 'Welcome to DSocial!',
  gettingStartedPara2:
    "Videos and thumbnails on DSocial are stored on the ARWeave blockchain. This is a censorship resistent blockchain specifically for persistent storage, this means your videos and thumbnails will outlive you. As such, you will need a wallet with $AR tokens to upload videos to DSocial, don't worry it's very simple. If you have any issues, reach out to us at <1>@DSocialApp</1> on Twitter.",
  gettingStartedPara3:
    'If you already have $AR tokens in a wallet, you can go back to the <1>upload page</1>.',
  gettingStartedHeading1:
    'Step 1: Install ArConnect Extension & Create/Load Your Wallet',
  gettingStartedPara4:
    "Install ArConnect Extension to manage your digital wallet. It's easy to use on any Chrome/Firefox browser.",
  gettingStartedGetArConnect: 'Create/load wallet on ArConnect',
  gettingStartedHeading2: 'Step 2: Top Up Wallet with $AR Tokens',
  gettingStartedPara5:
    'Adding $AR tokens with a debit/credit card is easy with either Ramp Network or Transak. But if you prefer to use one of the exchanges, ARDrive put together a helpful list of exchanges that support $AR tokens <1>here</1>.',
  gettingStartedPara6:
    'Note: DSocial do NOT receive any commission or benefit from you using these services. These are just the easiest way to top up with $AR tokens.',
  gettingStartedTransak: 'Purchase AR using Transak',
  gettingStartedRamp: 'Purchase AR using the Ramp Network',
  gettingStartedHeading3: 'Step 3: Install DSocial Uploader App',
  gettingStartedPara7:
    "To upload videos you'll need to install the <1>DSocial Uploader App</1>",
  gettingStartedHeading4: 'Step 3: Add your video to DSocial',
  gettingStartedPara8:
    'Go back to the <1>upload page</1> and upload your first video!',

  notFoundError: 'Возникла ошибка',
  notFoundTitle: 'Страница не найдена',
  notFoundContent:
    'Эта страница не найдена.Вернитесь к <1>Главная страница</1>',

  topTitle: 'Лучшие каналы',

  searchPlaceholder: 'Поиск видео...',

  sorting_recommended: 'Подписки',
  sorting_hot: 'Горячий',
  sorting_most_viewed: 'Наиболее просматриваемые',
  sorting_top_rated: 'Самые популярные',
  sorting_newest: 'Новейший',
  sorting_daily: 'Повседневная',
  sorting_weekly: 'Еженедельно',
  sorting_monthly: 'Ежемесячно',
  sorting_yearly: 'Ежегодно',
  sorting_all_time: 'Все время',

  feedbackSendFeedback: 'Отправить отзыв',
  feedbackCloseForm: 'Близкая форма',
  feedbackFeedbackType: 'Тип',
  feedbackName: 'Имя',
  feedback: 'Обратная связь',
  feedbackPlaceholder: 'Введите здесь свой отзыв...',
  feedbackSendFeedbackFormButton: 'Отправить отзыв 👋',
  feedbackFeedbackType_general: 'Общий',
  feedbackFeedbackType_bug: 'Ошибка',
  feedbackFeedbackType_idea: 'Идея',
  feedbackSubmitted: 'Благодарю вас!Отзыв',

  sideMenuHome: 'Дом',
  sideMenuUpload: 'Загрузить',
  sideMenuMyChannel: 'Мой канал',
  sideMenuMyAccount: 'Мой счет',
  sideMenuLoginOrRegister: 'Авторизоваться',
  sideMenuOpen: 'Открытым',
  sideMenuClose: 'Закрывать',
  sideMenuReviewFlagged: 'Просмотрите флаг -контент',
  sideMenuLogout: 'Выйти',
}
