import { getBackend } from './icp'
import getDt from './dt'
import { IS_LOCAL } from '../config'

const trackClick = async (videoId: string) => {
  if (IS_LOCAL) return
  const start = Date.now()
  console.log(`trackClick for ${videoId}`)

  try {
    const backend = await getBackend()
    const caller = await backend.getCallerInfo()
    const res = await backend.incrementClicks(
      videoId,
      caller.isAnonymous(),
      getDt(),
    )
    console.log(`incrementClicks took ${Date.now() - start}ms`, res)
  } catch (e) {
    console.warn('incrementClicks failed', e)
  }
}

export default trackClick
