import { useEffect, useState } from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { Link, useParams } from 'react-router-dom'
import pages, { generateUrl } from '..'
import {
  Button,
  CachedImage,
  Loading,
  Page,
  Pay,
  VideoBoxHome,
} from '../../components'
import { useTranslation, useUser } from '../../hooks'
import { getBackend } from '../../lib/icp'
import renderNumbers from '../../lib/numbers'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import { User, Video } from '../../types/backend/dsocial/dsocial.did'
import NotFound from '../404'
import { ReactComponent as Verified } from '../../assets/verified.svg'

import styles from './Channel.module.scss'
import { BADGE_CHECKOUT_ID, FUNCTIONS_URL } from '../../config'
import getDt from '../../lib/dt'
import { Tags } from '../../config/tags'

const Channel = () => {
  const { t } = useTranslation()
  const { userName } = useParams<{ userName?: string }>()
  const { userData, loading: loadingUser } = useUser()
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [loadingVerified, setLoadingVerified] = useState(true)
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState<string>('')
  const [videos, setVideos] = useState<Video[]>([])
  const [channel, setChannel] = useState<User | null | undefined>(null)
  const [userId, setUserId] = useState<string | null | undefined>('')
  const [subscribers, setSubscribers] = useState(0)
  const [subscribed, setSubscribed] = useState(false)
  const [toggleSubscribeRunning, setToggleSubscribeRunning] = useState(false)
  const [loadingIsSubscribed, setLoadingIsSubscribed] = useState(true)
  const [isPayOpen, setIsPayOpen] = useState(false)
  const [totalViews, setTotalViews] = useState(0)
  const [verifiedPrice, setVerifiedPrice] = useState(99.99 / 5.08)

  const onRequestClosePay = () => setIsPayOpen(false)

  const toggleSubscribe = async () => {
    const backend = await getBackend()

    if (userId) {
      setToggleSubscribeRunning(true)
      if (subscribed) {
        backend.unsubscribe(userId, getDt())
        setSubscribed(false)
        setSubscribers(subscribers - 1)
      } else {
        backend.subscribe(userId, getDt())
        setSubscribed(true)
        setSubscribers(subscribers + 1)
      }
      setToggleSubscribeRunning(false)
    }
  }

  useEffect(() => {
    if (loadingUser) return

    if (userName) {
      getBackend().then((backend) => {
        console.log('userName', userName)
        backend
          .getUserIdFromUserName(userName)
          .then((getUserIdFromUserNameResult) => {
            const channelId =
              getUserIdFromUserNameResult?.length > 0 &&
              getUserIdFromUserNameResult[0]
                ? getUserIdFromUserNameResult[0]
                : null
            console.log('channelId', channelId)

            setUserId(channelId)
            if (channelId) {
              backend.getVerifiedStatus(channelId).then((isVerified) => {
                setVerified(isVerified)
                setLoadingVerified(false)
              })

              return backend
                .getUserVideos(channelId)
                .then((userVideoResults) => {
                  console.log(`getUserVideos(${channelId})`, userVideoResults)
                  let videoList = userVideoResults || []

                  if (!userData || channelId !== userData?.id) {
                    // Filter unapproved videos
                    videoList = videoList.filter(
                      ({ tags }) => ![Tags.CopyrightedContent, Tags.Illegal, Tags.Nsfw].some(prohibited => tags.some(tag => prohibited === tag)),
                    )
                  }

                  videoList.sort((a, b) => Number(b.when) - Number(a.when))

                  let viewsCount = 0

                  videoList.forEach((video) => {
                    viewsCount += Number(video.views)
                  })
                  console.log('viewsCount', viewsCount)
                  setTotalViews(viewsCount)

                  setVideos(videoList)

                  backend.isSubscribed(channelId).then((isSubscribed) => {
                    setSubscribed(isSubscribed)
                    setLoadingIsSubscribed(false)
                  })

                  backend
                    .getSubscriberCount(channelId)
                    .then((subscriberCountResults) => {
                      setSubscribers(
                        subscriberCountResults?.length > 0
                          ? Number(subscriberCountResults[0])
                          : 0,
                      )
                    })

                  return backend
                    .getUser(channelId)
                    .then((getUserResult) => {
                      if (getUserResult?.length > 0 && getUserResult[0]) {
                        setChannel(getUserResult[0])
                      } else {
                        setNotFound(true)
                      }
                      setLoading(false)
                    })
                    .catch((e) => {
                      console.error(e)
                      setError(t('channelFailedToLoad'))
                      setLoading(false)
                    })
                })
                .catch((e) => {
                  console.error(e)
                  setError(t('channelFailedToLoadVideos'))
                  setLoading(false)
                })
            } else {
              setNotFound(true)
              setLoading(false)
            }
          })
      })
    }

    const loadPrice = async () => {
      try {
        const res = await fetch(`${FUNCTIONS_URL}/getPrice`)
        const data = await res.json()
  
        const usdIcpPrice = parseFloat(data.items[0].price)
        const icpToPay = 99.99 / usdIcpPrice
  
        console.log('usdIcpPrice', usdIcpPrice)
        console.log('icpToPay', icpToPay)
        setVerifiedPrice(icpToPay)
      } catch (e) {
        setVerifiedPrice(99.99 / 5.08)
      }
    }
    loadPrice()
  }, [userName, userData?.id, loadingUser])

  const onPaymentDetected = async (code: string) => {
    setVerified(true)
    onRequestClosePay()
    const backend = await getBackend()
    const setVerifiedStatusResult = await backend.setVerifiedStatus(code)
    console.log(setVerifiedStatusResult)
  }

  const showPayModal = () => setIsPayOpen(true)

  if (loading) {
    return <Loading />
  }

  if (notFound || error) {
    return <NotFound error={error} />
  }

  return (
    <>
      <Page>
        <div className={styles.header}>
          <div className={styles.profilePhoto}>
            <CachedImage src={generateProfilePhotoUrl(channel?.profilePhoto)} />
          </div>
          <div className={styles.right}>
            <div className={styles.channelInfo}>
              <h1>
                {channel?.displayName || channel?.userName}

                {/* <Button small icon={<IoCheckmarkCircle />} backgroundColor="rgb(29, 155, 240)" onClick={showPayModal}>Make Verified</Button> */}

                {channel?.id === userData?.id && !loadingVerified && !verified && (
                  // <CoinbaseCommerceButton onPaymentDetected={onPaymentDetected} className={`${buttonStyles.button} ${buttonStyles.small}`} style={{ backgroundColor : 'rgb(29, 155, 240)' }} checkoutId={BADGE_CHECKOUT_ID}>
                  //   <span className={buttonStyles.icon}><IoCheckmarkCircle /></span>
                  //   Get Verified
                  // </CoinbaseCommerceButton>
                  <Button
                    small
                    icon={<IoCheckmarkCircle />}
                    backgroundColor="rgb(29, 155, 240)"
                    onClick={showPayModal}
                  >
                    Get Verified
                  </Button>
                )}
                {!loadingVerified && verified && (
                  <Verified className={styles.verified} />
                )}
              </h1>
              <p>
                {renderNumbers(subscribers, 'subscribers', t)},{' '}
                {renderNumbers(totalViews, 'views', t)}
              </p>
            </div>
            <div className={styles.subscribeButtonWrapper}>
              {!loadingIsSubscribed && channel?.id !== userData?.id && (
                <Button
                  onClick={toggleSubscribe}
                  loading={toggleSubscribeRunning}
                  cancel={subscribed}
                >
                  {subscribed ? t('subscribed') : t('subscribe')}
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.videos}>
          {videos.map((video) => (
            <VideoBoxHome video={video} user={channel!} hideChannelInfo edit={channel?.id === userData?.id} />
          ))}

          {!videos || (videos.length === 0 && <p>{t('channelNoVideos')}</p>)}
        </div>
      </Page>
      <Pay
        isOpen={isPayOpen}
        onRequestClose={onRequestClosePay}
        productName="Verified Badge"
        checkoutId={BADGE_CHECKOUT_ID}
        onPaymentDetected={onPaymentDetected}
        verifiedPrice={verifiedPrice}
      />
    </>
  )
}

export default Channel
