import { initializeApp } from 'firebase/app'
import { getStorage, ref } from 'firebase/storage'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAhhHcTBpTX5O01a4lQKUzvcKLOtDzdJyU',
  authDomain: 'ilecstdybtaosa.firebaseapp.com',
  projectId: 'ilecstdybtaosa',
  storageBucket: 'ilecstdybtaosa.appspot.com',
  messagingSenderId: '493940227667',
  appId: '1:493940227667:web:9a50692082acc4352e90a6',
}

initializeApp(firebaseConfig)
const storage = getStorage()
const fs = getFirestore()

export const getRef = (filePath: string) => ref(storage, filePath)

export function on<T>(
  collectionName: string,
  id: string,
  cb: (data: T | null | undefined) => Promise<void> | void,
) {
  return onSnapshot(
    doc(fs, collectionName, id),
    (doc) => {
      if (doc.exists()) {
        const data = {
          id,
          ...doc.data(),
        }
        // @ts-ignore
        cb(data as T)
      } else {
        cb(null)
      }
    },
    (err) => {
      console.warn(`${collectionName}/${id} error`, err)
      cb(null)
    },
  )
}
