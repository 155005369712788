const cache: { [key: string]: any } = {}

export const getCacheItem = <T>(key: string): T | null | undefined => {
  const value = cache[key] as T
  // console.log(`getCacheItem hit with ${key}`, value)
  return value
}

export const setCacheItem = <T>(key: string, value: T) => {
  cache[key] = value
  // console.log(`setCacheItem hit with ${key}`, value)
}
