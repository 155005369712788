export const TRANSLATIONS_EN = {
  homePageTitle: 'Decentralised Social Media for Everyone',
  footer: 'Built by <1>@ashappdev<1>',

  headerMyChannel: 'My Channel',
  headerUpload: 'Upload',
  headerMyAccount: 'My Account',
  headerSignUpOrLogin: 'Login',

  accountErrorUserNameEmpty: 'Please enter a user name',
  accountErrorProfilePhotoFailed:
    'New profile photo failed to upload, please try again',
  accountErrorUserNameAlreadyTaken:
    'This username is already taken, please try another',
  accountError: 'An error occurred, please try again',
  accountErrorValidImage: 'Please select a valid JPG/PNG file',
  accountErrorFailedResize: 'Failed to resize photo, please try again',
  accountPageTitle: 'My Account',
  accountUpdated: 'Your account is now updated',
  accountUserNameLabel: 'User name',
  accountChannelNameLabel: 'Channel name',
  accountProfilePhotoLabel: 'Profile photo',
  accountResizingPhoto: 'Resizing photo...',
  accountDone: 'Done',
  accountCancel: 'Cancel',
  accountLoadingPhoto: 'Loading photo...',
  accountChoosePhoto: 'Choose Photo',
  accountUploadingPhoto: 'Uploading profile photo...',
  accountUpdatingAccountDetails: 'Updating account details...',
  accountUpdateAccountDetails: 'Update Account Details',
  accountLogout: 'Logout',

  monetisationPageTitle: '💰 Monetisation',
  monetisationUpdated: 'Monetisation settings updated',
  monetisationICPAddress: 'ICP Address',
  monetisationICPAddressDesc: 'ICP address to receive payments (tips, paid subscriptions etc).',
  monetisationMonthlySubscription: 'Monthly Subscription',
  monetisationMonthlySubscriptionDesc: 'Amount you want to charge paid subscribers monthly.',
  monetisationUpdatingDetails: 'Updating...',
  monetisationUpdateDetails: 'Update settings',

  channelFailedToLoad: 'Failed to load channel info, please refresh the page',
  channelFailedToLoadVideos: 'Failed to load videos, please refresh the page',
  subscribers: '{{number}} subscribers',
  subscribersK: '{{number}}K subscribers',
  subscribersM: '{{number}}M subscribers',
  subscribersNone: 'No subscribers',
  subscribe: 'Subscribe',
  subscribed: 'Subscribed',
  join: 'Join',
  joined: 'Joined',
  channelNoVideos: 'No videos uploaded yet.',

  views: '{{number}} views',
  viewsK: '{{number}}K views',
  viewsM: '{{number}}M views',
  viewsNone: 'No views',

  loginPageTitle: 'Login/Register',
  loginIfNotRegistered: 'If you have not registered with DSocial before:',
  loginSignUpButton: 'Sign up with Internet Identity',
  loginIfRegistered: 'If you are already registered with DSocial:',
  loginButton: 'Login with Internet Identity',

  uploadDropHere: 'Drop here...',
  uploadFailedResize: 'Failed to resize photo, please try again',
  uploadErrorSelectThumbnail: 'Please select a thumbnail (JPG/PNG, 1280x720)',
  uploadErrorTransactionId: 'Please enter the video ID',
  uploadErrorVideoFile: 'Please select a video (MP4 only, max 1080p)',
  uploadErrorFailedUpload: 'Thumbnail failed to upload, please try again...',
  uploadErrorVideoFailedUpload: 'Video failed to upload, please try again...',
  uploadPageTitle: 'Upload your video',
  uploadInstallArConnect:
    "Please install ArConnect Extension first. Follow the <1>Getting Started</1> guide if you haven't already.",
  uploadFirstTime:
    "If this is the first time you've uploaded to DSocial, read this <1>Getting Started</1> guide first.",
  uploadThumbnailUploaded: 'Thumbnail uploaded',
  uploadThumbnailUploading: 'Thumbnail uploading, {{progress}}% done...',
  uploadVideoUploaded: 'Video uploaded',
  uploadVideoUploading: 'Video uploading, {{progress}}% done...',
  uploadVideoCreated: 'Video created',
  uploadVideoCreating: 'Creating video record...',
  uploadSelectThumbnail: 'Select thumbnail:',
  uploadResizingPhoto: 'Resizing photo...',
  uploadDone: 'Done',
  uploadCancel: 'Cancel',
  uploadDropLabel: 'Drop your thumbnail here (ideally 1280x720)...',
  uploadSelectThumbnailError: 'Please select a JPG/PNG thumbnail',
  uploadYourVideoWith:
    'Upload your video with <1>DSocial Uploader App</1>, once uploaded, copy the video ID and paste below.',
  uploadSelectVideo: 'Select video (MP4 only, max 1080p):',
  uploadPlaceholderTransactionId: 'Paste your video ID here...',
  uploadDropVideo: 'Drop your video here (MP4 only, 1080p max)...',
  uploadErrorDropVideo: 'Please select video (MP4 only, max 1080p)',
  uploadEnterTitle: 'Enter video title:',
  uploadPlaceholderTitle: 'Enter video title...',
  uploadEnterVideoDesc: 'Enter video description (optional):',
  uploadPlaceholderDesc: 'Enter description...',
  uploadButton: 'Upload',

  uploadErrorUploadToArWeaveFailed:
    'Upload to AR Weave blockchain failed, please try again',
  uploadErrorTranscodingFailed: 'Failed to transcode video, please try again',
  uploadThumbnailUploadedToARWeave: 'Thumbnail uploaded to AR Weave blockchain',
  uploadThumbnailUploadingToARWeave:
    'Thumbnail uploading to AR Weave blockchain, {{progress}}% done...',
  uploadVideoUploadedToARWeave: 'Video uploaded to AR Weave blockchain',
  uploadVideoUploadingToARWeave:
    'Video uploading to AR Weave blockchain, {{progress}}% done...',
  uploadVideoTranscoded: 'Video transcoded',
  uploadVideoTranscoding: 'Video transcoding, {{progress}}% done...',
  uploadFileTooBig: 'Video uploads are limited to 2GB',

  video1Like: '1 Like',
  videoLikes: '{{likes}} Likes',
  videoLike: 'Like',
  videoComments: '{{comments}} Comments',
  videoAddPublicComment: 'Add a public comment...',
  videoCancelButton: 'Cancel',
  videoSubmitButton: 'Comment',
  videoShare: 'Share',
  videoEmbed: 'Embed',
  videoTip: 'Tip',
  videoFlag: 'Report',

  videoTipTitle: 'Tip {{name}}',
  videoTipChooseAmount: 'Choose the amount to tip {{name}} and click Tip',
  videoTipPay: 'Pay Tip',
  videoTipConfirmInstallPlug:
    'You do not have Plug Wallet installed, install now?',
  videoTipProcessingPayment: 'Processing payment...',
  videoTipPaid: 'Tip successfully paid',
  videoTipPaidThankYou: 'Thank you! 🙏 {{name}} appreciates your tip ✨',
  videoTipAnon: 'Anon',
  videoTipComment: '✨ Tipped ${{amount}} ✨',

  videoJoinTitle: 'Join {{name}} Membership',

  notFoundError: 'Error Occurred',
  notFoundTitle: 'Page Not Found',
  notFoundContent: 'This page is not found. Go back to the <1>homepage</1>.',

  topTitle: 'Top Channels',

  searchPlaceholder: 'Search for videos...',

  sorting_recommended: 'Subscriptions',
  sorting_hot: 'Hot',
  sorting_most_viewed: 'Most Viewed',
  sorting_top_rated: 'Top Rated',
  sorting_newest: 'Newest',
  sorting_daily: 'Daily',
  sorting_weekly: 'Weekly',
  sorting_monthly: 'Monthly',
  sorting_yearly: 'Yearly',
  sorting_all_time: 'All Time',

  feedbackSendFeedback: 'Send Feedback',
  feedbackCloseForm: 'Close Form',
  feedbackFeedbackType: 'Type',
  feedbackName: 'Name',
  feedback: 'Feedback',
  feedbackPlaceholder: 'Enter your feedback here...',
  feedbackSendFeedbackFormButton: 'Send Feedback 👋',
  feedbackFeedbackType_general: 'General',
  feedbackFeedbackType_bug: 'Bug',
  feedbackFeedbackType_idea: 'Idea',
  feedbackSubmitted: 'Thank you! Feedback submitted',

  sideMenuHome: 'Home',
  sideMenuUpload: 'Upload',
  sideMenuMyChannel: 'My Channel',
  sideMenuMyAccount: 'My Account',
  sideMenuLoginOrRegister: 'Login',
  sideMenuOpen: 'Open',
  sideMenuClose: 'Close',
  sideMenuReviewFlagged: 'Content Moderation',
  sideMenuLogout: 'Logout',
  sideMenuPaidSubscriptions: 'Paid Subscriptions',
  sideMenuMonetisation: 'Monetisation',
  sideMenuAnalytics: 'Analytics',

  contentModerationFlaggedContent: 'Content Moderation',
  contentModerationUserManagement: 'User Management',
}
