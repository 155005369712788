export const TRANSLATIONS_DE = {
  homePageTitle: 'Dezentralisierte freie Social Media Plattform',
  footer: 'Erstellt von <1>@DSocialApp<1>',

  headerMyChannel: 'Mein Kanal',
  headerUpload: 'Hochladen',
  headerMyAccount: 'Mein Konto',
  headerSignUpOrLogin: 'Registrieren / Anmelden',

  accountErrorUserNameEmpty: 'Bitte gib einen Benutzernamen an',
  accountErrorProfilePhotoFailed:
    'Neues Profilbild konnte nicht hochgeladen werden, bitte versuche es nochmals',
  accountErrorUserNameAlreadyTaken:
    'Dieser Benutzername wird bereits verwendet, bitte wähle einen anderen',
  accountError: 'Ein Fehler ist aufgetreten, bitte versuche es nochmals',
  accountErrorValidImage: 'Wähle bitte eine gültige JPG/PNG Datei aus',
  accountErrorFailedResize:
    'Das Bild konnte nicht zugeschnitten werden, bitte versuche es nochmals',
  accountPageTitle: 'Mein Konto',
  accountUpdated: 'Dein Konto wurde erfolgreich aktualisiert',
  accountUserNameLabel: 'Benutzername',
  accountChannelNameLabel: 'Kanal Name',
  accountProfilePhotoLabel: 'Profilbild',
  accountResizingPhoto: 'Wird zugeschnitten...',
  accountDone: 'Fertig',
  accountCancel: 'Abbrechen',
  accountLoadingPhoto: 'Bild wird geladen...',
  accountChoosePhoto: 'Wähle ein Bild aus',
  accountUploadingPhoto: 'Profilbild wird hochgeladen...',
  accountUpdatingAccountDetails: 'Kontoinformationen werden aktualisiert...',
  accountUpdateAccountDetails: 'Kontoinformationen aktualisieren',
  accountLogout: 'Abmelden',

  channelFailedToLoad:
    'Informationen zum Kanal konnten nicht geladen werden, bitte aktualisiere die Seite',
  channelFailedToLoadVideos:
    'Video konnte nicht geladen werden, bitte aktualisiere die Seite',
  subscribers: '{{number}} Abonnenten',
  subscribersK: '{{number}}K Abonnenten',
  subscribersM: '{{number}}M Abonnenten',
  subscribersNone: 'Keine Abonnenten',
  subscribe: 'Abonnieren',
  subscribed: 'Abonniert',
  channelNoVideos: 'Bisher keine Videos hochgeladen.',

  views: '{{number}} Aufrufe',
  viewsK: '{{number}}K Aufrufe',
  viewsM: '{{number}}M Aufrufe',
  viewsNone: 'Keine Aufrufe',

  loginPageTitle: 'Anmelden/Registrieren',
  loginIfNotRegistered:
    'Falls du bisher noch nicht bei DSocial registriert bist:',
  loginSignUpButton: 'Registriere dich mit deiner Internet Identity',
  loginIfRegistered: 'Falls du bereits bei DSocial registriert bist:',
  loginButton: 'Melde dich mit deiner Internet Identity an',

  uploadDropHere: 'Hier ablegen...',
  uploadFailedResize:
    'Das Bild konnte nicht zugeschnitten werden, bitte versuche es nochmals',
  uploadErrorSelectThumbnail:
    'Wähle bitte ein Vorschaubild aus (JPG/PNG, 1280x720)',
  uploadErrorTransactionId: 'Gib bitte eine Video ID ein',
  uploadErrorVideoFile: 'Wähle bitte ein Video aus (MP4 only, max 1080p)',
  uploadErrorFailedUpload:
    'Vorschaubild konnte nicht hochgeladen werden, bitte versuche es nochmals...',
  uploadErrorVideoFailedUpload:
    'Video konnte nicht hochgeladen werden, bitte versuche es nochmals...',
  uploadPageTitle: 'Video hochladen',
  uploadInstallArConnect:
    'Bitte installiere zuerst die ArConnect Erweiterung. Folge dazu der <1>Erste Schritte</1> Anleitung, falls du das nicht bereits getan hast.',
  uploadFirstTime:
    'Falls du zum ersten Mal ein Video auf DSocial hochlädst, lies bitte zuerst die <1>Erste Schritte</1> Anleitung.',
  uploadThumbnailUploaded: 'Vorschaubild hochgeladen',
  uploadThumbnailUploading:
    'Vorschaubild wird hochgeladen, {{progress}}% fertig...',
  uploadVideoUploaded: 'Video hochgeladen',
  uploadVideoUploading: 'Video wird hochgeladen, {{progress}}% fertig...',
  uploadVideoCreated: 'Video erstellt',
  uploadVideoCreating: 'Video Eintrag wird erstellt...',
  uploadSelectThumbnail: 'Vorschaubild auswählen:',
  uploadResizingPhoto: 'Bild wird zugeschnitten...',
  uploadDone: 'Fertig',
  uploadCancel: 'Abbrechen',
  uploadDropLabel: 'Lege hier dein Vorschaubild ab (idealerweise 1280x720)...',
  uploadSelectThumbnailError: 'Bitte wähle ein JPG/PNG Vorschaubild aus',
  uploadYourVideoWith:
    'Lade dein Video mit der <1>DSocial Uploader App</1> hoch. Wenn du fertig bist, kopiere die Video ID und füge sie hier ein.',
  uploadSelectVideo: 'Wähle ein Video aus (Nur MP4, höchstens 1080p):',
  uploadPlaceholderTransactionId: 'Hier Video ID einfügen...',
  uploadDropVideo: 'Lege dein Video hier ab (Nur MP4, höchstens 1080p)...',
  uploadErrorDropVideo: 'Wähle bitte ein Video aus (Nur MP4, höchstens 1080p)',
  uploadEnterTitle: 'Gib den Titel des Videos ein:',
  uploadPlaceholderTitle: 'Hier Video Titel eingeben...',
  uploadEnterVideoDesc: 'Gib eine Video Beschreibung ein (optional):',
  uploadPlaceholderDesc: 'Hier Videobeschreibung eingeben...',
  uploadButton: 'Hochladen',

  uploadErrorUploadToArWeaveFailed:
    'Das Hochladen zur AR Weave Blockchain ist fehlgeschlagen, bitte versuche es nochmals',
  uploadErrorTranscodingFailed:
    'Video konnte nicht verarbeitet werden, bitte versuche es nochmals',
  uploadThumbnailUploadedToARWeave:
    'Vorschaubild wurde zur AR Weave Blockchain hochgeladen',
  uploadThumbnailUploadingToARWeave:
    'Vorschaubild wird zur AR Weave Blockchain hochgeladen, {{progress}}% fertig...',
  uploadVideoUploadedToARWeave:
    'Video wurde zur AR Weave Blockchain hochgeladen',
  uploadVideoUploadingToARWeave:
    'Video wird zur AR Weave Blockchain hochgeladen, {{progress}}% fertig...',
  uploadVideoTranscoded: 'Video verarbeitet',
  uploadVideoTranscoding: 'Video wird verarbeitet, {{progress}}% fertig...',
  uploadFileTooBig: 'Video Uploads sind auf 2GB begrenzt',

  video1Like: '1 Like',
  videoLikes: '{{likes}} Likes',
  videoLike: 'Like',
  videoComments: '{{comments}} Kommentare',
  videoAddPublicComment: 'Öffentlichen Kommentar hinzufügen...',
  videoCancelButton: 'Abbrechen',
  videoSubmitButton: 'Kommentieren',

  notFoundError: 'Ein Fehler ist aufgetreten',
  notFoundTitle: 'Seite nicht gefunden',
  notFoundContent:
    'Die Seite wurde nicht gefunden. Zurück zur <1>homepage</1>.',

  topTitle: 'Top Kanäle',

  searchPlaceholder: 'Videos suchen...',

  sorting_recommended: 'Abonnements',
  sorting_hot: 'Heiß',
  sorting_most_viewed: 'Am häufigsten gesehen',
  sorting_top_rated: 'Bestbewertet',
  sorting_newest: 'Neueste',
  sorting_daily: 'Täglich',
  sorting_weekly: 'Wöchentlich',
  sorting_monthly: 'Monatlich',
  sorting_yearly: 'Jährlich',
  sorting_all_time: 'Alle Zeit',

  feedbackSendFeedback: 'Feedback schicken',
  feedbackCloseForm: 'Enge Form',
  feedbackFeedbackType: 'Typ',
  feedbackName: 'name',
  feedback: 'Rückmeldung',
  feedbackPlaceholder: 'Geben Sie hier Ihr Feedback ein ...',
  feedbackSendFeedbackFormButton: 'Feedback schicken👋',
  feedbackFeedbackType_general: 'Allgemein',
  feedbackFeedbackType_bug: 'Insekt',
  feedbackFeedbackType_idea: 'Idee',
  feedbackSubmitted: 'Danke!Feedback eingereicht',

  sideMenuHome: 'Heim',
  sideMenuUpload: 'Hochladen',
  sideMenuMyChannel: 'Mein Kanal',
  sideMenuMyAccount: 'Mein Konto',
  sideMenuLoginOrRegister: 'Anmeldung',
  sideMenuOpen: 'Offen',
  sideMenuClose: 'Schließen',
  sideMenuReviewFlagged: 'Überprüfung markierter Inhalte',
  sideMenuLogout: 'Abmelden',
}
