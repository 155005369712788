import { useEffect } from 'react'
import { useHistory } from 'react-router'
import pages, { generateUrl } from '..'

import { Loading, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import { useUser } from '../../hooks'

const GetVerified = () => {
  const history = useHistory()
  const { loading, userData, loggedIn } = useUser()
  const channelPath =
    loggedIn && userData?.userName
      ? generateUrl(pages.channel.path, { userName: userData?.userName })
      : ''

  useEffect(() => {
    if (!loading && !loggedIn) {
      history.replace(pages.login.path)
      return
    }
    if (channelPath) {
      history.replace(channelPath)
    }
  }, [loading, loggedIn, channelPath])

  return (
    <Page title="Get Verified">
      <Loading />
    </Page>
  )
}

export default GetVerified
