import { ChangeEvent, ReactNode, useCallback, useState, useEffect } from 'react'
import { SkynetClient } from 'skynet-js'
import Arweave from 'arweave'
import slugify from 'slugify'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { IoCloudDownloadOutline, IoTrendingUpOutline } from 'react-icons/io5'
import { Link, useParams } from 'react-router-dom'
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone'
import { JWKInterface } from 'arweave/node/lib/wallet'
import { useHistory } from 'react-router'
import { create } from 'ipfs-http-client'

import pages, { generateUrl } from '..'
import { Button, Page, ErrorBox, CachedImage, Loading } from '../../components'
import { getBackend } from '../../lib/icp'
import { withAuthCheck } from '../../hoc'
import {
  USE_WALLET,
  AR_WEAVE_CONFIG,
  IPFS_CONFIG,
  AR_ENABLED,
  CLOUD_UPLOAD,
  CLOUD_TRANSCODING,
} from '../../config'
import { getCroppedImg } from '../../lib/canvas'
import Cropper from 'react-easy-crop'
import { Asset, Video__1 } from '../../types/backend/dsocial/dsocial.did'
import { HLS } from '../../config/assetCategories'
import { useTranslation, useUser } from '../../hooks'

import styles from './EditVideo.module.scss'
import { TFunction, Trans } from 'react-i18next'
import { uploadToCloud } from '../../lib/upload'
import { on } from '../../lib/db'
import { TranscodingProgress } from '../../types'

const arweave = Arweave.init(AR_WEAVE_CONFIG)

const ipfs = create(IPFS_CONFIG)

const ipfsUpload = async (
  file: File,
  contentType: string,
  onUploadProgress?: (progress: number) => void,
  blob?: Blob,
): Promise<string | null> => {
  let i = 0
  const max = 3

  let newBlob: Blob | undefined = blob

  if (!newBlob) {
    const ar = await getArrayBuffer(file)

    if (!ar) return null

    newBlob = new Blob([ar], { type: contentType })
  }

  for (; i < max; i += 1) {
    try {
      const result = await ipfs.add(newBlob, {
        progress: (bytes: number) => {
          onUploadProgress &&
            onUploadProgress(Math.ceil((bytes / file.size) * 100))
        },
        pin: true,
      })
      return result.path
    } catch (e) {
      console.log('failed to upload, trying again...', i + 1, e)
      await new Promise((resolve) => setTimeout(resolve, 500 * (i + 1)))

      // last try
      if (i === max - 1) {
        Sentry.captureException(e)
      }
    }
  }

  return null
}

const upload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number, status?: string) => void,
  blob?: Blob,
  fileName?: string,
) => {
  if (CLOUD_UPLOAD && fileName) {
    return uploadToCloud(fileName!, file, onUploadProgress, blob)
  }

  return AR_ENABLED
    ? arweaveUpload(file, contentType, onUploadProgress)
    : ipfsUpload(file, contentType, onUploadProgress, blob)
}

const arweaveUpload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number, status?: string) => void,
): Promise<string | null> => {
  try {
    const key = USE_WALLET ? 'use_wallet' : await arweave.wallets.generate()
    const thumbBuffer = await getArrayBuffer(file)

    if (!thumbBuffer) {
      console.error('thumbBuffer is null')
      return ''
    }

    const transaction = await getTransaction(thumbBuffer!, contentType, key)
    const mpdFee = await arweave.ar.winstonToAr(transaction.reward)

    console.log('mpdFee', mpdFee)
    console.log('in $', Number(mpdFee) * 54.32)

    console.log('before sign')
    await arweave.transactions.sign(transaction, key)
    console.log('after sign', transaction)

    let uploader = await arweave.transactions.getUploader(transaction)

    while (!uploader.isComplete) {
      await uploader.uploadChunk()
      console.log(
        `${uploader.pctComplete}% complete, ${uploader.uploadedChunks}/${uploader.totalChunks}`,
      )
      onUploadProgress(uploader.pctComplete)
    }

    return transaction.id
  } catch (e) {
    console.error(e)
    return null
  }
}

const dsocialUpload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number) => void,
): Promise<string | null> => {
  try {
    const data = new FormData()
    data.append('body', file)

    await axios.post('http://localhost:8999/upload', data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    return ''
  } catch (e) {
    console.error(e)
    return null
  }
}

const getArrayBuffer = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new window.FileReader()

    reader.onloadend = () => {
      resolve(reader.result)
    }

    reader.readAsArrayBuffer(file)
  })

const getTransaction = async (
  file: string | ArrayBuffer,
  contentType: string,
  key?: JWKInterface | 'use_wallet',
) => {
  // @ts-ignore
  console.log('getTransaction start', file.byteLength, new Date().toISOString())
  const transaction = await arweave.createTransaction({ data: file }, key)
  console.log('after createTransaction', new Date().toISOString())
  transaction.addTag('Content-Type', contentType)

  return transaction
}

const FILE_SIZE_LIMIT = 10 * 1024 * 1024 * 1024

interface DropperProps {
  onDrop: (file: File) => void
  onError: (error: string) => void
  dropLabel: ReactNode | string
  fileTypes: string[]
  invalidFileTypeError: string
}

const Dropper = ({
  onDrop,
  onError,
  dropLabel,
  fileTypes,
  invalidFileTypeError,
}: DropperProps) => {
  const { t } = useTranslation()
  const onDropInternal = useCallback(
    (acceptedFiles: File[], _: FileRejection[], _2: DropEvent) => {
      onError('')

      if (!acceptedFiles || acceptedFiles.length === 0) return

      const file = acceptedFiles[0]

      if (
        fileTypes.filter((fileType) => fileType.indexOf(file.type) !== -1)
          .length === 0
      ) {
        onError(invalidFileTypeError)
        return
      }

      onDrop(file)
    },
    [],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropInternal,
  })

  return (
    <div
      {...getRootProps()}
      className={`${styles.dropper} ${isDragActive ? styles.active : ''}`}
    >
      <input {...getInputProps()} />

      <IoCloudDownloadOutline />

      <p>{isDragActive ? t('uploadDropHere') : dropLabel}</p>
    </div>
  )
}

interface Progress {
  uploadProgress?: number
  uploaded?: boolean
  transcodingProgress?: number
  transcoded?: boolean
  uploadARWeaveProgress?: number
  uploadedToARWeave?: boolean
}

interface UploadingProgress {
  thumbnail?: Progress
  video?: Progress
  record?: Progress
}

interface TranscodeComplete {
  transactionId: string
  videoLength?: number
}

const InfoBox = ({ children }: { children: ReactNode }) => (
  <div className={styles.infoBox}>
    <p>{children}</p>
  </div>
)

const fileExtension = (fileName: string) => fileName.split('.').pop()

const processFile = (
  t: TFunction<'translation', undefined>,
  fileName: string,
  setProgress: (newProgress: Progress) => void,
  originalProgress: Progress | null | undefined,
) =>
  new Promise<TranscodeComplete | null | undefined>(async (resolve, reject) => {
    let progressObj: Progress = {
      ...(originalProgress || {}),
    }

    try {
      await axios.post(
        'https://us-central1-ilecstdybtaosa.cloudfunctions.net/queueTranscoding',
        {
          name: fileName,
        },
      )

      const id = fileName.replace(/\.[^/.]+$/, '')

      const unsubscribe = on<TranscodingProgress>('progress', id, (status) => {
        if (status) {
          const {
            progress,
            failed,
            completed,
            uploadedFailed,
            uploaded,
            transactionId,
            videoLength,
          } = status

          if (transactionId) {
            setProgress({
              ...progressObj,
              uploadedToARWeave: true,
              uploadARWeaveProgress: uploaded,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            resolve({ transactionId, videoLength })
            unsubscribe()
            return
          }

          if (uploaded) {
            setProgress({
              ...progressObj,
              uploadedToARWeave: uploaded === 100,
              uploadARWeaveProgress: uploaded,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            return
          }

          if (uploadedFailed) {
            unsubscribe()
            reject(new Error(t('uploadErrorUploadToArWeaveFailed')))
            return
          }

          if (completed) {
            setProgress({
              ...progressObj,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            return
          }

          if (failed) {
            unsubscribe()
            reject(new Error(t('uploadErrorTranscodingFailed')))
            return
          }

          if (progress) {
            setProgress({
              ...progressObj,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: progress,
              transcoded: false,
            })
            return
          }
        }
      })
    } catch (e) {
      reject(new Error(t('uploadErrorFailedUpload')))
    }
  })

const Upload = () => {
  const { t } = useTranslation()
  const { videoId } = useParams<{ videoId?: string }>()
  const history = useHistory()
  const { loading, loggedIn, userData } = useUser()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState('')
  const [video, setVideo] = useState<Video__1 | null | undefined>()
  const [loadingVideo, setLoadingVideo] = useState(true)

  useEffect(() => {
    if (!loading && loggedIn && (!userData || !userData.userName)) {
      history.push(pages.account.path)
    } else if (!videoId) {
      history.replace('/')
    } else {
      const load = async () => {
        const backend = await getBackend()
        const videoResults = await backend.getVideo(videoId)
        const videoItem = videoResults.length > 0 ? videoResults[0] : null

        if (videoItem) {
          setTitle(videoItem?.title)
          setDescription(videoItem?.desc)
          setVideo(videoItem)
          setLoadingVideo(false)
        } else {
          history.replace('/')
        }
      }
      load()
    }
  }, [history, videoId, loading, loggedIn, userData?.userName])

  const saveVideo = async () => {
    if (!title) {
      setError('Enter a title')
      return
    }
    if (!description) {
      setError('Enter a description')
      return
    }

    setLoadingVideo(true)

    const backend = await getBackend()
    const updateResult = await backend.updateVideo(videoId!, title, description)
    console.log('updateResult', updateResult)
    history.push(
      generateUrl(pages.video.path, {
        videoId,
        videoSlug: slugify(title, { lower: true, strict: true }),
      }),
    )
  }

  const deleteVideo = async () => {
    const sure = window.confirm('Are you sure you want to delete this video? This is irreversible')

    if (sure) {
      setLoadingVideo(true)
      const backend = await getBackend()
      const deleteResult = await backend.deleteVideo(videoId!)
      console.log('deleteResult', deleteResult)
      const channelPath = generateUrl(pages.channel.path, { userName: userData?.userName })
      history.push(channelPath)
    }
  }

  if (loadingVideo) return <Loading />

  return (
    <Page title={`Edit Video | DSocial`}>
      <h2>Edit: {video?.title}</h2>

      {error && (
        <ErrorBox>{error}</ErrorBox>
      )}
      
      <form
        className={styles.uploadForm}
        onSubmit={(event) => {
          event.preventDefault()
          saveVideo()
        }}
      >
        <label htmlFor="title">
          {t('uploadEnterTitle')}
          <input
            id="title"
            type="text"
            placeholder={t('uploadPlaceholderTitle')}
            required
            autoComplete="off"
            value={title}
            onChange={(e) => {
              setError('')
              setTitle(e.target.value)
            }}
          />
        </label>

        <label htmlFor="desc">
          {t('uploadEnterVideoDesc')}
          <textarea
            id="desc"
            placeholder={t('uploadPlaceholderDesc')}
            value={description}
            onChange={(e) => {
              setError('')
              setDescription(e.target.value)
            }}
            rows={4}
          />
        </label>
        <div style={{ padding: 10, flexDirection: 'row', display: 'flex' }}>
          <Button type="submit">Save</Button>
          <div style={{ width: 6 }} />
          <Button type="button" onClick={deleteVideo} backgroundColor="red">Delete</Button>
        </div>
      </form>
    </Page>
  )
}

export default withAuthCheck(Upload)
