import { TFunction } from 'react-i18next'

const renderNumbers = (
  num: BigInt | bigint | number | undefined,
  translationIdPrefix: string,
  t: TFunction<'translation', undefined>,
) => {
  const newNum = Number(num || 0)

  if (newNum < 1) return t(`${translationIdPrefix}None`)

  if (newNum >= 1000000)
    return t(`${translationIdPrefix}M`, {
      number: Number(newNum / 1000000).toFixed(1),
    })

  if (newNum >= 1000) {
    return t(`${translationIdPrefix}K`, {
      number:
        newNum / 1000 === 0
          ? Math.round(newNum / 1000)
          : (newNum / 1000).toFixed(1),
    })
  }

  return t(`${translationIdPrefix}`, { number: newNum })
}

export default renderNumbers
