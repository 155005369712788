import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Loading, Page, VideoBoxHome } from '../../components'
import pages from '../../pages'
import { useUser, useTranslation } from '../../hooks'
import { getBackend } from '../../lib/icp'
import { VideoAndUser } from '../../types/backend/dsocial/dsocial.did'

import styles from './Home.module.scss'
import { Tags } from '../../config/tags'
import moderators from '../../config/moderators'
import { SortBy, TimeRange } from './enums'
import Sorting from './Sorting'

const paramSupport = !!window.URLSearchParams

const convertMomentToBigInt = (mo: Moment) => BigInt(mo.valueOf() * 1000000)
const getFromDate = (newTimeRange: TimeRange) => {
  let mo = moment()
  switch (newTimeRange) {
    case TimeRange.Daily:
      mo = mo.subtract(24, 'hours')
      break

    case TimeRange.Weekly:
      mo = mo.subtract(7, 'days')
      break

    case TimeRange.Monthly:
      mo = mo.subtract(1, 'months')
      break

    case TimeRange.Yearly:
      mo = mo.subtract(1, 'years')
      break

    default:
      mo = moment('2021-06-02')
      break
  }

  return convertMomentToBigInt(mo)
}

const Home = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { loading, loggedIn, userData } = useUser()
  const [videos, setVideos] = useState<VideoAndUser[]>([])
  const [previewVideoId, setPreviewVideoId] = useState('')
  const [loadingVideos, setLoadingVideos] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  const params = paramSupport
    ? new URLSearchParams(window.location.search)
    : null
  const defaultSort =
    params && params?.get('s') ? (params?.get('s') as SortBy) : SortBy.Hot
  const defaultTime =
    params && params?.get('t')
      ? (params?.get('t') as TimeRange)
      : TimeRange.Weekly
  const [sortBy, setSortBy] = useState<SortBy>(defaultSort)
  const [timeRange, setTimeRange] = useState<TimeRange>(defaultTime)
  const approvalMode =
    params &&
    !!params?.get('a') &&
    userData?.id &&
    moderators.indexOf(userData?.id) !== -1

  const load = useCallback(
    async (
      append: boolean = false,
      offset: number = 0,
      maxResults: number = 12,
      newSortBy: SortBy = SortBy.Hot,
      newTimeRange: TimeRange = TimeRange.Weekly,
    ) => {
      setLoadingMore(append)
      setHasMore(false)
      try {
        const backend = await getBackend()
        const start = Date.now()
        const recommendedVideosResult = approvalMode
          ? await backend.getRecommendedWithTags(
              BigInt(offset),
              BigInt(maxResults),
              [Tags.Reviewed],
              false,
            )
          : await backend.getVideosWithTagsAndSort(
              BigInt(offset),
              BigInt(maxResults),
              [Tags.CopyrightedContent, Tags.Illegal, Tags.Nsfw],
              false,
              newSortBy,
              getFromDate(
                newSortBy !== SortBy.Newest && newSortBy !== SortBy.Recommended
                  ? newTimeRange
                  : TimeRange.AllTime,
              ),
            )

        console.log(
          `${
            approvalMode ? 'getRecommendedWithTags' : 'getVideosWithTagsAndSort'
          } took ${Date.now() - start}ms`,
          newSortBy,
        )
        if (recommendedVideosResult.length > 0) {
          const [videosList] = recommendedVideosResult
          if (videosList && videosList.length > 0) {
            if (append) {
              setVideos([...videos, ...videosList])
            } else {
              setVideos(videosList)
            }
            setHasMore(true)
          }
        } else if (!append) {
          setVideos([])
        }
      } catch (e) {
        console.error(e)
      }
      setLoadingVideos(false)
      setLoadingMore(false)
    },
    [videos, approvalMode, sortBy, timeRange],
  )

  useEffect(() => {
    setLoadingVideos(true)
    setSortBy(defaultSort)
    setTimeRange(defaultTime)
    load(false, 0, 12, defaultSort, defaultTime)
  }, [approvalMode, defaultSort, defaultTime])

  useEffect(() => {
    if (!loading && loggedIn && (!userData || !userData.userName)) {
      history.push(pages.account.path)
    }
    console.log('userData', userData)
  }, [loading, loggedIn, userData?.userName])

  // useEffect(() => {
  //   load()
  // }, [approvalMode])

  const onVideoMouseOver = (videoId: string) => {
    if (approvalMode) setPreviewVideoId(videoId)
  }

  const onVideoMouseOut = (videoId: string) => {
    if (approvalMode) setPreviewVideoId('')
  }

  const updateTag = useCallback(
    async (videoId: string, tag: string) => {
      if (approvalMode) {
        // Remove video from local list
        const newVideosList = [...videos]
        // console.log('newVideosList', newVideosList)
        newVideosList.splice(
          newVideosList.findIndex(({ video }) => video.id === videoId),
          1,
        )

        // console.log('newVideosList', newVideosList)
        setVideos(newVideosList)

        const backend = await getBackend()
        const start = Date.now()
        const result = await backend.updateTags(videoId, [Tags.Reviewed, tag])
        console.log(
          `updateTags(${videoId}, ${tag}) took ${Date.now() - start}ms`,
          result,
        )
        // load()
      }
    },
    [approvalMode, videos],
  )

  const sortOnChange = useCallback(
    (newSortBy: SortBy, newTimeRange: TimeRange) => {
      // setLoadingVideos(true)
      // setSortBy(newSortBy)
      // setTimeRange(newTimeRange)
      // load(false, 0, 12, newSortBy, newTimeRange)
      let newUrl = `/?s=${newSortBy}&t=${newTimeRange}`
      history.push(newUrl)
    },
    [],
  )

  return (
    <Page title={`DSocial - ${t('homePageTitle')}`} className={styles.content}>
      {loadingVideos ? (
        <Loading />
      ) : (
        <>
          <div className={styles.videos}>
            {approvalMode ? (
              <div className={styles.approvalMode}>Approval Mode ON</div>
            ) : (
              <Sorting
                sortBy={sortBy}
                timeRange={timeRange}
                onChange={sortOnChange}
              />
            )}
            <InfiniteScroll
              dataLength={videos.length}
              next={() => load(true, videos.length, 12, sortBy, timeRange)}
              hasMore={hasMore}
              loader={loadingMore && <Loading small />}
            >
              {videos.map(({ video, user, verified }, i) => (
                <VideoBoxHome
                  video={video}
                  user={user}
                  verified={verified}
                  onVideoMouseOver={onVideoMouseOver}
                  preview={!!approvalMode && previewVideoId === video.id}
                  approvalMode={!!approvalMode}
                  updateTag={updateTag}
                />
              ))}
            </InfiniteScroll>
          </div>
        </>
      )}

      {/* <iframe width="560" height="315" src="https://dsocial.app/2-504?embed=y" title="DSocial video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
    </Page>
  )
}

export default Home
