import { IPFS_BASE_URL } from '../config'

let hashPreloading = ''
let isPreloading = false
let tempImage = ''

const generateProfilePhotoUrl = (
  profilePhotoHash?: string | null,
  force?: boolean,
) => {
  if (
    hashPreloading === profilePhotoHash &&
    isPreloading &&
    tempImage &&
    !force
  )
    return tempImage

  if (profilePhotoHash) return `${IPFS_BASE_URL}${profilePhotoHash}`

  return '/profile.png'
}

export const preloadProfilePhoto = async (
  hash: string,
  tempBase64Image: string,
) => {
  isPreloading = true
  tempImage = tempBase64Image
  hashPreloading = hash

  // Try 3 times just in case internet dropped
  for (let i = 0; i < 3; i += 1) {
    try {
      await new Promise<void>((resolve, reject) => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = () => {
          resolve()
        }
        image.onerror = () => {
          reject()
        }
        image.src = generateProfilePhotoUrl(hash, true)
      })
      break
    } catch (e) {
      console.log('failed to load image from IPFS, trying again...')
      await new Promise((resolve) => setTimeout(resolve, 500 * (i + 1)))
    }
  }

  isPreloading = false
  tempImage = ''
  hashPreloading = ''
}

export default generateProfilePhotoUrl
