import { Link } from 'react-router-dom'
import { ReactComponent as LogoSvg } from './logo.svg'

import styles from './Logo.module.scss'

interface LogoProps {
  target?: string
  hideText?: boolean
  className?: string
  disableResponsive?: boolean
}

const Logo = ({
  target,
  hideText,
  className,
  disableResponsive,
}: LogoProps) => (
  <h1 className={`${styles.logoWrapper} ${className}`}>
    <Link to="/" className={styles.logo} target={target} title="DSocial">
      <LogoSvg />
      {!hideText && (
        <span className={disableResponsive ? '' : styles.text}>DSocial</span>
      )}
    </Link>
  </h1>
)

export default Logo
