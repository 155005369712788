import { ReactNode } from 'react'

import styles from './ErrorBox.module.scss'

const ErrorBox = ({ children }: ErrorBoxProps) => (
  <div className={styles.error}>{children}</div>
)

interface ErrorBoxProps {
  children: ReactNode
}

export default ErrorBox
