import { ReactNode } from 'react'
import {
  IoFlashSharp,
  IoHeartSharp,
  IoLeafSharp,
  IoSparklesSharp,
  IoStatsChartSharp,
} from 'react-icons/io5'
import cn from 'classnames'

import { Button } from '../../components'
import { useTranslation, useUser } from '../../hooks'
import { SortBy, TimeRange } from './enums'

import styles from './Sorting.module.scss'

const SORT_LIST = Object.values(SortBy)
const TIME_RANGE_LIST = Object.values(TimeRange)

const SORT_ICONS = {
  [SortBy.Recommended]: IoSparklesSharp,
  [SortBy.Hot]: IoFlashSharp,
  [SortBy.MostViewed]: IoStatsChartSharp,
  [SortBy.TopRated]: IoHeartSharp,
  [SortBy.Newest]: IoLeafSharp,
}

interface SortingProps {
  sortBy: SortBy
  timeRange: TimeRange
  onChange: (sortBy: SortBy, timeRange: TimeRange) => void
}

const List = ({ children }: { children: ReactNode }) => (
  <div className={styles.list}>{children}</div>
)

const renderIcon = (sortBy: SortBy) => {
  const IconComponent = SORT_ICONS[sortBy]
  return <IconComponent />
}

const Sorting = ({ onChange, sortBy, timeRange }: SortingProps) => {
  const { loggedIn } = useUser()
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <List>
        {SORT_LIST.map((sortById) =>
          sortById !== SortBy.Recommended || loggedIn ? (
            <Button
              className={cn(styles.sortByButton, {
                [styles.selected]: sortById === sortBy,
              })}
              small
              onClick={() => onChange(sortById, timeRange)}
              icon={renderIcon(sortById)}
            >
              {t(`sorting_${sortById}`)}
            </Button>
          ) : null,
        )}
      </List>

      {sortBy !== SortBy.Newest && sortBy !== SortBy.Recommended && (
        <List>
          {TIME_RANGE_LIST.map((timeRangeId) => (
            <Button
              className={cn(styles.timeRangeButton, {
                [styles.selected]: timeRangeId === timeRange,
              })}
              small
              onClick={() => onChange(sortBy, timeRangeId)}
            >
              {t(`sorting_${timeRangeId}`)}
            </Button>
          ))}
        </List>
      )}
    </div>
  )
}

export default Sorting
