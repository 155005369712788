import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Loading, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import { useUser } from '../../hooks'
import { getBackend } from '../../lib/icp'
import { UserPermission } from '../../types'

const Admin = () => {
  const history = useHistory()
  const { loading, loggedIn, userPermissions } = useUser()
  const [dataStats, setDataStats] = useState<[string, bigint][]>([])

  useEffect(() => {
    console.log('userPermissions', userPermissions)
    if (!loading && loggedIn && userPermissions) {
      if (
        userPermissions.indexOf(UserPermission.Admin) !== -1 ||
        userPermissions.indexOf(UserPermission.Owner) !== -1
      ) {
        const load = async () => {
          const backend = await getBackend()
          // cool
          const dataStatsResult = await backend.getDataStats()
          setDataStats(dataStatsResult)
        }

        load()
      } else {
        history.replace('/')
      }
    }
  }, [loading, loggedIn, userPermissions])

  if (loading) {
    return <Loading />
  }

  return (
    <Page title="Admin">
      <p>Admin</p>

      <ul>
        {dataStats.map(([variable, len]) => (
          <li>
            <strong>{variable}:</strong> {Number(len).toLocaleString()}
          </li>
        ))}
      </ul>
    </Page>
  )
}

export default withAuthCheck(Admin)
