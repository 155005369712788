import ClipLoader from 'react-spinners/ClipLoader'

import { useColorScheme } from '../../hooks'
import { ColorScheme } from '../../types'

import styles from './Loading.module.scss'

const Loading = ({ small }: LoadingProps) => {
  const colorScheme = useColorScheme()

  return (
    <div className={small ? styles.small : styles.container}>
      <ClipLoader
        loading
        size={32}
        color={colorScheme === ColorScheme.Dark ? 'white' : '#333'}
      />
    </div>
  )
}

interface LoadingProps {
  small?: boolean
}

export default Loading
