import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { QueryParamProvider } from 'use-query-params'
import { ENV } from './config'

import { Usergeek } from 'usergeek-ic-js'

if (window.location.host !== 'localhost:3000') {
  Sentry.init({
    dsn: 'https://8ea9edee387244b68da5226861119821@o1073695.ingest.sentry.io/6073445',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

import './translations/i18n'

import { ThemeProvider, UserProvider } from './components'
import pagesObject from './pages'

import './styles/global.scss'
import Feedback from './components/Feedback'

Usergeek.init({
  apiKey: '011E0138D24FC7C414E62D0B78F3EEAE',
})

const pages = Object.entries(pagesObject)

const App = () => {
  const embedMode = window.location.pathname.toLowerCase().startsWith('/embed')
  const wrongUrl = !embedMode && window.location.host.indexOf('raw.ic0.app') !== -1

  useEffect(() => {
    if (wrongUrl) {
      window.location.href = window.location.href.replace('raw.ic0.app', 'ic0.app')
    }
  }, [wrongUrl])

  return (
    <ThemeProvider>
      <UserProvider>
        <>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Switch>
                {pages.map(([key, { path, component }]) => (
                  <Route key={key} path={path} component={component} />
                ))}
              </Switch>
            </QueryParamProvider>
          </Router>
          <Feedback />
        </>
      </UserProvider>
    </ThemeProvider>
  )
}

export default App
