import { Link, useHistory } from 'react-router-dom'

import pages from '../../pages'
import { Button, Page } from '../../components'
import { useTranslation } from '../../hooks'
import { Trans } from 'react-i18next'
import { useEffect } from 'react'
import { AR_ENABLED } from '../../config'

const GettingStarted = () => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (!AR_ENABLED) {
      history.replace(pages.upload.path)
    }
  }, [])

  return (
    <Page title={t('gettingStartedTitle')}>
      <h1>{t('gettingStartedTitle')}</h1>

      <p>{t('gettingStartedPara1')}</p>

      <p>
        <Trans i18nKey="gettingStartedPara2">
          Videos and thumbnails on DSocial are stored on the ARWeave blockchain.
          This is a censorship resistent blockchain specifically for persistent
          storage, this means your videos and thumbnails will outlive you. As
          such, you will need a wallet with $AR tokens to upload videos to
          DSocial, don't worry it's very simple. If you have any issues, reach
          out to us at <a href="https://twitter.com/DSocialApp">@DSocialApp</a>{' '}
          on Twitter.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="gettingStartedPara3">
          If you already have $AR tokens in a wallet, you can go back to the{' '}
          <Link to={pages.upload.path}>upload page</Link>.
        </Trans>
      </p>

      <h2>{t('gettingStartedHeading1')}</h2>

      <p>{t('gettingStartedPara4')}</p>

      <p>
        <Button href="https://arconnect.io/">
          {t('gettingStartedGetArConnect')}
        </Button>
      </p>

      <h2>{t('gettingStartedHeading2')}</h2>

      <p>
        <Trans i18nKey="gettingStartedPara5">
          Adding $AR tokens with a debit/credit card is easy with either Ramp
          Network or Transak. But if you prefer to use one of the exchanges,
          ARDrive put together a helpful list of exchanges that support $AR
          tokens{' '}
          <a
            href="https://ardrive.atlassian.net/wiki/spaces/help/pages/86605844/Cryptocurrency+Exchanges"
            target="_blank"
          >
            here
          </a>
          .
        </Trans>
      </p>

      <p>{t('gettingStartedPara6')}</p>

      <p>
        <Button inline href="https://app.transak.com/?defaultCryptoCurrency=AR">
          {t('gettingStartedTransak')}
        </Button>
        &nbsp;
        <Button inline href="https://ramp.network/buy/?defaultAsset=Arweave_AR">
          {t('gettingStartedRamp')}
        </Button>
      </p>

      <h2>{t('gettingStartedHeading3')}</h2>

      <p>
        <Trans i18nKey="gettingStartedPara7">
          To upload videos you'll need to install the{' '}
          <Link to={pages.uploaderApp.path} target="_blank">
            DSocial Uploader App
          </Link>
          .
        </Trans>
      </p>

      <h2>{t('gettingStartedHeading4')}</h2>

      <p>
        <Trans i18nKey="gettingStartedPara8">
          Go back to the <Link to={pages.upload.path}>upload page</Link> and
          upload your first video!
        </Trans>
      </p>
    </Page>
  )
}

export default GettingStarted
