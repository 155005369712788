import { getBackend } from './icp'
import getDt from './dt'

const trackWatchTime = async (
  videoId: string,
  watchTime: number,
  watchTimeRate: number,
) => {
  const start = Date.now()
  // console.log(`trackWatchTime for ${videoId}`)

  try {
    const backend = await getBackend()
    const caller = await backend.getCallerInfo()
    const res = await backend.incrementWatchTime(
      videoId,
      caller.isAnonymous(),
      getDt(),
      watchTime,
      watchTimeRate,
    )
    // console.log(`trackWatchTime took ${Date.now() - start}ms`, res)
  } catch (e) {
    console.warn('trackWatchTime failed', e)
  }
}

export default trackWatchTime
