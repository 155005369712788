import {
  Actor,
  HttpAgent,
  ActorSubclass,
  HttpAgentOptions,
} from '@dfinity/agent'
import { AuthClient } from '@dfinity/auth-client'

import { idlFactory, canisterId } from '../types/backend/dsocial'
import { DSocial } from '../types/backend/dsocial/dsocial.did'
import { Feedback } from '../types/backend/feedback/feedback.did'
import { idlFactory as FeedbackIdlFactory } from '../types/backend/feedback'

import {
  BACKEND_CANISTER_ID,
  FEEDBACK_BACKEND_CANISTER_ID,
  IC_HOST,
} from '../config'

let agentOptions: HttpAgentOptions = { host: IC_HOST }

export async function getBackendActor(
  authClient: AuthClient | null,
): Promise<ActorSubclass<DSocial>> {
  if (authClient instanceof AuthClient) {
    const identity = authClient.getIdentity()
    agentOptions = { ...agentOptions, identity: identity as any }
  }
  const agent = new HttpAgent(agentOptions)
  // for local development only, this must not be used for production
  if (process.env.NODE_ENV === 'development') {
    await agent.fetchRootKey()
  }

  console.log('BACKEND_CANISTER_ID', BACKEND_CANISTER_ID)

  const backend = Actor.createActor<DSocial>(idlFactory, {
    agent,
    canisterId: BACKEND_CANISTER_ID!,
  })

  return backend
}

export async function getFeedbackBackendActor(
  authClient: AuthClient | null,
): Promise<ActorSubclass<Feedback>> {
  if (authClient instanceof AuthClient) {
    const identity = authClient.getIdentity()
    agentOptions = { ...agentOptions, identity: identity as any }
  }
  const agent = new HttpAgent(agentOptions)
  // for local development only, this must not be used for production
  if (process.env.NODE_ENV === 'development') {
    await agent.fetchRootKey()
  }

  const backend = Actor.createActor<Feedback>(FeedbackIdlFactory, {
    agent,
    canisterId: FEEDBACK_BACKEND_CANISTER_ID!,
  })

  return backend
}
