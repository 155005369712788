import { useEffect, useState } from 'react'
import { LazyImage } from 'react-lazy-images'
import { Link } from 'react-router-dom'
import pages, { generateUrl } from '..'

import { ReactComponent as Verified } from '../../assets/verified.svg'
import { CachedImage, Page } from '../../components'
import { getBackend } from '../../lib/icp'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import { UserAndVerifiedAccountStatus } from '../../types/backend/dsocial/dsocial.did'

import styles from './ThankYou.module.scss'

const ThankYou = () => {
  const [users, setUsers] = useState<UserAndVerifiedAccountStatus[]>([])

  const getVerifiedUsers = async () => {
    const backend = await getBackend()
    const result = await backend.getVerifiedUsers()
    let list: UserAndVerifiedAccountStatus[] =
      result && result.length > 0 && result[0] ? result[0] : []

    list.sort((a, b) => Number(a.status.when) - Number(b.status.when))

    // exclude dsocial
    list = list.filter(({ user: { id } }) => id !== '2')

    setUsers(list)
  }

  useEffect(() => {
    getVerifiedUsers()
  }, [])

  return (
    <Page title="🙏 Thank You">
      <h1>🙏 Thank You to 1,000 Supporters</h1>

      <p>
        DSocial is a self-funded project. This is a thank you to the first 1,000
        channels that got verified on DSocial.
      </p>

      <div className={styles.users}>
        {users.map(({ user, status: { when } }, i) => (
          <Link
            key={user.id}
            to={generateUrl(pages.channel.path, {
              userName: user.userName,
            })}
            className={styles.user}
          >
            <LazyImage
              src={generateProfilePhotoUrl(user.profilePhoto)}
              alt={user.displayName || user.userName}
              placeholder={({ ref }) => (
                <div ref={ref} className={styles.profilePlaceholder} />
              )}
              actual={({ imageProps }) => <CachedImage {...imageProps} />}
            />
            <strong>
              {user?.displayName || user.userName}{' '}
              <Verified className={styles.verified} />
            </strong>
            <p>
              #{i + 1}, {new Date(Number(when) / 1000000).toLocaleDateString()}
            </p>
          </Link>
        ))}
      </div>
    </Page>
  )
}

export default ThankYou
