import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import pages from '../../pages'

import { Loading } from '../../components'
import { AR_ENABLED } from '../../config'
import { useTranslation } from '../../hooks'
import NotFound from '../404'

const supportedOperatingSystems: { [key: string]: string } = {
  mac: 'https://firebasestorage.googleapis.com/v0/b/ilecstdybtaosa.appspot.com/o/builds%2FDSocial%20Video%20Uploader-1.0.0.dmg?alt=media&token=6aec8a2d-2f1f-4039-b771-32197e3bc961',
}

const Download = () => {
  const history = useHistory()
  const { os } = useParams<{ os: string }>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!AR_ENABLED) {
      history.replace(pages.upload.path)
    } else if (os) {
      const clean = os.trim().toLowerCase()
      const dl = supportedOperatingSystems[clean]

      if (dl) {
        window.location.href = dl
        setTimeout(() => history.goBack(), 10000)
      } else {
        setLoading(false)
      }
    }
  }, [os])

  if (loading) return <Loading />

  return <NotFound />
}

export default Download
