import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Page } from '../../components'
import { getBackend } from '../../lib/icp'

const SetVerifiedStatus = () => {
  const history = useHistory()
  const query = new URLSearchParams(window.location.search)
  const userName = query.get('userName') || ''
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    setVerifiedStatus()
  }, [userName])

  const setVerifiedStatus = async () => {
    if (userName) {
      setVerified(false)
      const { getUserIdFromUserName, setVerifiedStatusManual } =
        await getBackend()
      const getUserIdFromUserNameResult = await getUserIdFromUserName(userName)
      const channelId =
        getUserIdFromUserNameResult?.length > 0 &&
        getUserIdFromUserNameResult[0]
          ? getUserIdFromUserNameResult[0]
          : null

      console.log(channelId)

      if (channelId) {
        const result = await setVerifiedStatusManual(channelId, 'manual')
        console.log(result)
        setVerified(true)
        history.replace(`/@${userName}`)
      }
    }
  }

  return (
    <Page title="Delete">
      <h1>Successful Payment</h1>

      {verified ? (
        <p>verified!</p>
      ) : (
        <p>Please wait while we verify your account...</p>
      )}
    </Page>
  )
}

export default SetVerifiedStatus
