import { ChangeEvent, ReactNode, useCallback, useState, useEffect } from 'react'
import { SkynetClient } from 'skynet-js'
import Arweave from 'arweave'
import slugify from 'slugify'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { IoCloudDownloadOutline, IoTrendingUpOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone'
import { JWKInterface } from 'arweave/node/lib/wallet'
import { useHistory } from 'react-router'
import { create } from 'ipfs-http-client'

import pages, { generateUrl } from '../../pages'
import { Button, Page, ErrorBox, CachedImage } from '../../components'
import { getBackend } from '../../lib/icp'
import { withAuthCheck } from '../../hoc'
import {
  USE_WALLET,
  AR_WEAVE_CONFIG,
  IPFS_CONFIG,
  AR_ENABLED,
  CLOUD_UPLOAD,
  CLOUD_TRANSCODING,
} from '../../config'
import { getCroppedImg } from '../../lib/canvas'
import Cropper from 'react-easy-crop'
import { Asset } from '../../types/backend/dsocial/dsocial.did'
import { HLS } from '../../config/assetCategories'
import { useTranslation, useUser } from '../../hooks'

import styles from './Upload.module.scss'
import { TFunction, Trans } from 'react-i18next'
import { uploadToCloud } from '../../lib/upload'
import { on } from '../../lib/db'
import { TranscodingProgress } from '../../types'

const arweave = Arweave.init(AR_WEAVE_CONFIG)

const ipfs = create(IPFS_CONFIG)

const skynetUpload = async (
  file: File,
  onUploadProgress?: (progress: number) => void,
): Promise<string | null> => {
  let i = 0
  const max = 3

  const client = new SkynetClient('https://siasky.net', {
    onUploadProgress,
  })

  for (; i < max; i += 1) {
    try {
      // Upload user's file and get backs descriptor for our Skyfile
      const { skylink } = await client.uploadFile(file)

      // skylinks start with `sia://` and don't specify a portal URL
      // we can generate URLs for our current portal though.
      const skylinkUrl = await client.getSkylinkUrl(skylink)

      console.log('File Uploaded:', skylinkUrl)
      return skylinkUrl
    } catch (e) {
      console.log('failed to upload, trying again...', i + 1)
      await new Promise((resolve) => setTimeout(resolve, 500 * (i + 1)))
    }
  }

  return null
}

const ipfsUpload = async (
  file: File,
  contentType: string,
  onUploadProgress?: (progress: number) => void,
  blob?: Blob,
): Promise<string | null> => {
  let i = 0
  const max = 3

  let newBlob: Blob | undefined = blob

  if (!newBlob) {
    const ar = await getArrayBuffer(file)

    if (!ar) return null

    newBlob = new Blob([ar], { type: contentType })
  }

  for (; i < max; i += 1) {
    try {
      const result = await ipfs.add(newBlob, {
        progress: (bytes: number) => {
          onUploadProgress &&
            onUploadProgress(Math.ceil((bytes / file.size) * 100))
        },
        pin: true,
      })
      return result.path
    } catch (e) {
      console.log('failed to upload, trying again...', i + 1, e)
      await new Promise((resolve) => setTimeout(resolve, 500 * (i + 1)))

      // last try
      if (i === max - 1) {
        Sentry.captureException(e)
      }
    }
  }

  return null
}

const upload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number, status?: string) => void,
  blob?: Blob,
  fileName?: string,
) => {
  if (CLOUD_UPLOAD && fileName) {
    return uploadToCloud(fileName!, file, onUploadProgress, blob)
  }

  return AR_ENABLED
    ? arweaveUpload(file, contentType, onUploadProgress)
    : ipfsUpload(file, contentType, onUploadProgress, blob)
}

const arweaveUpload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number, status?: string) => void,
): Promise<string | null> => {
  try {
    const key = USE_WALLET ? 'use_wallet' : await arweave.wallets.generate()
    const thumbBuffer = await getArrayBuffer(file)

    if (!thumbBuffer) {
      console.error('thumbBuffer is null')
      return ''
    }

    const transaction = await getTransaction(thumbBuffer!, contentType, key)
    const mpdFee = await arweave.ar.winstonToAr(transaction.reward)

    console.log('mpdFee', mpdFee)
    console.log('in $', Number(mpdFee) * 54.32)

    console.log('before sign')
    await arweave.transactions.sign(transaction, key)
    console.log('after sign', transaction)

    let uploader = await arweave.transactions.getUploader(transaction)

    while (!uploader.isComplete) {
      await uploader.uploadChunk()
      console.log(
        `${uploader.pctComplete}% complete, ${uploader.uploadedChunks}/${uploader.totalChunks}`,
      )
      onUploadProgress(uploader.pctComplete)
    }

    return transaction.id
  } catch (e) {
    console.error(e)
    return null
  }
}

const dsocialUpload = async (
  file: File,
  contentType: string,
  onUploadProgress: (progress: number) => void,
): Promise<string | null> => {
  try {
    const data = new FormData()
    data.append('body', file)

    await axios.post('http://localhost:8999/upload', data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    return ''
  } catch (e) {
    console.error(e)
    return null
  }
}

const getArrayBuffer = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new window.FileReader()

    reader.onloadend = () => {
      resolve(reader.result)
    }

    reader.readAsArrayBuffer(file)
  })

const getTransaction = async (
  file: string | ArrayBuffer,
  contentType: string,
  key?: JWKInterface | 'use_wallet',
) => {
  // @ts-ignore
  console.log('getTransaction start', file.byteLength, new Date().toISOString())
  const transaction = await arweave.createTransaction({ data: file }, key)
  console.log('after createTransaction', new Date().toISOString())
  transaction.addTag('Content-Type', contentType)

  return transaction
}

const FILE_SIZE_LIMIT = 10 * 1024 * 1024 * 1024

interface DropperProps {
  onDrop: (file: File) => void
  onError: (error: string) => void
  dropLabel: ReactNode | string
  fileTypes: string[]
  invalidFileTypeError: string
}

const Dropper = ({
  onDrop,
  onError,
  dropLabel,
  fileTypes,
  invalidFileTypeError,
}: DropperProps) => {
  const { t } = useTranslation()
  const onDropInternal = useCallback(
    (acceptedFiles: File[], _: FileRejection[], _2: DropEvent) => {
      onError('')

      if (!acceptedFiles || acceptedFiles.length === 0) return

      const file = acceptedFiles[0]

      if (
        fileTypes.filter((fileType) => fileType.indexOf(file.type) !== -1)
          .length === 0
      ) {
        onError(invalidFileTypeError)
        return
      }

      onDrop(file)
    },
    [],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropInternal,
  })

  return (
    <div
      {...getRootProps()}
      className={`${styles.dropper} ${isDragActive ? styles.active : ''}`}
    >
      <input {...getInputProps()} />

      <IoCloudDownloadOutline />

      <p>{isDragActive ? t('uploadDropHere') : dropLabel}</p>
    </div>
  )
}

interface Progress {
  uploadProgress?: number
  uploaded?: boolean
  transcodingProgress?: number
  transcoded?: boolean
  uploadARWeaveProgress?: number
  uploadedToARWeave?: boolean
}

interface UploadingProgress {
  thumbnail?: Progress
  video?: Progress
  record?: Progress
}

interface TranscodeComplete {
  transactionId: string
  videoLength?: number
  icUrl?: string
}

const InfoBox = ({ children }: { children: ReactNode }) => (
  <div className={styles.infoBox}>
    <p>{children}</p>
  </div>
)

const fileExtension = (fileName: string) => fileName.split('.').pop()

const processFile = (
  t: TFunction<'translation', undefined>,
  fileName: string,
  setProgress: (newProgress: Progress) => void,
  originalProgress: Progress | null | undefined,
) =>
  new Promise<TranscodeComplete | null | undefined>(async (resolve, reject) => {
    let progressObj: Progress = {
      ...(originalProgress || {}),
    }

    try {
      await axios.post(
        'https://us-central1-ilecstdybtaosa.cloudfunctions.net/queueTranscoding',
        {
          name: fileName,
        },
      )

      const id = fileName.replace(/\.[^/.]+$/, '')

      const unsubscribe = on<TranscodingProgress>('progress', id, (status) => {
        if (status) {
          const {
            progress,
            failed,
            completed,
            uploadedFailed,
            uploaded,
            transactionId,
            videoLength,
            icUrl,
          } = status

          if (transactionId) {
            setProgress({
              ...progressObj,
              uploadedToARWeave: true,
              uploadARWeaveProgress: uploaded,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            resolve({ icUrl, transactionId, videoLength })
            unsubscribe()
            return
          }

          if (uploaded) {
            setProgress({
              ...progressObj,
              uploadedToARWeave: uploaded === 100,
              uploadARWeaveProgress: uploaded,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            return
          }

          if (uploadedFailed) {
            unsubscribe()
            reject(new Error(t('uploadErrorUploadToArWeaveFailed')))
            return
          }

          if (completed) {
            setProgress({
              ...progressObj,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: 100,
              transcoded: true,
            })
            return
          }

          if (failed) {
            unsubscribe()
            reject(new Error(t('uploadErrorTranscodingFailed')))
            return
          }

          if (progress) {
            setProgress({
              ...progressObj,
              uploadProgress: 100,
              uploaded: true,
              transcodingProgress: progress,
              transcoded: false,
            })
            return
          }
        }
      })
    } catch (e) {
      reject(new Error(t('uploadErrorFailedUpload')))
    }
  })

const Upload = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { loading, loggedIn, userData } = useUser()
  const [videoFile, setVideoFile] = useState<File | null>()
  const [thumbnailBuffer, setThumbnailBuffer] = useState<File | null>()
  const [uploading, setUploading] = useState<
    UploadingProgress | null | undefined
  >(null)
  const [invalidThumbnail, setInvalidThumbnail] = useState(false)
  const [invalidVideo, setInvalidVideo] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState('')
  const [progress, setProgress] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imageToCrop, setImageToCrop] = useState<string | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [pixels, setPixels] = useState(null)
  const [loadingChoosePhoto, setLoadingChoosePhoto] = useState(false)
  const [resizingPhoto, setResizingPhoto] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const [noArWallet, setNoArWallet] = useState(false)

  useEffect(() => {
    if (!loading && loggedIn && (!userData || !userData.userName)) {
      history.push(pages.account.path)
    }
  }, [loading, loggedIn, userData?.userName])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setPixels(croppedAreaPixels)
  }, [])

  const captureThumbnail = (file: File) => {
    setImageToCrop(null)
    setPixels(null)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    // event.preventDefault()
    setInvalidThumbnail(false)
    setError('')

    // if (!event?.target?.files || !event?.target?.files?.length) return

    // const file = event.target.files[0]

    setThumbnailBuffer(file)

    setError('')

    setLoadingChoosePhoto(true)
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setImageToCrop(reader.result as string)
        setLoadingChoosePhoto(false)
      },
      false,
    )
    reader.readAsDataURL(file)
  }

  const failedResize = () => {
    setResizingPhoto(false)
    setError(t('accountFailedResize'))
  }

  const showResult = async () => {
    if (!imageToCrop) return

    setResizingPhoto(true)

    try {
      let newCroppedImage = await getCroppedImg(imageToCrop!, pixels)

      // Resize the image
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const maxSize = 1280
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height
            height = maxSize
          }
        }
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')

        if (!ctx) {
          failedResize()
          return
        }

        ctx.drawImage(image, 0, 0, width, height)
        newCroppedImage = canvas.toDataURL('image/jpeg')

        console.log('done', { croppedImage })
        setCroppedImage(newCroppedImage)
        setResizingPhoto(false)
        setImageToCrop(null)
      }
      image.onerror = failedResize
      image.src = newCroppedImage
    } catch (e) {
      console.error(e)
      setResizingPhoto(false)
    }
  }

  const cancelEdit = () => {
    setImageToCrop(null)
    setCroppedImage(null)
    setPixels(null)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
  }

  const captureFile = (file: File) => {
    // event.preventDefault()
    setInvalidVideo(false)
    setError('')

    // if (!event?.target?.files || !event?.target?.files?.length) return

    // const file = event.target.files[0]
    // if (file.size > FILE_SIZE_LIMIT) {
    //   setError(t('uploadFileTooBig'))
    //   return
    // }
    setVideoFile(file)
    // const reader = new window.FileReader()
    // reader.readAsArrayBuffer(file)

    // reader.onloadend = () => {
    //   // @ts-ignore
    //   setBuffer(new Buffer(reader.result))
    //   console.log('thumbnail loaded')
    // }
  }

  const onChangeTransactionId = (e: ChangeEvent<HTMLInputElement>) => {
    setTransactionId(e.target.value)
    setError(t('uploadFailedResize'))
  }

  const uploadVideo = async () => {
    try {
      setError('')
      if (!thumbnailBuffer || invalidThumbnail || !croppedImage) {
        setError(t('uploadErrorSelectThumbnail'))
        return
      }

      if (AR_ENABLED) {
        if (!transactionId) {
          setError(t('uploadErrorTransactionId'))
          return
        }

        if (USE_WALLET) {
          let bal = 0

          try {
            const address = await arweave.wallets.jwkToAddress('use_wallet')
            const winston = await arweave.wallets.getBalance(address)
            bal = Number((await arweave.ar.winstonToAr(winston)) || '0')
          } catch (e) {
            // do nothing
          }

          if (!bal) {
            setNoArWallet(true)
            return
          }
        }
      } else {
        if (!videoFile || invalidVideo) {
          setError(t('uploadErrorVideoFile'))
          return
        }
      }

      setUploading({
        thumbnail: {
          uploadProgress: 0,
        },
      })

      const start = Date.now()

      const imageId = uuid()
      const imageFileName = `${imageId}.${fileExtension(thumbnailBuffer!.name)}`
      const blob = await (await fetch(croppedImage)).blob()
      let thumbPath = await upload(
        thumbnailBuffer!,
        'image/jpeg',
        (progress) => {
          setUploading({
            ...uploading,
            thumbnail: {
              uploadProgress: progress,
            },
          })
        },
        blob,
        imageFileName,
      )
      let thumbIcPath: string | undefined | null = ''

      if (!thumbPath) {
        setError(t('uploadErrorFailedUpload'))
        setUploading(null)
        return
      }


      const assets: Asset[] = []

      try {
        const imageTransaction = await processFile(
          t,
          imageFileName,
          (thumbnail) => {
            setUploading({
              ...uploading,
              thumbnail,
              video: {
                uploadProgress: 0,
              },
            })
          },
          {
            uploadProgress: 100,
            uploaded: true,
          },
        )
        // alert('imageTransactionId' + imageTransactionId)
        if (imageTransaction?.transactionId) {
          thumbPath = imageTransaction?.transactionId
        }
        thumbIcPath = imageTransaction?.icUrl
        console.log(imageTransaction)

        if (thumbIcPath) {
          assets.push({
            category: 'ict',
            url: thumbIcPath!,
          })
        }
      } catch (e: any) {
        setError(e.message)
        setUploading(null)
        return
      }

      const thumbnail = {
        uploadProgress: 100,
        uploaded: true,
        uploadARWeaveProgress: 100,
        uploadedToARWeave: true,
      }

      setUploading({
        ...uploading,
        thumbnail,
        video: {
          uploadProgress: 0,
        },
      })

      let videoPath: string | null = ''

      if (!AR_ENABLED) {
        const videoId = uuid()
        const videoFileName = `${videoId}.${fileExtension(videoFile!.name)}`

        videoPath = await upload(
          videoFile!,
          'video/mp4',
          (progress) => {
            setUploading({
              ...uploading,
              thumbnail,
              video: {
                uploadProgress: progress,
              },
            })
          },
          undefined,
          videoFileName,
        )

        if (!videoPath) {
          setError(t('uploadErrorVideoFailedUpload'))
          setUploading(null)
          return
        }
        console.log('Arweave result', videoPath, Date.now() - start)

        if (CLOUD_TRANSCODING) {
          try {
            const videoTransaction = await processFile(
              t,
              videoFileName,
              (video) => {
                setUploading({
                  ...uploading,
                  thumbnail,
                  video,
                })
              },
              {
                uploadProgress: 100,
                uploaded: true,
              },
            )
            if (videoTransaction?.transactionId) {
              videoPath = 'ar'
              assets.push({
                category: HLS,
                url: videoTransaction?.transactionId,
              })

              if (videoTransaction?.videoLength) {
                assets.push({
                  category: 'length',
                  url: videoTransaction?.videoLength!.toString(),
                })
              }
            }
            if (videoTransaction?.icUrl) {
              assets.push({
                category: 'icv',
                url: videoTransaction?.icUrl,
              })
            }
          } catch (e: any) {
            setError(e.message)
            setUploading(null)
            return
          }
        }
      } else {
        videoPath = 'ar'
        assets.push({
          category: HLS,
          url: transactionId,
        })
      }

      const video: Progress = {
        uploadProgress: 100,
        uploaded: true,
        uploadARWeaveProgress: 100,
        uploadedToARWeave: true,
        transcoded: true,
        transcodingProgress: 100,
      }

      setUploading({
        ...uploading,
        thumbnail,
        video,
        record: {
          uploaded: true,
        },
      })

      const backend = await getBackend()
      const uploadResult = await backend.uploadVideo(
        videoPath,
        thumbPath,
        title,
        description,
        assets,
      )

      setUploading({
        ...uploading,
        thumbnail,
        video,
        record: {
          uploadProgress: 100,
          uploaded: true,
        },
      })

      history.push(
        generateUrl(pages.video.path, {
          videoId: uploadResult.newVideoId,
          videoSlug: slugify(title, { lower: true, strict: true }),
        }),
      )
    } catch (e) {
      setError(t('uploadErrorVideoFailedUpload'))
      setUploading(null)
      Sentry.captureException(e)
    }
  }

  return (
    <Page title={`${t('uploadPageTitle')} | DSocial`}>
      <h2>{t('uploadPageTitle')}</h2>

      {noArWallet && (
        <ErrorBox>
          <Trans i18nKey="uploadInstallArConnect">
            Please install ArConnect Extension first. Follow the{' '}
            <Link to={pages.gettingStarted.path} target="_blank">
              Getting Started
            </Link>{' '}
            guide if you haven't already.
          </Trans>
        </ErrorBox>
      )}

      {error ? (
        <ErrorBox>{error}</ErrorBox>
      ) : (
        <>
          {AR_ENABLED && (
            <InfoBox>
              <Trans i18nKey="uploadFirstTime">
                If this is the first time you've uploaded to DSocial, read this{' '}
                <Link to={pages.gettingStarted.path} target="_blank">
                  Getting Started
                </Link>{' '}
                guide first.
              </Trans>
            </InfoBox>
          )}
        </>
      )}

      {uploading ? (
        <div className={styles.progress}>
          <ul>
            {uploading.thumbnail && (
              <>
                <li>
                  {uploading.thumbnail.uploaded
                    ? `✅ ${t('uploadThumbnailUploaded')}`
                    : `⬆️ ${t('uploadThumbnailUploading', {
                        progress: uploading.thumbnail.uploadProgress || 0,
                      })}`}
                </li>
                <>
                  {uploading.thumbnail.uploaded && CLOUD_TRANSCODING && (
                    <>
                      <li>
                        {uploading.thumbnail.uploadedToARWeave
                          ? `✅ ${t('uploadThumbnailUploadedToARWeave')}`
                          : `⬆️ ${t('uploadThumbnailUploadingToARWeave', {
                              progress:
                                uploading.thumbnail.uploadARWeaveProgress || 0,
                            })}`}
                      </li>
                    </>
                  )}
                </>
              </>
            )}
            {uploading.video && (
              <>
                <li>
                  {uploading.video.uploaded
                    ? `✅ ${t('uploadVideoUploaded')}`
                    : `⬆️ ${t('uploadVideoUploading', {
                        progress: uploading.video.uploadProgress || 0,
                      })}`}
                </li>
                <>
                  {uploading.video.uploaded && CLOUD_TRANSCODING && (
                    <>
                      <li>
                        {uploading.video.transcoded
                          ? `✅ ${t('uploadVideoTranscoded')}`
                          : `⬆️ ${t('uploadVideoTranscoding', {
                              progress:
                                uploading.video.transcodingProgress || 0,
                            })}`}
                      </li>
                      {uploading.video.transcoded && (
                        <li>
                          {uploading.video.uploadedToARWeave
                            ? `✅ ${t('uploadVideoUploadedToARWeave')}`
                            : `⬆️ ${t('uploadVideoUploadingToARWeave', {
                                progress:
                                  uploading.video.uploadARWeaveProgress || 0,
                              })}`}
                        </li>
                      )}
                    </>
                  )}
                </>
              </>
            )}
            {uploading.record && (
              <li>
                {uploading.record.uploaded
                  ? `✅ ${t('uploadVideoCreated')}`
                  : `⬆️ ${t('uploadVideoCreating')}`}
              </li>
            )}
          </ul>
        </div>
      ) : (
        <form
          className={styles.uploadForm}
          onSubmit={(event) => {
            event.preventDefault()
            uploadVideo()
          }}
        >
          <label htmlFor="thumb">
            {!error && !invalidThumbnail && thumbnailBuffer ? (
              <>✅&nbsp;</>
            ) : (
              <>1)&nbsp;</>
            )}
            {t('uploadSelectThumbnail')}
            {imageToCrop ? (
              <div className={styles.cropper}>
                <div className={styles.cropperContainer}>
                  <Cropper
                    image={imageToCrop}
                    crop={crop}
                    zoom={zoom}
                    aspect={1280 / 720}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                  />
                </div>
                <div className={styles.cropperButtons}>
                  <Button
                    onClick={showResult}
                    loading={resizingPhoto}
                    small
                    secondary
                  >
                    {resizingPhoto ? t('uploadResizingPhoto') : t('uploadDone')}
                  </Button>
                  <Button
                    onClick={cancelEdit}
                    disabled={resizingPhoto}
                    small
                    cancel
                  >
                    {t('uploadCancel')}
                  </Button>
                </div>
              </div>
            ) : (
              <Dropper
                onDrop={captureThumbnail}
                fileTypes={[
                  'image/jpeg .jpeg',
                  'image/jpeg .jpg',
                  'image/png .png',
                ]}
                dropLabel={
                  thumbnailBuffer?.name ? (
                    <div className={styles.picked}>
                      {croppedImage ? (
                        <CachedImage
                          className={styles.croppedImage}
                          src={croppedImage}
                        />
                      ) : null}
                      <p>{thumbnailBuffer?.name}</p>
                    </div>
                  ) : (
                    t('uploadDropLabel')
                  )
                }
                onError={setError}
                invalidFileTypeError={t('uploadSelectThumbnailError')}
              />
            )}
          </label>

          {!imageToCrop && (
            <>
              <label htmlFor="video">
                {!error && !invalidVideo && (videoFile || transactionId) ? (
                  <>✅&nbsp;</>
                ) : (
                  <>2)&nbsp;</>
                )}
                {AR_ENABLED ? (
                  <Trans i18nKey="uploadYourVideoWith">
                    Upload your video with{' '}
                    <Link to={pages.uploaderApp.path} target="_blank">
                      DSocial Uploader App
                    </Link>
                    , once uploaded, copy the video ID and paste below.
                  </Trans>
                ) : (
                  t('uploadSelectVideo')
                )}

                {AR_ENABLED ? (
                  <>
                    <input
                      type="text"
                      value={transactionId}
                      placeholder={t('uploadPlaceholderTransactionId')}
                      onChange={onChangeTransactionId}
                    />
                  </>
                ) : (
                  <Dropper
                    onDrop={captureFile}
                    fileTypes={['video/mp4 .mp4']}
                    dropLabel={videoFile?.name || t('uploadDropVideo')}
                    onError={setError}
                    invalidFileTypeError={t('uploadErrorDropVideo')}
                  />
                )}
              </label>

              <label htmlFor="title">
                {!error && title && title.trim() ? (
                  <>✅&nbsp;</>
                ) : (
                  <>3)&nbsp;</>
                )}
                {t('uploadEnterTitle')}
                <input
                  id="title"
                  type="text"
                  placeholder={t('uploadPlaceholderTitle')}
                  required
                  autoComplete="off"
                  value={title}
                  onChange={(e) => {
                    setError('')
                    setTitle(e.target.value)
                  }}
                />
              </label>

              <label htmlFor="desc">
                {!error && description && description.trim() ? (
                  <>✅&nbsp;</>
                ) : (
                  <>4)&nbsp;</>
                )}
                {t('uploadEnterVideoDesc')}
                <textarea
                  id="desc"
                  placeholder={t('uploadPlaceholderDesc')}
                  value={description}
                  onChange={(e) => {
                    setError('')
                    setDescription(e.target.value)
                  }}
                  rows={4}
                />
              </label>
              <Button type="submit">{t('uploadButton')}</Button>
            </>
          )}
        </form>
      )}
    </Page>
  )
}

export default withAuthCheck(Upload)
