import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Loading } from '../components'
import { logout } from '../lib/icp'

const Logout = () => {
  useEffect(() => {
    logout().then(() => {
      window.location.href = '/'
    })
  }, [])

  return <Loading />
}

export default Logout
