import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import pages, { generateUrl } from '..'

import { CachedImage, Loading, Page } from '../../components'
import { useTranslation } from '../../hooks'
import { getBackend } from '../../lib/icp'
import renderNumbers from '../../lib/numbers'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import { TopChannel } from '../../types/backend/dsocial/dsocial.did'

const Top = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [channels, setChannels] = useState<TopChannel[]>([])

  useEffect(() => {
    getBackend().then(({ getTopChannels }) => {
      getTopChannels().then((topChannels) => {
        console.log('topChannels', topChannels)
        if (topChannels && topChannels.length > 0) {
          let list = topChannels[0] || []

          list.sort(
            (a, b) => Number(b.subscriberCount) - Number(a.subscriberCount),
          )

          if (list.length > 10) {
            list = list.slice(0, 10)
          }

          setChannels(list)
        } else {
          setChannels([])
        }
        setLoading(false)
      })
    })
  }, [])

  return (
    <Page title={t('topTitle')}>
      <h1>{t('topTitle')}</h1>

      {loading && <Loading />}

      {!loading && (
        <table>
          {channels.map(
            (
              {
                user: { profilePhoto, userName, displayName },
                subscriberCount,
              },
              i,
            ) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                  <h2>
                    <CachedImage src={generateProfilePhotoUrl(profilePhoto)} />
                    <Link
                      to={generateUrl(pages.channel.path, {
                        userName,
                      })}
                    >
                      {displayName || userName}
                    </Link>
                  </h2>
                </td>
                <td>{renderNumbers(subscriberCount, 'subscribers', t)}</td>
              </tr>
            ),
          )}
        </table>
      )}
    </Page>
  )
}

export default Top
