import { createContext, ReactNode, useEffect } from 'react'
import { ColorScheme, Theme } from '../../types'
import { useColorScheme } from '../../hooks/useColorScheme'

const initialValue: IThemeContext = {
  theme: Theme.Dark,
}

const BG = '--background'
const TEXT = '--text'
const SHADOW = '--shadow'
const HEADER_BG = '--header-bg'
const OTHER_BG = '--other-bg'
const SUBTLE_TEXT = '--subtle-text'
const SIDE_MENU_SELECTED = '--side-menu-selected'
const SIDE_MENU_HOVER = '--side-menu-hover'
const SIDE_MENU_HOVER_TEXT = '--side-menu-hover-text'

const DARK_THEME = {
  [BG]: '#181818',
  [TEXT]: '#FFFFFF',
  [SHADOW]: 'rgba(255, 255, 255, 0.5)',
  // [HEADER_BG]: 'rgba(33, 33, 33, 0.98)',
  [HEADER_BG]: '#181818',
  [OTHER_BG]: '#292B2F',
  [SUBTLE_TEXT]: '#B8B8B8',
  [SIDE_MENU_SELECTED]: '#292B2F',
  [SIDE_MENU_HOVER]: '#266DD3',
  [SIDE_MENU_HOVER_TEXT]: '#FFFFFF',
}

const LIGHT_THEME = {
  [BG]: '#FFFFFF',
  [TEXT]: '#181818',
  [SHADOW]: 'rgba(0, 0, 0, 0.5)',
  // [HEADER_BG]: 'rgba(255, 255, 255, 0.98)',
  [HEADER_BG]: '#FFFFFF',
  [OTHER_BG]: '#F7F9FD',
  [SUBTLE_TEXT]: '#98AFB1',
  [SIDE_MENU_SELECTED]: '#F7F9FD',
  [SIDE_MENU_HOVER]: '#266DD3',
  [SIDE_MENU_HOVER_TEXT]: '#FFFFFF',
}

export const ThemeContext = createContext<IThemeContext>(initialValue)

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const colorScheme = useColorScheme()
  const isDark = colorScheme === ColorScheme.Dark
  const theme = isDark ? Theme.Dark : Theme.Light

  useEffect(() => {
    const newCssVariables = theme === Theme.Dark ? DARK_THEME : LIGHT_THEME

    if (window.cssVars) {
      window.cssVars({
        rootElement: document,
        variables: newCssVariables,
      })
    }

    let style = document.getElementById('theme')

    if (!style) {
      style = document.createElement('style')
      style.id = 'theme'
      document.head.appendChild(style)
    }

    const variables = Object.entries(newCssVariables)
      .map(([key, value]) => `${key}:${value}`)
      .join(';')

    style.innerHTML = `:root { ${variables} }`

    return () => {
      if (style) {
        style.remove()
      }
    }
  }, [theme])

  const value: IThemeContext = {
    theme,
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

interface ThemeProviderProps {
  children: ReactNode
}

interface IThemeContext {
  theme: Theme
}
