import React, { useEffect } from 'react'
import Hls from 'hls.js'
import useMobileDetect from 'use-mobile-detect-hook'

interface HlsSourceProps {
  src: string
  video?: HTMLVideoElement
  autoPlay?: boolean
}

const HlsSource = ({ src, video, autoPlay }: HlsSourceProps) => {
  const detectMobile = useMobileDetect()
  useEffect(() => {
    const hls = new Hls({
      xhrSetup: (xhr, url) => {
        const parts = url.split('/')
        const txId = parts[parts.length - 1]
        const splitter = 'arweave.net/'

        if (url.indexOf(splitter) !== -1 && parts.length >= 5) {
          const baseUrl = `${url.split(splitter)[0]}${splitter}`
          const newUrl = `${baseUrl}${txId}`
          console.log('url', url)
          console.log('newUrl', newUrl)
          xhr.open('GET', newUrl, true)
        }
      },
    })

    if (Hls.isSupported() && video) {
      hls.loadSource(src)
      hls.attachMedia(video!)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (!detectMobile.isMobile() && autoPlay) {
          video!.play()
        }
      })
    }

    return () => hls.destroy()
  }, [])

  return (
    <source
      // @ts-ignore
      isVideoChild
      src={src}
      type="application/x-mpegURL"
    />
  )
}

export default HlsSource
