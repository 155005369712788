import Page from '../../components/Page'

const Privacy = () => (
  <Page title="Privacy Policy | DSocial">
    <h1>Privacy Policy</h1>

    <p>
      DSocial considers your privacy to be important. We will therefore process
      and use your data in a secure manner in accordance with the General Data
      Protection Regulation. In this privacy statement, we will explain what
      information will be collected and why. It also states your rights with
      regard to the processing of your personal data. Please contact
      <a href="mailto:hello@dsocial.app">hello@dsocial.app</a> if you have any
      questions about your privacy or this statement.
    </p>

    <h2>Article 1 Who are we?</h2>

    <p>
      Rudland Capital Ltd is a limited company, established United Kingdom.
      Company number 12498768, shall in this privacy policy be referred to as
      DSocial. We are the controller of your personal data.
    </p>

    <h2>Article 2 Which data do we use?</h2>

    <p>
      Here you will find an overview of data that we process, why we do this,
      what legal basis we have to process this data and how long we will store
      this data.
    </p>

    <p>
      We process the username, channel name, likes, video metadata and comments
      to provide the service.
    </p>

    <p>
      For financial administration purposes, we process your name, email and
      credit card details. For credit card payments, we are not able to process
      your payment without this information. We will delete this information as
      soon as you delete your account. If desired, we can send you an invoice.
    </p>

    <p>
      In the unlikely event that you have a complaint about our services, we
      will process your name, email and the content of and communication
      regarding this complaint in order to find the best possible solution for
      you. We use this information for an optimal execution of the agreement. We
      will erase data regarding complaints after handling the complaint.
    </p>

    <p>
      Upon registration, we will process your username, channel name and other
      data that are important. This data is collected via a registration form.
    </p>

    <h2>Article 3 How do we obtain this information?</h2>

    <p>
      We have obtained the above information from you as upon signup, because
      you have provided us with this information.
    </p>

    <h2>Article 4 What rights do you have with regard to this data?</h2>

    <p>
      The General Data Protection Regulation has given you a number of rights
      with regard to personal data that is processed by us.
    </p>

    <ol>
      <li>Access - You can request to view your data at any time.</li>
      <li>
        Correct - If you want to have your data adjusted, corrected,
        supplemented, protected or erased, you can submit a request hereto. You
        can also correct certain data in your personal account.
      </li>
      <li>Object - You can object to the processing of your data.</li>
      <li>
        Data transfer - If you want to transfer your data to another provider,
        we will provide your data in a structured and commonly used form that
        can be accessed by common digital systems.
      </li>
      <li>
        Automated processing - You may always inform us of your view on an
        automated decision and have this decision reconsidered by a third
        person.
      </li>
      <li>
        Withdrawal - When we process data based on your explicit consent, you
        have the right to withdraw your consent. This may have consequences for
        the services we are able to provide.
      </li>
    </ol>

    <p>
      Please send a request to
      <a href="mailto:hello@dsocial.app">hello@dsocial.app</a>
      if you want to exercise any of the abovementioned rights. We will assess
      your request as soon as possible. If we cannot comply with your request,
      we will let you know why we reject your request.
    </p>

    <h2>Article 5 Who receives your data?</h2>

    <p>
      We will not provide your data to third parties. Your data will always
      remain yours. We will never sell your data to third parties.
    </p>

    <h2>Article 6 Automated decision-making</h2>

    <p>
      We may use automated decision-making based on information, actions or
      omissions provided by you. It is therefore possible that you are no longer
      able to view your data when a payment term of an invoice has expired or
      when you are transferred to a higher plan.
    </p>

    <h2>Article 7 Final provisions</h2>

    <p>
      We recommend that you regularly consult this privacy statement, as we may
      make changes to the policy. We will notify you of any changes. Please send
      an email to
      <a href="mailto:hello@dsocial.app">hello@dsocial.app</a>
      if you have any questions about this privacy statement or the way we use
      your data. Please also inform us if you have a complaint about the way we
      handle your data.
    </p>
  </Page>
)

export default Privacy
