import { ASSET_BASE_URL, AR_WEAVE_HOST, CS_CDN, AR_CDN } from '../config'
import { Video } from '../types/backend/dsocial/dsocial.did'

const generateThumbnailUrl = (video: Video) => {
  if (video.thumb && video.thumb.startsWith('http')) {
    return video.thumb.replace(CS_CDN.before, CS_CDN.after)
  }

  // if (video.assets.length > 2) {
  //   const thumb = video.assets.find(r => r.category === 'ict')
  //   if (thumb) {
  //     return thumb.url.replace('5xecn-dyaaa-aaaal-qbdcq-cai.raw.ic0.app', 'dsocialic.b-cdn.net')
  //   }
  // }

  if (video.hash === 'ar') {
    return `${AR_WEAVE_HOST.replace(AR_CDN.before, AR_CDN.after)}${video.thumb}`
  }

  return `${ASSET_BASE_URL}${video.thumb}`
}

export default generateThumbnailUrl
