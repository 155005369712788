import { useEffect, useState } from 'react'
import { LazyImage } from 'react-lazy-images'
import { Link, useHistory } from 'react-router-dom'
import slugify from 'slugify'
import { StringParam, useQueryParam } from 'use-query-params'
import pages, { generateUrl } from '..'
import { useTranslation } from '../../hooks'

import { CachedImage, Loading, Page } from '../../components'
import { ReactComponent as Verified } from '../../assets/verified.svg'
import generateThumbnailUrl from '../../lib/generateThumbnailUrl'
import { getBackend, getClientId } from '../../lib/icp'
import { VideoAndUser } from '../../types/backend/dsocial/dsocial.did'
import renderNumbers from '../../lib/numbers'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import ago from '../../lib/ago'

import styles from './Search.module.scss'
import convertDuration from '../../lib/duration'
import InfiniteScroll from 'react-infinite-scroll-component'

const Search = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [query, _] = useQueryParam('q', StringParam)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [results, setResults] = useState<VideoAndUser[]>([])
  const [time, setTime] = useState(0)
  const cleanQuery = query ? query.trim() : ''

  const search = async (
    append: boolean = false,
    offset: number = 0,
    maxResults: number = 12,
  ) => {
    if (!cleanQuery) {
      history.replace('/')
      return
    }

    setLoadingMore(append)
    setHasMore(false)
    const backend = await getBackend()
    const start = Date.now()
    const rawResults = await backend.search(
      cleanQuery,
      BigInt(offset),
      BigInt(maxResults),
    )
    const newResults: VideoAndUser[] =
      rawResults.length > 0 && rawResults[0] ? rawResults[0] : []

    if (newResults.length > 0) {
      if (append) {
        setResults([...results, ...newResults])
      } else {
        setResults(newResults)
      }
      setHasMore(true)
    }

    setTime((Date.now() - start) / 1000)
    setLoading(false)
    setLoadingMore(false)
    if (!append) window.scrollTo({ top: 0 })
  }

  useEffect(() => {
    search()
  }, [cleanQuery])

  return (
    <Page title={`${cleanQuery} | DSocial`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <p className={styles.results}>
            {results.length} videos found ({time.toFixed(1)}s)
          </p>
          <InfiniteScroll
            dataLength={results.length}
            next={() => search(true, results.length)}
            hasMore={hasMore}
            loader={loadingMore && <Loading small />}
          >
            {results.map(({ video, user, verified }) => {
              const { id, title, assets, views, when, desc } = video
              const { displayName, userName, profilePhoto } = user
              const videoLengthRecord =
                assets && assets.find(({ category }) => category === 'length')
              const duration =
                videoLengthRecord &&
                videoLengthRecord.url &&
                Number(videoLengthRecord.url) > 0
                  ? Math.ceil(Number(videoLengthRecord.url))
                  : 0
              return (
                <Link
                  to={generateUrl(pages.video.path, {
                    videoId: id,
                    videoSlug: slugify(title, { lower: true, strict: true }),
                  })}
                  className={styles.item}
                  title={title}
                  key={id}
                >
                  <div className={styles.thumb}>
                    <LazyImage
                      src={generateThumbnailUrl(video)}
                      alt={title}
                      placeholder={({ ref }) => (
                        <div ref={ref} className={styles.placeholder} />
                      )}
                      actual={({ imageProps }) => <img {...imageProps} />}
                    />
                    {duration ? (
                      <span className={styles.duration}>
                        {convertDuration(duration)}
                      </span>
                    ) : null}
                  </div>
                  <div className={styles.info}>
                    <h2>{title}</h2>
                    <p className={styles.viewsAndWhen}>
                      <span className={styles.views}>
                        {renderNumbers(views, 'views', t)}
                      </span>
                      <span className={styles.seperator}>{'\u2B24'}</span>
                      <span className={styles.ago}>{ago(when)}</span>
                    </p>
                    <p>
                      <Link
                        to={generateUrl(pages.channel.path, { userName })}
                        className={styles.channel}
                      >
                        <LazyImage
                          src={generateProfilePhotoUrl(profilePhoto)}
                          alt={displayName || userName}
                          placeholder={({ ref }) => (
                            <div
                              ref={ref}
                              className={styles.profilePlaceholder}
                            />
                          )}
                          actual={({ imageProps }) => (
                            <CachedImage {...imageProps} />
                          )}
                        />
                        {displayName || userName}
                        {verified && <Verified className={styles.verified} />}
                      </Link>
                    </p>
                    <p className={styles.desc}>
                      {desc && desc.length > 100
                        ? desc.substring(0, 100) + '...'
                        : desc}
                    </p>
                  </div>
                </Link>
              )
            })}
          </InfiniteScroll>
        </>
      )}
    </Page>
  )
}

export default Search
