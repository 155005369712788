import { DelegationIdentity } from '@dfinity/identity'
import { useCallback } from 'react'
import { Buffer } from 'buffer'

import { Button } from '../../components'
import { IDENTITY_PROVIDER_URL } from '../../config'
import { getAuthClient, loadAuthClient } from '../../lib/icp'

const {
  REACT_APP_TEST_MOBILE_AUTH_PRINCIPAL: testPrincipal,
  REACT_APP_TEST_MOBILE_AUTH_DELEGATION: testDelegationJson,
  REACT_APP_TEST_MOBILE_AUTH_KEYS: testKeyJson,
} = process?.env || {}
const isTestMode =
  window.location.host === 'localhost:3000' &&
  testPrincipal &&
  testDelegationJson &&
  testKeyJson

const openApp = (
  keyJson: string,
  delegationsJson: string,
  principal: string,
) => {
  console.log('principal', delegationsJson)
  console.log('Ed25519KeyIdentity', keyJson)
  const key64 = Buffer.from(keyJson).toString('base64')
  console.log('delegations', delegationsJson)
  const delegations64 = Buffer.from(delegationsJson).toString('base64')

  const params = new URLSearchParams(window.location.search)
  let appUrl = params.get('appUrl')

  if (!appUrl) {
    console.log('No appUrl param to open')
    return
  }

  appUrl += `?principal=${encodeURIComponent(principal)}`
  appUrl += `&delegations64=${encodeURIComponent(delegations64)}`
  appUrl += `&key64=${encodeURIComponent(key64)}`

  console.log(appUrl)

  if (appUrl) {
    window.location.href = appUrl
  }
}

const MobileAuth = () => {
  const login = useCallback(async () => {
    if (isTestMode) {
      openApp(testKeyJson, testDelegationJson, testPrincipal)
      return
    }

    await loadAuthClient()
    const daysToAdd = 365
    const expiry = Date.now() + daysToAdd * 86400000
    const authClient = getAuthClient()
    await authClient?.login({
      onSuccess: async () => {
        const identity = authClient.getIdentity()
        if (identity instanceof DelegationIdentity) {
          const delegationIdentity = identity as DelegationIdentity
          const principal = identity.getPrincipal()

          // @ts-ignore eslint-disable-next-line no-underscore-dangle
          const keyJson = JSON.stringify(identity._inner.toJSON())
          const delegationsJson = JSON.stringify(
            delegationIdentity.getDelegation().toJSON(),
          )
          openApp(keyJson, delegationsJson, principal.toString())
        }
      },
      identityProvider: IDENTITY_PROVIDER_URL,
      maxTimeToLive: BigInt(expiry * 1000000),
    })
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Button onClick={login}>Continue</Button>
    </div>
  )
}

export default MobileAuth
