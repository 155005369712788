import { ReactNode, useMemo, useState } from 'react'
import classnames from 'classnames'

import { useTranslation } from '../../hooks'

import styles from './Tabs.module.scss'

interface Tab {
  id: string
  display: string
  content: () => ReactNode
  shouldShow?: () => boolean
}

interface TabsProps {
  tabs: Tab[]
  selectedTab: string
  setSelectedTab: (newSelectedTab: string) => void
}

const Tabs = ({ tabs, selectedTab, setSelectedTab }: TabsProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {tabs.map(({ id, display, shouldShow }) =>
          !shouldShow || shouldShow() ? (
            <div
              key={id}
              className={classnames(styles.tab, {
                [styles.selected]: selectedTab === id,
              })}
              onClick={() => setSelectedTab(id)}
            >
              {t(display)}
            </div>
          ) : null,
        )}
        <div className={styles.spacer} />
      </div>
      {tabs.find(({ id }) => id === selectedTab)?.content()}
    </div>
  )
}

export default Tabs
