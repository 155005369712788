import React, { ReactNode, useCallback, useState } from 'react'

import classnames from 'classnames'
import { IoCashOutline, IoMenu } from 'react-icons/io5'
import { IoMdAnalytics } from 'react-icons/io'
import { Link } from 'react-router-dom'
import {
  MdHomeFilled,
  MdOutlineAccountCircle,
  MdOutlineLogin,
  MdOutlineLogout,
  MdOutlinePaid,
  MdOutlineRuleFolder,
  MdOutlineUploadFile,
  MdVideoSettings,
} from 'react-icons/md'

import pages from '../../pages'
import { UserPermission } from '../../types'
import { useUser, useTranslation } from '../../hooks'
import { User } from '../../types/backend/dsocial/dsocial.did'

import Logo from '../Logo'
import pkg from '../../../package.json'

import styles from './SideMenu.module.scss'

type MaybeUser = User | null | undefined
type UrlSetting =
  | undefined
  | string
  | ((userData?: User | null | undefined) => string)

interface MenuItem {
  icon?: ReactNode
  display?: string
  spacer?: boolean
  url?: UrlSetting
  shouldShow?: (
    userData?: MaybeUser,
    userPermissions?: UserPermission[],
  ) => boolean | undefined
  items?: MenuItem[]
}

const MODERATOR_PERMS: UserPermission[] = [
  UserPermission.Owner,
  UserPermission.Admin,
  UserPermission.Moderator,
]

const MENU_ITEMS: MenuItem[] = [
  {
    icon: <MdHomeFilled />,
    display: 'sideMenuHome',
    url: pages.home.path,
  },
  {
    icon: <MdOutlineLogin />,
    display: 'sideMenuLoginOrRegister',
    url: pages.login.path,
    shouldShow: (userData?: MaybeUser) => !userData?.id,
  },
  {
    icon: <MdVideoSettings />,
    display: 'sideMenuMyChannel',
    url: (userData?: MaybeUser) =>
      userData?.userName ? `/@${userData?.userName}` : '',
    shouldShow: (userData?: MaybeUser) => !!userData?.id,
    items: [
      {
        icon: <MdOutlineUploadFile />,
        display: 'sideMenuUpload',
        url: pages.upload.path,
      },
      {
        icon: <IoMdAnalytics />,
        display: 'sideMenuAnalytics',
        url: pages.analytics.path,
      },
      {
        icon: <IoCashOutline />,
        display: 'sideMenuMonetisation',
        url: pages.monetisation.path,
      }
    ]
  },
  {
    icon: <MdOutlineAccountCircle />,
    display: 'sideMenuMyAccount',
    url: pages.account.path,
    shouldShow: (userData?: MaybeUser) => !!userData?.id,
    items: [
      {
        icon: <MdOutlinePaid />,
        display: 'sideMenuPaidSubscriptions',
        url: pages.paidSubscriptions.path,
      },
    ]
  },
  {
    icon: <MdOutlineRuleFolder />,
    display: 'sideMenuReviewFlagged',
    url: pages.contentModeration.path,
    shouldShow: (userData?: MaybeUser, userPermissions?: UserPermission[]) =>
      !!userData?.id &&
      userPermissions &&
      userPermissions.length > 0 &&
      userPermissions.some((perm) =>
        MODERATOR_PERMS.some((modPerm) => perm === modPerm),
      ),
  },
  {
    icon: <MdOutlineLogout />,
    display: 'sideMenuLogout',
    url: pages.logout.path,
    shouldShow: (userData?: MaybeUser) => !!userData?.id,
  },
]

const getUrl = (url?: UrlSetting, userData?: MaybeUser) => {
  if (!url) return '/'

  if (typeof url === 'function') return url(userData)

  return url
}

const selectedUrl = (url?: UrlSetting, userData?: MaybeUser) => {
  const currentUrl = getUrl(url, userData)

  if (currentUrl === '/') {
    return window.location.pathname === '/'
  }

  return window.location.pathname.startsWith(currentUrl)
}

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { userData: rawUserData, loggedIn, userPermissions } = useUser()
  const userData: User | null | undefined = loggedIn ? rawUserData : null
  const { t } = useTranslation()

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const renderButton = useCallback(
    () => (
      <div
        className={styles.sideMenuButton}
        onClick={toggleOpen}
        title={t(isOpen ? 'sideMenuClose' : 'sideMenuOpen')}
      >
        <IoMenu />
      </div>
    ),
    [toggleOpen],
  )

  return (
    <>
      {renderButton()}
      <div className={classnames(styles.sideMenu, { [styles.open]: isOpen })}>
        <div className={styles.header}>
          {renderButton()}
          <Logo disableResponsive />
        </div>
        <ul>
          {MENU_ITEMS.map(({ url, display, shouldShow, icon, items }) => (
            <>
              {(!shouldShow || shouldShow(userData, userPermissions)) && (
                <li>
                  <Link
                    to={getUrl(url, userData)}
                    className={
                      selectedUrl(url, userData) ? styles.selected : ''
                    }
                    title={display ? t(display) : ''}
                  >
                    {icon}
                    {display && t(display)}
                  </Link>

                  {items?.length && items?.length > 0 && (
                    <ul>
                      {items?.map(({ url, display, shouldShow, icon }) => (
                        <>
                          {(!shouldShow || shouldShow(userData, userPermissions)) && (
                            <li>
                              <Link
                                to={getUrl(url, userData)}
                                className={
                                  selectedUrl(url, userData) ? styles.selected : ''
                                }
                                title={display ? t(display) : ''}
                              >
                                {icon}
                                {display && t(display)}
                              </Link>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  )}
                </li>
              )}
            </>
          ))}
        </ul>
        {/* <div className={styles.version}>v{pkg.version}</div> */}
      </div>
    </>
  )
}

export default SideMenu
