import React, { useState } from 'react'
import Modal from 'react-modal'
import { IoClose } from 'react-icons/io5'
import { ReactComponent as Verified } from '../../assets/verified.svg'

import CoinbaseCommerceButton from 'react-coinbase-commerce'

import Button from '../Button'

import 'react-coinbase-commerce/dist/coinbase-commerce-button.css'
import styles from './Pay.module.scss'
import buttonStyles from '../../components/Button/Button.module.scss'
import { FUNCTIONS_URL, VERIFIED_PRICE_ID } from '../../config'
import axios from 'axios'
import { useUser } from '../../hooks'

Modal.setAppElement('#root')

const Pay = ({
  productName,
  onRequestClose,
  isOpen,
  checkoutId,
  onPaymentDetected,
  verifiedPrice,
}: PayProps) => {
  const { userData } = useUser()
  const [mode, setMode] = useState('')
  const [loading, setLoading] = useState(false)

  const payWithFiat = async () => {
    setLoading(true)

    const priceId = VERIFIED_PRICE_ID
    const startCheckout = `${FUNCTIONS_URL}/startCheckout?priceId=${encodeURIComponent(
      priceId,
    )}&cancelUrl=${encodeURIComponent(window.location.href)}&userName=${
      userData?.userName
    }`

    try {
      const {
        data: { url },
      } = await axios.get(startCheckout)
      window.location.href = url
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const payWithICP = async () => {
    if (!window?.ic?.plug) {
      window.location.href = 'https://plugwallet.ooo/'
      return
    }

    try {
      const hasAllowed = await window.ic.plug.requestConnect()

      if (hasAllowed) {
        console.log('Plug wallet is connected')

        const params = {
          to: 'ae7e3e915b5332cd66b09cb3e3f29dbc906c150530834420d6dd9ecd4fa0d281',
          amount: Math.floor(100000000 * verifiedPrice),
          memo: 'DSocial Verified Badge',
        }
        const result = await window.ic.plug.requestTransfer(params)
        console.log(result)
        onPaymentDetected((result.transactionId || result.height).toString())
      } else {
        window.alert('In order to pay with $ICP, you must give permission')
      }
    } catch (e) {
      window.alert(
        'Issue connecting to Plug wallet, make sure Plug is installed',
      )
    }
  }

  const onCryptoPaymentDetected = (e: any) => {
    onPaymentDetected(e.code)
  }

  return (
    <Modal
      portalClassName={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={`Buy ${productName}`}
      className={styles.modalContent}
    >
      <div className={styles.container}>
        <button className={styles.iconButton} onClick={onRequestClose}>
          <IoClose />
        </button>
        <h2>
          <Verified className={styles.verified} />
          Get Verified
        </h2>
        <p>
          Getting Verified on DSocial is $99.99 ({verifiedPrice.toFixed(2)} $ICP) per year.
        </p>
        {mode ? (
          <></>
        ) : (
          <div className={styles.picker}>
            {/* <div className={styles.option}>
              <Button loading={loading} onClick={payWithFiat}>
                💳 Pay with Card
              </Button>
            </div>

            <div className={styles.option}>
              <CoinbaseCommerceButton
                onPaymentDetected={onCryptoPaymentDetected}
                className={`${buttonStyles.button} ${styles.cryptoButton}`}
                checkoutId={checkoutId}
              >
                🔐 Pay with Crypto
              </CoinbaseCommerceButton>
              <p className={styles.desc}>
                Supports $BTC, $BCH, $USDC, $ETH, $DOGE, $LTC &amp; $DAI
              </p>
            </div> */}

            <div className={styles.option}>
              <Button onClick={payWithICP}>
                <img src="/icp.png" className={styles.icp} /> Pay {verifiedPrice.toFixed(2)} $ICP
              </Button>
              <p className={styles.desc}>
                You will need the{' '}
                <a href="https://plugwallet.ooo/" target="_blank">
                  Plug Extension
                </a>{' '}
                installed.
              </p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export interface PayProps {
  productName: string
  isOpen: boolean
  onRequestClose: () => void
  checkoutId: string
  onPaymentDetected: (code: string) => void
  verifiedPrice: number
}

export default Pay
