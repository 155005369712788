import { AR_WEAVE_HOST, ASSET_BASE_URL, CS_CDN, AR_CDN } from '../config'
import { Video } from '../types/backend/dsocial/dsocial.did'

const generateVideoUrl = (video: Video) => {
  if (video.hash && video.hash.startsWith('http')) {
    return video.hash.replace(CS_CDN.before, CS_CDN.after)
  }

  // if (video.assets.length > 2) {
  //   const icVideo = video.assets.find(r => r.category === 'icv')
  //   if (icVideo) {
  //     return icVideo.url //.replace('5xecn-dyaaa-aaaal-qbdcq-cai.raw.ic0.app', 'dsocialic.b-cdn.net')
  //   }
  // }

  if (video.hash === 'ar' && video.assets.length > 0) {
    const vid = video.assets.find(r => r.category === 'hls')
    if (vid) {
      return `${AR_WEAVE_HOST}${vid.url}`
    }
  }

  return `${ASSET_BASE_URL}${video.hash}`
}

export default generateVideoUrl
