// built to keep data bandwidth costs as low as possible
// caches images locally in local storage using data URLs

import { ImgHTMLAttributes, useEffect, useState } from 'react'
import sha1 from 'sha1'

const preloadingState: { [key: string]: boolean } = {}

const preloadImage = (cacheKey: string, src: string) => {
  if (!preloadingState[cacheKey]) {
    preloadingState[cacheKey] = true

    const image = new Image()
    let start = 0
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        return
      }

      ctx.drawImage(image, 0, 0, image.width, image.height)
      const dataUrl = canvas.toDataURL('image/jpeg')
      try {
        window.localStorage.setItem(cacheKey, dataUrl)
        // console.log(`download & cache of ${src} took ${Date.now() - start}ms...`)
      } catch (e) {
        // console.log('Sometimes storing image fails')
      }
    }
    // console.log(`starting download of ${src}`)
    start = Date.now()
    image.src = src
  }
}

const CachedImage = ({ src, ...rest }: ImgHTMLAttributes<any>) => {
  const [cachedSrc, setCachedSrc] = useState('')

  useEffect(() => {
    // do not reload if the src is empty or it is not a full URL
    if (!src || src.substring(0, 4) !== 'http') return
    const cacheKey = sha1(src)
    try {
      const cacheHit = window.localStorage.getItem(cacheKey)

      if (cacheHit) {
        // console.log(`found ${src}, stopped download...`)
        setCachedSrc(cacheHit)
        return
      }
    } catch (e) {
      // sometimes loading fails
    }
    // console.log(`preloading ${src}, downloading...`)
    preloadImage(cacheKey, src)
  }, [src])

  return <img src={cachedSrc || src} {...rest} />
}

export default CachedImage
