const durationCache: { [key: number]: string } = {}

const convertDuration = (duration: number) => {
  if (!durationCache[duration]) {
    let friendlyDuration = new Date(duration * 1000)
      .toISOString()
      .substring(11, 19)
    if (friendlyDuration.startsWith('00:00:')) {
      friendlyDuration = friendlyDuration.substring(3, 8)
    } else if (friendlyDuration.startsWith('00:')) {
      friendlyDuration = friendlyDuration.substring(3)
    }
    durationCache[duration] = friendlyDuration
  }

  return durationCache[duration]
}

export default convertDuration
