import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { Trans } from 'react-i18next'

import Header from '../Header'
import { changeLanguage, languages } from '../../translations/i18n'
import pkg from '../../../package.json'

import styles from './Page.module.scss'

const year = new Date().getFullYear()

const Page = ({
  title,
  children,
  disableFooter,
  center,
  className,
}: PageProps) => (
  <>
    <Helmet>
      <title>{title}</title>
      <script
        async
        defer
        src="https://scripts.simpleanalyticscdn.com/latest.js"
      />
      <meta name="package-version" content={pkg.version} />
      <link
        rel="canonical"
        href={`https://dsocial.app/${window.location.pathname}`}
      />
    </Helmet>
    <div className={`${styles.container} ${center ? styles.center : ''}`}>
      <Header />
      <div className={`${styles.content} ${className}`}>{children}</div>
      {!disableFooter && (
        <div className={styles.footer}>
          <p>
            &copy; {year} DSocial (v{pkg.version}) |{' '}
            <Trans i18nKey="footer">
              Built by
              <a
                href="https://twitter.com/ashappdev"
                target="_blank"
                rel="noreferrer"
              >
                @ashappdev
              </a>
              .
            </Trans>
          </p>

          <div className={styles.languages}>
            {languages.map(([code, emoji, desc]) => (
              <a
                title={desc}
                className={styles.lang}
                onClick={() => changeLanguage(code)}
              >
                {emoji}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  </>
)

interface PageProps {
  title?: string
  children: ReactNode
  disableFooter?: boolean
  center?: boolean
  className?: string
}

export default Page
