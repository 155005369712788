import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { LazyImage } from 'react-lazy-images'
import Modal from 'react-modal'
import moment from 'moment'
import {
  IoArrowDown,
  IoArrowUp,
  IoClose,
  IoCodeSlash,
  IoFlagSharp,
  IoFlashOutline,
  IoHeartOutline,
  IoHeartSharp,
  IoShareOutline,
} from 'react-icons/io5'
import slugify from 'slugify'
import useMobileDetect from 'use-mobile-detect-hook'
import Linkify from 'react-linkify'
import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react'
import Hls from 'hls.js'
import { useWindowHeight } from '@react-hook/window-size'
import classnames from 'classnames'

// @ts-ignore
import nns_ledgerDid from './nns_ledgerDid'
import { ReactComponent as Verified } from '../../assets/verified.svg'
import { getBackend, getClientId } from '../../lib/icp'
import {
  Comment,
  CommentInfo,
  User,
  Video,
  VideoAndUser,
  UserAndPayment,
} from '../../types/backend/dsocial/dsocial.did'
import { Page, Loading, Button, CachedImage, Logo } from '../../components'
import NotFound from '../404'
import { ASSET_BASE_URL, FUNCTIONS_URL } from '../../config'
import renderNumbers from '../../lib/numbers'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import ago from '../../lib/ago'
import pages, { generateUrl } from '../../pages'
import { useUser, useTranslation, useColorScheme } from '../../hooks'
import { getCacheItem, setCacheItem } from '../../lib/cache'
import routes from '../../pages'
import generateVideoUrl from '../../lib/generateVideoUrl'

import './Video.scss'
import styles from './Video.module.scss'
import generateThumbnailUrl from '../../lib/generateThumbnailUrl'
import { Helmet } from 'react-helmet'
import HlsSource from '../../components/HlsSource'
import getDt from '../../lib/dt'
import trackClick from '../../lib/trackClick'
import TrackImpression from '../../components/TrackImpression'
import trackWatchTime from '../../lib/trackWatchTime'
import { Tags } from '../../config/tags'
import { ColorScheme } from '../../types'
import ClipLoader from 'react-spinners/ClipLoader'

Modal.setAppElement('#root')

const SourceTag = (props: any) => <source src={props.src} />

const linkProperties = { target: '_blank', rel: 'nofollow noopener noreferrer' }

const convertDurationToSeconds = (duration: string) => {
  const a = duration.split(':') // split it at the colons

  if (a.length === 3)
    return (
      parseInt(a[0], 10) * 60 * 60 +
      parseInt(a[1], 10) * 60 +
      parseInt(a[2], 10)
    )
  else if (a.length === 2) return parseInt(a[0], 10) * 60 + parseInt(a[1], 10)

  return 0
}

const timestampRegex = /[0-5][0-9]:[0-5][0-9]/gim
const replaceTimestamps = (a: string) =>
  `[${a}](${window.location.pathname}?s=${convertDurationToSeconds(a)})`

const copyLink = async (url: string, msg?: string) => {
  try {
    await window.navigator.clipboard.writeText(url)
    window.alert(msg || 'Link copied to the clipboard')
  } catch (e) {
    // do nothing
  }
}

interface PayingStatus {
  loadingPrice?: boolean
  usdPrice?: number
  symbol?: string
  commission?: number
  commissionPaid?: boolean
  tip?: number
  tipPaid?: boolean
}

type CommentOrPayment = CommentInfo | UserAndPayment

const TRACK_INTERVAL_IN_MS = 1000
let trackInterval: NodeJS.Timeout | null = null

const Watch = () => {
  const screenHeight = useWindowHeight()
  const detectMobile = useMobileDetect()
  const colorScheme = useColorScheme()
  const { t } = useTranslation()
  const playerRef = useRef()
  const { userData, loggedIn, loading: loadingUser } = useUser()
  const realUser = !loadingUser && loggedIn && !!userData?.id
  const params = new URLSearchParams(window.location.search)
  const secondsStart = params.get('s')
  const embedMode = window.location.pathname.toLowerCase().startsWith('/embed')
  const { videoId } = useParams<{ videoId?: string }>()
  const skipTo =
    secondsStart && !isNaN(Number(secondsStart)) ? Number(secondsStart) : -1
  const history = useHistory()
  const [recommended, setRecommended] = useState<VideoAndUser[]>([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [paying, setPaying] = useState<PayingStatus | null | undefined>()
  const [loadingRecommended, setLoadingRecommended] = useState(true)
  const cachedVideo = videoId ? getCacheItem<Video>(videoId) : null
  const cachedChannel = cachedVideo
    ? getCacheItem<User>(cachedVideo.userId)
    : null
  const [loadingVerified, setLoadingVerified] = useState(true)
  const [verified, setVerified] = useState(false)
  const [details, setDetails] = useState<Video | null | undefined>(cachedVideo)
  const [channel, setChannel] = useState<User | null | undefined>(cachedChannel)
  const [comments, setComments] = useState<CommentInfo[]>([])
  const [videoTips, setVideoTips] = useState<UserAndPayment[]>([])
  const [commentsAndTips, setCommentsAndTips] = useState<any[]>([])
  const [totalTips, setTotalTips] = useState<number>()
  const [desc, setDesc] = useState('')
  const [loadingSubscribers, setLoadingSubscribers] = useState(true)
  const [subscribers, setSubscribers] = useState<number>(0)
  const [newComment, setNewComment] = useState('')
  const [newCommentFocussed, setNewCommentFocussed] = useState(false)
  const [loading, setLoading] = useState(!cachedVideo)
  const [notFound, setNotFound] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [selectedTip, setSelectedTip] = useState(TIPS[0])
  const [error, setError] = useState('')
  const [toggleSubscribeRunning, setToggleSubscribeRunning] = useState(false)
  const [addingComment, setAddingComment] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const [loadingIsSubscribed, setLoadingIsSubscribed] = useState(true)
  const [hasLiked, setHasLiked] = useState(false)
  const [loadingHasLiked, setLoadingHasLiked] = useState(true)
  const [playNextEnabled, setPlayNextEnabled] = useState(true)
  const [icpAddress, setIcpAddress] = useState('')
  const [tipPaid, setTipPaid] = useState(false)
  const [monthlySubscription, setMonthlySubscription] = useState(5.99)
  const [commentsCount, setCommentsCount] = useState(
    details?.comments ? Number(details?.comments) : 0,
  )
  const [likesCount, setLikesCount] = useState(
    details?.likes ? Number(details?.likes) : 0,
  )
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMoreOpen, setReadMoreOpen] = useState(false)
  const [played, setPlayed] = useState(false)
  const [joiningPaid, setJoiningPaid] = useState(false)
  const videoWrapperRef = useRef<HTMLDivElement>(null)
  const videoPlayerHeight = Math.round((videoWrapperRef?.current?.clientWidth || 780) * 0.56)
  const flagged = details?.tags
    ? details?.tags.filter(
        (tag) => [Tags.CopyrightedContent, Tags.Illegal, Tags.Nsfw].some(prohibited => tag === prohibited),
      )
    : []

  const channelName = channel?.displayName || channel?.userName || ''
  const oldEmbedUrl = params.get('embed') === 'y'

  const [loadingIsPaidSubscriber, setLoadingIsPaidSubscriber] = useState(true)
  const [togglePaidSubscriberRunning, setTogglePaidSubscriberRunning] = useState(false)
  const [isPaidSubscriber, setIsPaidSubscriber] = useState(false)
  const togglePaidSubscriber = useCallback(() => {
    setShowJoinModal(true)
  }, [isPaidSubscriber])
  const [showJoinModal, setShowJoinModal] = useState(false)

  const descRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      const { height } = node.getBoundingClientRect()
      if (height > 100) {
        setShowReadMore(true)
      }
    }
  }, [])

  const readMorePlease = () => {
    setReadMoreOpen(!readMoreOpen)
  }

  const protect = useCallback(() => {
    const safe = loggedIn && userData?.id

    if (!safe) {
      history.push(pages.login.path)
    }

    return !safe
  }, [videoId, loggedIn, userData?.id])

  const toggleSubscribe = useCallback(async () => {
    if (protect()) return
    const backend = await getBackend()

    if (details?.userId) {
      setToggleSubscribeRunning(true)
      if (subscribed) {
        backend.unsubscribe(details?.userId, getDt())
        setSubscribed(false)
        setSubscribers(subscribers - 1)
      } else {
        backend.subscribe(details?.userId, getDt())
        setSubscribed(true)
        setSubscribers(subscribers + 1)
      }
      setToggleSubscribeRunning(false)
    }
  }, [videoId, details?.userId, loggedIn, userData?.id])

  const loadComments = useCallback(async () => {
    const backend = await getBackend()

    if (!videoId) return

    backend.getVideoComments(videoId!).then((commentsResult) => {
      // console.log('getVideoComments', commentsResult)
      const commentRecords =
        commentsResult?.length > 0 && commentsResult[0] ? commentsResult[0] : []
      setComments(commentRecords)
      setCommentsCount(commentRecords.length)
    })

    backend.getVideoTips(videoId!).then((videoTipsResult) => {
      setVideoTips(videoTipsResult)
      let newTotalTips = 0.0
      videoTipsResult.forEach(({ payment: { amount } }) => {
        newTotalTips += Number(amount)
      })
      setTotalTips(newTotalTips)
    })
  }, [videoId])

  const addLike = useCallback(async () => {
    if (protect()) return

    const backend = await getBackend()

    if (!videoId) return

    // Update UI immediately
    setHasLiked(true)
    setLikesCount(likesCount + 1)

    const start = Date.now()
    const addLikeResult = await backend.addLike(videoId, getDt())
    console.log(`addLike took ${Date.now() - start}ms`, addLikeResult)
  }, [videoId, likesCount, loggedIn, userData?.id])

  const share = () => {
    const url = `https://dsocial.app/${videoId}`

    console.log('share result', url)

    if (window.navigator.share) {
      window.navigator
        .share({ url })
        .then(() => console.log('successfully shared'))
        .catch(() => copyLink(url))
    } else {
      copyLink(url)
    }

    getBackend().then(({ incrementShares, getCallerInfo }) => {
      getCallerInfo().then((caller) => {
        console.log(`incrementShare start`)
        incrementShares(videoId!, caller.isAnonymous(), getDt()).then(() => {
          console.log(`incrementShares done`)
        })
      })
    })
  }

  const embed = useCallback(() => {
    const embedUrl = `https://dwqte-viaaa-aaaai-qaufq-cai.raw.ic0.app/embed/${videoId}`

    console.log('embed', embedUrl)

    copyLink(
      `<iframe width="560" height="315" src="${embedUrl}" title="DSocial video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
      'Embed code copied to your clipboard',
    )
  }, [videoId])

  const flag = useCallback(async () => {
    window.alert('Video flagged for Review, thanks for your report')
    const backend = await getBackend()
    await backend.reportVideo(videoId!, getClientId())
  }, [videoId])

  const tip = useCallback(() => {
    setModalIsOpen(true)
  }, [videoId])

  const makeTip = useCallback(async () => {
    if (!window?.ic?.plug) {
      const proceed = window.confirm(t('videoTipConfirmInstallPlug'))
      if (proceed) {
        window.location.href = 'https://plugwallet.ooo/'
      }
      return
    }

    setPaying({ loadingPrice: true })

    let usdIcpPrice = 0.0
    let icpToPay = 0.0

    try {
      const res = await fetch(`${FUNCTIONS_URL}/getPrice`)
      const data = await res.json()

      usdIcpPrice = parseFloat(data.items[0].price)
      icpToPay = selectedTip / usdIcpPrice

      console.log('usdIcpPrice', usdIcpPrice)
      console.log('icpToPay', icpToPay)
    } catch (e) {
      window.alert('Failed to load ICP live price, please try again')
      setPaying(null)
      return
    }

    try {
      const hasAllowed = await window.ic.plug.requestConnect()

      if (hasAllowed) {
        console.log('Plug wallet is connected')

        const commission = 0.1
        const commissionIcp = icpToPay * commission
        const tipIcp = icpToPay * (1 - commission)
        let payingInfo: PayingStatus = {
          tip: selectedTip * (1 - commission),
          commission: selectedTip * commission,
        }
        setPaying(payingInfo)
        console.log('commissionIcp', commissionIcp)
        console.log('tipIcp', tipIcp)
        let result = await window.ic.plug.requestTransfer({
          to: 'ae7e3e915b5332cd66b09cb3e3f29dbc906c150530834420d6dd9ecd4fa0d281',
          amount: Math.round(commissionIcp * 100000000),
        })

        payingInfo = {
          ...payingInfo,
          commissionPaid: true,
        }
        setPaying(payingInfo)
        result = await window.ic.plug.requestTransfer({
          to: icpAddress,
          amount: Math.round(tipIcp * 100000000)
        })
        payingInfo = {
          ...payingInfo,
          tipPaid: true,
        }
        setPaying(payingInfo)

        const backend = await getBackend()
        /*
         videoId: VideoId,
        dt: Text,
        to: UserId,
        amount: Float,
        */
        backend
          .recordPayment(videoId || '', getDt(), channel?.id || '', selectedTip)
          .then(() => {
            console.log('recordPayment succeeded')
          })
          .catch((e) => {
            console.log('recordPayment failed', e)
          })
        setTipPaid(true)
      } else {
        window.alert('In order to pay with $ICP, you must give permission')
      }
    } catch (e) {
      console.error(e)
      window.alert(
        'Issue connecting to Plug wallet, make sure Plug is installed',
      )
    }
    setPaying(null)
  }, [videoId, t, selectedTip, channel, icpAddress])

  const onRequestClose = useCallback(() => {
    setModalIsOpen(false)
    setTipPaid(false)
  }, [])

  const addComment = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      if (protect()) {
        console.log('early exit')
        return
      }

      const newCommentText = newComment ? newComment.trim() : ''

      console.log('newCommentText', newCommentText)

      if (!newCommentText || !videoId) return

      setAddingComment(true)

      const newDummyComment = {
        user: userData!,
        comment: {
          id: `temp_${userData?.id}_${Date.now()}`,
          userId: userData!.id,
          comment: newCommentText,
          when: BigInt(Date.now() * 1000000),
          likes: BigInt(0),
          replies: BigInt(0),
          videoId,
        },
      }

      console.log('newDummyComment', newDummyComment)

      setComments([newDummyComment, ...comments])
      setCommentsCount(commentsCount + 1)
      setNewComment('')
      setNewCommentFocussed(false)
      setAddingComment(false)

      let start = Date.now()
      const backend = await getBackend()
      const commentResult = await backend.addComment(
        videoId,
        newCommentText,
        getDt(),
      )

      console.log(`addComment took ${Date.now() - start}`)

      if (commentResult.newCommentId) {
        loadComments()
      }
    },
    [videoId, newComment, comments, commentsCount, loggedIn, userData?.id],
  )

  useEffect(() => {
    // Clean up for page
    return () => {
      console.log('trackWatchTime: clearing interval...')
      if (trackInterval) clearInterval(trackInterval)
    }
  }, [videoId])

  useEffect(() => {
    console.log('sort tips and comments')
    const newCommentsAndTips: CommentOrPayment[] = [...comments, ...videoTips]

    newCommentsAndTips.sort((a, b) => {
      // @ts-ignore
      const whenA = Number(a.comment?.when || a.payment?.when || BigInt(0))
      // @ts-ignore
      const whenB = Number(b.comment?.when || b.payment?.when || BigInt(0))

      return Number(whenB) - Number(whenA)
    })

    console.log('newCommentsAndTips', newCommentsAndTips)

    setCommentsAndTips(newCommentsAndTips)
  }, [comments, videoTips])

  const joinPaid = useCallback(async () => {
    setJoiningPaid(true)

    // Make payment
    if (!window?.ic?.plug) {
      const proceed = window.confirm(t('videoTipConfirmInstallPlug'))
      if (proceed) {
        window.location.href = 'https://plugwallet.ooo/'
      }
      return
    }

    let usdIcpPrice = 0.0
    let icpToPay = 0.0

    try {
      const res = await fetch(`${FUNCTIONS_URL}/getPrice`)
      const data = await res.json()

      usdIcpPrice = parseFloat(data.items[0].price)
      icpToPay = monthlySubscription / usdIcpPrice

      console.log('usdIcpPrice', usdIcpPrice)
      console.log('icpToPay', icpToPay)
    } catch (e) {
      window.alert('Failed to load ICP live price, please try again')
      setJoiningPaid(false)
      return
    }

    try {
      const hasAllowed = await window.ic.plug.requestConnect()

      if (hasAllowed) {
        console.log('Plug wallet is connected')

        const commission = 0.1
        const commissionIcp = icpToPay * commission
        const monthlyIcp = icpToPay * (1 - commission)
        console.log('commissionIcp', commissionIcp)
        console.log('monthlyIcp', monthlyIcp)
        let result = await window.ic.plug.requestTransfer({
          to: 'ae7e3e915b5332cd66b09cb3e3f29dbc906c150530834420d6dd9ecd4fa0d281',
          amount: Math.round(commissionIcp * 100000000)
        })
        result = await window.ic.plug.requestTransfer({
          to: icpAddress,
          amount: Math.round(monthlyIcp * 100000000)
        })

        const backend = await getBackend()
        backend
          .joinPaidSubscription(channel?.id!, monthlySubscription)
          .then((res) => {
            console.log('joinPaidSubscription succeeded', res)
            setJoiningPaid(false)

            if (res === 'recorded-subscription-and-payment') {
              setIsPaidSubscriber(true)
              setShowJoinModal(false)
              window.alert(`Thank you! You have successfully joined a paid subscriotion for ${channel?.displayName || channel?.userName}`)
            } else if (res === 'already-paid-sub') {
              window.alert(`You are already a paid subscriber for ${channel?.displayName || channel?.userName}`)
            } else if (res === 'user-not-found') {
              window.alert(`You need to register first`)
            }            
          })
          .catch((e) => {
            console.log('joinPaidSubscription failed', e)
            window.alert('Failed to record subscription, please DM @DSocialApp on Twitter to resolve this')
            setJoiningPaid(false)
          })
      } else {
        window.alert('In order to pay with $ICP, you must give permission')
      }
    } catch (e) {
      console.error(e)
      window.alert(
        'Issue connecting to Plug wallet, make sure Plug is installed',
      )
    }
  }, [icpAddress, monthlySubscription, details, channel])

  const onEnded = useCallback(() => {
    if (!embedMode && playNextEnabled && recommended?.length > 0 && !paying && !showJoinModal && !joiningPaid) {
      const {
        video: { id, title },
      } = recommended[0]

      history.push(
        generateUrl(pages.video.path, {
          videoId: id,
          videoSlug: slugify(title, { lower: true, strict: true }),
        }),
      )
    }
    if (trackInterval) clearInterval(trackInterval)
  }, [embedMode, playNextEnabled, recommended, history, paying, showJoinModal, joiningPaid])

  const onPlaying = useCallback(() => {
    // console.log('trackWatchTime: on playing...')

    if (trackInterval) clearInterval(trackInterval)

    trackInterval = setInterval(() => {
      // console.log('trackWatchTime: 1s TICK')

      // @ts-ignore
      const state = playerRef?.current.getState() as any
      // console.log('trackWatchTime: player info', state)

      if (state?.player?.duration) {
        const { player } = state
        const playbackRate = player.playbackRate || 1
        const watchTimeInSeconds = (TRACK_INTERVAL_IN_MS / 1000) * playbackRate
        // console.log('trackWatchTime: watchTimeInSeconds=', watchTimeInSeconds)

        const watchTimeInMinutes = watchTimeInSeconds / 60.0
        const watchTimeRate = watchTimeInSeconds / player.duration

        trackWatchTime(videoId!, watchTimeInMinutes, watchTimeRate)
      }
    }, TRACK_INTERVAL_IN_MS)
  }, [playerRef?.current])

  const onWaiting = useCallback(() => {
    // console.log('trackWatchTime: on waiting...')
    if (trackInterval) clearInterval(trackInterval)
  }, [])

  const onPause = useCallback(() => {
    // console.log('trackWatchTime: on pause...')
    if (trackInterval) clearInterval(trackInterval)
  }, [])

  const onCanPlay = useCallback(() => {
    if (skipTo > -1 && playerRef?.current) {
      // @ts-ignore
      if (playerRef.current.seek) {
        // @ts-ignore
        playerRef.current.seek(skipTo)
      } else {
        // @ts-ignore
        playerRef.current.currentTime = skipTo
      }
    }
    setPlayed(true)
  }, [skipTo, playerRef?.current])

  useEffect(() => {
    if (oldEmbedUrl && videoId) {
      history.replace(generateUrl(pages.embed.path, { videoId }))
    }
  }, [oldEmbedUrl, videoId])

  useEffect(() => {
    if (played && skipTo > -1 && playerRef?.current) {
      onCanPlay()
    }
  }, [played, skipTo, playerRef?.current])

  useEffect(() => {
    async function load() {
      setLoading(true)

      if (loadingUser) return

      try {
        if (videoId) {
          let start = Date.now()
          const backend = await getBackend()

          if (!realUser || embedMode) {
            setHasLiked(false)
            setLoadingHasLiked(false)
          } else {
            setLoadingHasLiked(true)
            backend.hasLiked(videoId).then((hasLikedResult) => {
              setHasLiked(hasLikedResult)
              setLoadingHasLiked(false)
            })
          }

          const videoResults = await backend.getVideo(videoId)
          // console.log(`getVideo took ${Date.now() - start}ms`, videoResults)

          const video = videoResults.length > 0 ? videoResults[0] : null
          setNotFound(!video)
          setDetails(video)
          setLoading(false)
          setLikesCount(video?.likes ? Number(video?.likes) : 0)
          setCommentsCount(video?.comments ? Number(video?.comments) : 0)

          if (video) {
            // console.log('getting video stats...')
            // backend
            //   .getVideoStats(videoId)
            //   .then((videoStats) => {
            //     console.log(
            //       `video stats`,
            //       videoStats && videoStats.length > 0 ? videoStats[0] : null,
            //     )
            //   })
            //   .catch((err) => {
            //     console.warn(`getVideoStats failed`, err)
            //   })

            setCacheItem(video.id, video)
            setChannel(null)
            backend.getUser(video.userId).then((userResults) => {
              const channelInfo =
                userResults?.length > 0 ? userResults[0] : null
              setCacheItem(video.userId, channelInfo)
              setChannel(channelInfo)

              setLoadingRecommended(true)
              setRecommended([])

              const loadVideoRecommendations = () => {
                console.log(`starting getVideoRecommendations(${videoId}) call`)
                backend
                  .getVideoRecommendations(videoId, getClientId())
                  .then((recommendedVideosResult) => {
                    console.log(
                      `getVideoRecommendations(${videoId}) result`,
                      recommendedVideosResult,
                    )
                    if (recommendedVideosResult.length > 0) {
                      const [videosList] = recommendedVideosResult
                      const videos: VideoAndUser[] = []
                      if (videosList) {
                        videosList.sort(
                          (a, b) =>
                            Number(b.video.likes || 0) -
                            Number(a.video.likes || 0),
                        )
                        videosList.forEach((item) => {
                          if (videos.length < 40) {
                            videos.push(item)
                          }
                        })
                      }
                      setRecommended(videos)
                    } else {
                      setRecommended([])
                    }
                    setLoadingRecommended(false)
                  })
                  .catch((err) => {
                    console.warn(
                      `getVideoRecommendations(${videoId}) result`,
                      err,
                    )
                  })
              }

              const query =
                video.title +
                ' ' +
                (channelInfo?.displayName || channelInfo?.userName || '')
              console.log(`starting search(${query}) call`)
              backend
                .search(query, BigInt(0), BigInt(20))
                .then((rawResults) => {
                  const searchResults: VideoAndUser[] =
                    rawResults.length > 0 && rawResults[0]
                      ? rawResults[0].filter(
                          ({ video: { id } }: VideoAndUser) => id !== videoId,
                        )
                      : []

                  console.log(`search(${query}) result`, searchResults)

                  if (searchResults.length === 0) {
                    loadVideoRecommendations()
                  } else {
                    setRecommended(searchResults)
                    setLoadingRecommended(false)
                  }
                })
                .catch((err) => {
                  console.warn(`error: search(${query})`, err)
                  loadVideoRecommendations()
                })
            })

            backend
              .getUserMonitizationSettingsById(video.userId)
              .then((monitization) => {
                const monitizationResult =
                  monitization?.length > 0 ? monitization[0] : null

                if (monitizationResult) {
                  const { addresses, settings } = monitizationResult

                  if (addresses && addresses.length > 0) {
                    const icp = addresses.find(({ id }) => id === 'icp')

                    if (icp && icp.address) {
                      setIcpAddress(icp.address)
                    }
                  }

                  const monthlySub = settings.find(({ key }) => key === 'monthlySub')
                  if (monthlySub) {
                    setMonthlySubscription(Number(monthlySub.value))
                  }
                }
              })

            if (!embedMode) {
              backend.getVerifiedStatus(video.userId).then((isVerified) => {
                setVerified(isVerified)
                setLoadingVerified(false)
              })

              if (!realUser) {
                console.warn('is not real user so marking subscribed as false')
                setSubscribed(false)
                setLoadingIsSubscribed(false)
                setIsPaidSubscriber(false)
                setLoadingIsPaidSubscriber(false)
              } else {
                setLoadingIsSubscribed(true)
                backend
                  .isSubscribed(video.userId)
                  .then((isSubscribed) => {
                    console.warn(
                      `isSubscribed(${video.userId}) result`,
                      isSubscribed,
                    )
                    setSubscribed(isSubscribed)
                    setLoadingIsSubscribed(false)
                  })
                  .catch((err) => {
                    console.warn(`error: isSubscribed(${video.userId})`, err)
                  })
                setLoadingIsPaidSubscriber(true)
                backend
                  .isPaidSubscriber(video.userId)
                  .then((isPaidSubscriberResult) => {
                    console.warn(
                      `isPaidSubscriber(${video.userId}) result`,
                      isPaidSubscriberResult,
                    )
                    setIsPaidSubscriber(isPaidSubscriberResult)
                    setLoadingIsPaidSubscriber(false)
                  })
                  .catch((err) => {
                    console.warn(`error: isPaidSubscriber(${video.userId})`, err)
                  })
              }

              setLoadingSubscribers(true)
              setSubscribers(0)
              backend
                .getSubscriberCount(video.userId)
                .then((subscriberCountResults) => {
                  setSubscribers(
                    subscriberCountResults?.length > 0
                      ? Number(subscriberCountResults[0])
                      : 0,
                  )
                  setLoadingSubscribers(false)
                })

              // console.log('getVideoComments hit?')
              setComments([])
              loadComments()
            }

            start = Date.now()
            const caller = await backend.getCallerInfo()
            await backend.incrementView(
              videoId,
              getClientId(),
              caller.isAnonymous(),
              getDt(),
            )
            console.log(`incrementView took ${Date.now() - start}ms`)
          }
        }
      } catch (e: any) {
        setError(e.message)
      }
    }

    load()
  }, [loadingUser, videoId])

  if (loading) {
    return <Loading />
  }

  if (notFound || error) {
    return (
      <>
        <NotFound error={error} />
      </>
    )
  }

  if (flagged.length > 0) {
    return (
      <Page title={`${details?.title} | DSocial`} className={styles.content}>
        This video is flagged as "{flagged[0]}"
      </Page>
    )
  }

  const thumbUrl = generateThumbnailUrl(details!)
  const videoUrl = generateVideoUrl(details!)
  const hlsEnabled = details?.hash === 'ar'
  const Source = hlsEnabled ? HlsSource : SourceTag

  if (embedMode) {
    return (
      <>
        <Helmet>
          <title>{details?.title} | DSocial</title>
          <script
            async
            defer
            src="https://scripts.simpleanalyticscdn.com/latest.js"
          />
        </Helmet>
        <div className="embeddedVideo">
          <Player
            playsInline
            ref={playerRef}
            poster={thumbUrl}
            className={styles.video}
            width="100%"
            height={screenHeight}
            onLoadedData={onCanPlay}
            onEnded={onEnded}
            onPlaying={onPlaying}
            onPause={onPause}
            onWaiting={onWaiting}
            autoPlay={false}
            fluid={false}
          >
            <div className={styles.embedTitle}>
              {channel && (
                <div className={styles.embedProfilePhoto}>
                  <Link
                    to={generateUrl(pages.channel.path, {
                      userName: channel.userName,
                    })}
                    title={channel.displayName || channel.userName}
                  >
                    <CachedImage
                      src={generateProfilePhotoUrl(channel?.profilePhoto)}
                    />
                  </Link>
                </div>
              )}
              <a
                href={generateUrl(pages.video.path, {
                  videoId,
                  videoSlug: slugify(details!.title, {
                    lower: true,
                    strict: true,
                  }),
                })}
                className={styles.embedTitleText}
                target="_blank"
                title={details!.title}
              >
                {details?.title}
              </a>
              <Logo target="_blank" hideText className={styles.embedLogo} />
            </div>
            {/* @ts-ignore */}
            <Source src={videoUrl} isVideoChild autoPlay={false} />
            {hlsEnabled && (
              <ControlBar>
                <PlaybackRateMenuButton
                  rates={[3, 2.5, 2, 1.5, 1, 0.5, 0.25]}
                  order={7}
                />
              </ControlBar>
            )}
          </Player>
        </div>
      </>
    )
  }

  return (
    <>
      <Page title={`${details?.title} | DSocial`} className={styles.content}>
        <div className={styles.sections}>
          <div className={styles.videoAndComments}>
            <div className={styles.videoWrapper} ref={videoWrapperRef}>
              <Player
                playsInline
                ref={playerRef}
                poster={thumbUrl}
                className={styles.video}
                width="100%"
                height={videoPlayerHeight}
                onLoadedData={onCanPlay}
                onEnded={onEnded}
                onPlaying={onPlaying}
                onPause={onPause}
                onWaiting={onWaiting}
                autoPlay={!detectMobile.isMobile()}
                fluid={false}
              >
                <Source
                  src={videoUrl}
                  // @ts-ignore
                  isVideoChild
                  autoPlay={!detectMobile.isMobile()}
                />
                {hlsEnabled && (
                  <ControlBar>
                    <PlaybackRateMenuButton
                      rates={[3, 2.5, 2, 1.5, 1, 0.5, 0.25]}
                      order={7}
                    />
                  </ControlBar>
                )}
              </Player>
              {/* {showPlaceholder && (
                <div
                  style={{
                    backgroundImage: `url(${ASSET_BASE_URL}${details?.thumb})`,
                  }}
                  className={styles.placeholder}
                />
              )} */}
            </div>

            <div className={styles.underVideo}>
              <h1>{details?.title}</h1>

              <div
                className={`${styles.viewsAndWhen} ${styles.viewAndLikeBar}`}
              >
                <div className={styles.left}>
                  <span className={styles.views}>
                    {renderNumbers(details?.views, 'views', t)}
                  </span>
                  <span className={styles.seperator}>{'\u2B24'}</span>
                  <span className={styles.ago}>{ago(details?.when)}</span>
                  {totalTips ? (
                    <>
                      <span className={styles.seperator}>{'\u2B24'}</span>
                      <span className={styles.ago}>
                        $
                        {totalTips.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </>
                  ) : null}
                </div>

                <div className={styles.likesAndShares}>
                  {!detectMobile.isMobile() && (
                    <>
                      {icpAddress && (
                        <div className={styles.tip} onClick={tip}>
                          <IoFlashOutline />
                          {t('videoTip')}
                        </div>
                      )}
                      <div className={styles.embed} onClick={embed}>
                        <IoCodeSlash />
                        {t('videoEmbed')}
                      </div>
                    </>
                  )}
                  <div className={styles.share} onClick={share}>
                    <IoShareOutline />
                    {t('videoShare')}
                  </div>
                  <div
                    onClick={hasLiked ? undefined : addLike}
                    className={`${styles.like} ${hasLiked ? styles.liked : ''}`}
                  >
                    {!loadingHasLiked ? (
                      <>
                        {likesCount > 0 ? (
                          <>
                            <IoHeartSharp className={styles.heart} />
                            {likesCount === 1
                              ? t('video1Like')
                              : t('videoLikes', {
                                  likes: likesCount.toLocaleString(),
                                })}
                          </>
                        ) : (
                          <>
                            <IoHeartOutline />
                            {t('videoLike')}
                          </>
                        )}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                  <div className={styles.report} onClick={flag}>
                    <IoFlagSharp />
                    {t('videoFlag')}
                  </div>
                </div>
              </div>

              {channel && (
                <>
                  <div className={styles.creator}>
                    <div className={styles.profilePhoto}>
                      <Link
                        to={generateUrl(pages.channel.path, {
                          userName: channel.userName,
                        })}
                      >
                        <CachedImage
                          src={generateProfilePhotoUrl(channel?.profilePhoto)}
                        />
                      </Link>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.top}>
                        <div className={styles.channelInfo}>
                          <h3>
                            <Link
                              to={generateUrl(pages.channel.path, {
                                userName: channel.userName,
                              })}
                            >
                              {channelName}

                              {!loadingVerified && verified && (
                                <Verified className={styles.verified} />
                              )}
                            </Link>
                          </h3>
                          {!loadingSubscribers && (
                            <p>
                              {renderNumbers(subscribers, 'subscribers', t)}
                            </p>
                          )}
                        </div>
                        <div className={styles.subscribeButtonWrapper}>
                          {!loadingIsPaidSubscriber &&
                            icpAddress &&
                            channel?.id !== userData?.id &&
                            userData?.id && 
                            userData?.userName && (
                              <Button
                                onClick={togglePaidSubscriber}
                                loading={togglePaidSubscriberRunning}
                                cancel={isPaidSubscriber}
                                invert
                              >
                                {isPaidSubscriber ? t('joined') : t('join')}
                              </Button>
                          )}

                          {!loadingIsSubscribed &&
                            channel?.id !== userData?.id && (
                              <Button
                                onClick={toggleSubscribe}
                                loading={toggleSubscribeRunning}
                                cancel={subscribed}
                              >
                                {subscribed ? t('subscribed') : t('subscribe')}
                              </Button>
                            )}
                        </div>
                      </div>
                      {details?.desc && (
                        <>
                          <div
                            className={`${styles.desc} ${
                              readMoreOpen ? styles.open : ''
                            }`}
                          >
                            <div className={styles.descInner} ref={descRef}>
                              <ReactMarkdown
                                children={details?.desc.replace(
                                  timestampRegex,
                                  replaceTimestamps,
                                )}
                                components={{
                                  p: (props: any) => (
                                    // @ts-ignore
                                    <Linkify properties={linkProperties}>
                                      <p>{props.children}</p>
                                    </Linkify>
                                  ),
                                  a: (props: any) => {
                                    return props.href.match(/^\//) ? (
                                      <Link to={props.href}>
                                        {props.children}
                                      </Link>
                                    ) : (
                                      <a href={props.href} {...linkProperties}>
                                        {props.children}
                                      </a>
                                    )
                                  },
                                }}
                              />
                            </div>
                          </div>
                          {showReadMore && (
                            <div
                              className={styles.readMore}
                              onClick={readMorePlease}
                            >
                              {readMoreOpen ? (
                                <>
                                  <IoArrowUp />
                                  Read Less
                                </>
                              ) : (
                                <>
                                  <IoArrowDown />
                                  Read More
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={styles.commentsSection}>
                    <h4>
                      {t('videoComments', {
                        comments: commentsCount.toLocaleString(),
                      })}
                    </h4>

                    <div className={styles.addComment}>
                      <div className={styles.profilePhoto}>
                        <img
                          src={generateProfilePhotoUrl(userData?.profilePhoto)}
                        />
                      </div>
                      <form className={styles.right} onSubmit={addComment}>
                        <textarea
                          placeholder={t('videoAddPublicComment')}
                          onChange={(e) => setNewComment(e.target.value)}
                          onFocus={() => setNewCommentFocussed(true)}
                          disabled={addingComment}
                          value={newComment}
                        />

                        {(newCommentFocussed || addingComment) && (
                          <div className={styles.newCommentButtons}>
                            <Button
                              disabled={addingComment}
                              type="reset"
                              onClick={() => setNewCommentFocussed(false)}
                            >
                              {t('videoCancelButton')}
                            </Button>
                            <Button loading={addingComment} type="submit">
                              {t('videoSubmitButton')}
                            </Button>
                          </div>
                        )}
                      </form>
                    </div>

                    <div className={styles.comments}>
                      {commentsAndTips.map(
                        ({
                          comment,
                          payment,
                          user: { id, displayName, userName, profilePhoto },
                        }) => (
                          <div
                            className={styles.comment}
                            key={comment?.id || Number(payment?.when || 0)}
                          >
                            <div className={styles.profilePhoto}>
                              <CachedImage
                                src={generateProfilePhotoUrl(profilePhoto)}
                              />
                            </div>
                            <div className={styles.right}>
                              <h5
                                className={
                                  (comment?.userId || payment.from) ===
                                  details?.userId
                                    ? styles.creatorComment
                                    : ''
                                }
                              >
                                {id ? (
                                  <Link
                                    to={generateUrl(pages.channel.path, {
                                      userName,
                                    })}
                                  >
                                    {displayName || userName}
                                  </Link>
                                ) : (
                                  t('videoTipAnon')
                                )}
                                <span className={styles.when}>
                                  {ago(comment?.when || payment?.when)}
                                </span>
                              </h5>

                              {comment ? (
                                <ReactMarkdown>{comment.comment}</ReactMarkdown>
                              ) : (
                                <p>
                                  {t('videoTipComment', {
                                    amount: payment
                                      ? payment.amount.toLocaleString()
                                      : 0,
                                  })}
                                </p>
                              )}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.recommended}>
            {recommended.map(({ video, user: { displayName, userName } }) => {
              const { id, title, when, views } = video
              return (
                <TrackImpression videoId={id}>
                  <Link
                    className={styles.recommendationRow}
                    to={generateUrl(pages.video.path, {
                      videoId: id,
                      videoSlug: slugify(title, { lower: true, strict: true }),
                    })}
                    key={id}
                    onClick={() => trackClick(id)}
                  >
                    <LazyImage
                      src={generateThumbnailUrl(video)}
                      alt={title}
                      placeholder={({ ref }) => (
                        <div ref={ref} className={styles.placeholder} />
                      )}
                      actual={({ imageProps }) => (
                        <img crossOrigin="anonymous" {...imageProps} />
                      )}
                    />

                    <div className={styles.recommendationCol}>
                      <strong>{title}</strong>
                      <p>
                        <Link
                          to={generateUrl(pages.channel.path, { userName })}
                        >
                          {displayName || userName}
                        </Link>
                      </p>
                      <p
                        className={`${styles.viewsAndWhen} ${styles.recommendedViewsAndWhen}`}
                      >
                        <span className={styles.views}>
                          {renderNumbers(views, 'views', t)}
                        </span>
                        <span className={styles.seperator}>{'\u2B24'}</span>
                        <span className={styles.ago}>{ago(when)}</span>
                      </p>
                    </div>
                  </Link>
                </TrackImpression>
              )
            })}
          </div>
        </div>
      </Page>
      <Modal
        portalClassName={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={modalIsOpen}
        onRequestClose={onRequestClose}
        contentLabel={t('videoTipTitle', {
          name: channel?.displayName || channel?.userName,
        })}
        className={styles.modalContent}
      >
        <div className={styles.container}>
          <button className={styles.iconButton} onClick={onRequestClose}>
            <IoClose />
          </button>

          {paying && !tipPaid && (
            <div className={styles.paying}>
              <h2>
                <ClipLoader
                  loading
                  size={32}
                  color={colorScheme === ColorScheme.Dark ? 'white' : '#333'}
                />
                <span>{t('videoTipProcessingPayment')}</span>
              </h2>
              <ul>
                {paying.loadingPrice && <li>Loading price...</li>}
                {paying.usdPrice && (
                  <li>$ICP Price: ${paying.usdPrice.toLocaleString()}</li>
                )}
                {paying.commission && (
                  <li>
                    DSocial Commission: ${paying.commission.toLocaleString()}
                  </li>
                )}
                {paying.commission && !paying.commissionPaid && (
                  <li>
                    <ClipLoader
                      loading
                      size={20}
                      color={
                        colorScheme === ColorScheme.Dark ? 'white' : '#333'
                      }
                    />{' '}
                    Paying Commission
                  </li>
                )}
                {paying.commission && paying.commissionPaid && (
                  <li>✅ Commission Paid</li>
                )}
                {paying.tip && !paying.tipPaid && (
                  <li>
                    <ClipLoader
                      loading
                      size={20}
                      color={
                        colorScheme === ColorScheme.Dark ? 'white' : '#333'
                      }
                    />{' '}
                    Paying Tip
                  </li>
                )}
                {paying.tip && paying.tipPaid && <li>✅ Tip Paid</li>}
              </ul>
            </div>
          )}

          {!paying && tipPaid && (
            <div className={styles.paying}>
              <h2>{t('videoTipPaid')}</h2>
              <p>
                {t('videoTipPaidThankYou', {
                  name: channel?.displayName || channel?.userName,
                })}
              </p>
            </div>
          )}

          {!paying && !tipPaid && (
            <>
              <h2>
                {t('videoTipTitle', {
                  name: channel?.displayName || channel?.userName,
                })}
              </h2>
              <p>
                {t('videoTipChooseAmount', {
                  name: channel?.displayName || channel?.userName,
                })}
              </p>

              <div className={styles.tips}>
                {TIPS.map((tip) => (
                  <div
                    className={classnames(styles.tip, {
                      [styles.selected]: selectedTip === tip,
                    })}
                    onClick={() => setSelectedTip(tip)}
                  >
                    ${tip.toLocaleString()}
                  </div>
                ))}
              </div>

              <div className={styles.buttons}>
                <Button onClick={makeTip}>
                  <img src="/icp.png" className={styles.icp} />{' '}
                  {t('videoTipPay')}
                </Button>
              </div>
              <p className={styles.desc}>
                You will need the{' '}
                <a href="https://plugwallet.ooo/" target="_blank">
                  Plug Extension
                </a>{' '}
                installed.
              </p>
            </>
          )}
        </div>
      </Modal>
      <Modal
        portalClassName={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={showJoinModal}
        onRequestClose={() => setShowJoinModal(false)}
        contentLabel={t('videoJoinTitle', {
          name: channel?.displayName || channel?.userName,
        })}
        className={styles.modalContent}
      >
         <div className={styles.container}>
          <button className={styles.iconButton} onClick={() => setShowJoinModal(false)}>
            <IoClose />
          </button>

          <div className={styles.joinModal}>
            <h2>
                {t('videoJoinTitle', {
                  name: channel?.displayName || channel?.userName,
                })}
                &nbsp;
                &nbsp;
                &nbsp;
            </h2>
            {joiningPaid ?
              <div>
                Joining...
              </div> :
              <div>
                {isPaidSubscriber ?
                  <p>
                    You are already a paid subscriber to {channel?.displayName || channel?.userName}. 
                    {/* Manage your subscriptions <Link to={routes.paidSubscriptions.path}>here</Link>. */}
                  </p> :
                  <>
                    <p>
                      To get access to exclusive benefits & become a supporter of {channel?.displayName || channel?.userName}. Click join below.
                    </p>
                    <div className={styles.buttons}>
                      
                      <Button onClick={joinPaid}>
                        <img src="/icp.png" className={styles.icp} />{' '}
                        Join for ${monthlySubscription.toFixed(2)}/mo
                      </Button>
                    </div>
                    <p className={styles.desc}>
                      You will need the{' '}
                      <a href="https://plugwallet.ooo/" target="_blank">
                        Plug Extension
                      </a>{' '}
                      installed.
                    </p>
                  </>}
                </div>}
            </div>
        </div>
      </Modal>
    </>
  )
}

const TIPS = [19.99, 9.99, 4.99, 2.99, 1.99]

export default Watch
