import { useWindowWidth } from '@react-hook/window-size'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Brush,
  AreaChart,
  Area,
} from 'recharts'

import { Loading, Page } from '../../components'
import { useColorScheme } from '../../hooks'
import { getBackend } from '../../lib/icp'
import { ColorScheme } from '../../types'
import {
  ChannelStats,
  DailyPayments,
} from '../../types/backend/dsocial/dsocial.did'

type DailyViews = {
  date: Date
  views: number
}

type Series = {
  label: string
  data: DailyViews[]
}

function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const Progress = () => {
  const screenWidth = useWindowWidth()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any[]>([])
  useEffect(() => {
    const getStats = async () => {
      const { getProgress, getChannelStats, getDailyPayments, getSubscriptionPaymentsItems } = 
        await getBackend()
      const result = await getProgress()
      console.log('getProgress', result)
      const dailyPayments = await getDailyPayments()
      const subPayments = await getSubscriptionPaymentsItems()

      console.log('subPayments', subPayments)

      result.sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())

      setData(
        result.map(
          ([
            dt,
            {
              ctr,
              clicks,
              avgViewDuration,
              shares,
              views,
              watchTime,
              impressions,
              likes,
              uniqueViews,
              watchTimeRate,
              comments,
            },
          ]: [string, ChannelStats]) => {
            const payments = dailyPayments.find(
              ([pDt]: [string, DailyPayments]) => pDt === dt,
            )

            const sps = subPayments
              .filter(([_, sp]) => {
                const m: string = moment(Number(sp.when) / 1000000).format('YYYY-MM-DD')
                return m === dt
              })
              .map(([_, sp]) => sp)

            if (sps.length > 0) console.log('sps', sps)

            let numberOfPayments = 0
            let gmv = 0.0
            let subscriptions = 0
            if (payments) {
              const info = payments[1]
              numberOfPayments = Number(info.count)
              gmv = Number(info.amount)
            }

            sps.forEach(({ amount }) => {
              subscriptions += amount
            })

            return {
              date: dt,
              ctr: Number(ctr),
              // clicks: Number(clicks),
              // avgViewDuration: Number(avgViewDuration),
              shares: Number(shares),
              views: Number(views),
              watchTime: Number(watchTime),
              // impressions: Number(impressions),
              likes: Number(likes),
              uniqueViews: Number(uniqueViews),
              watchTimeRate: Number(watchTimeRate),
              comments: Number(comments),
              numberOfPayments,
              gmv,
              subscriptions,
            }
          },
        ),
      )

      const channelStats = await getChannelStats()
      console.log('channelStats', channelStats)
      setLoading(false)
    }
    getStats()
  }, [])

  if (loading) return <Loading />

  const width = screenWidth - 80
  const height = Math.ceil(width * 0.4)
  return (
    <Page title="Progress">
      <h1>DSocial Stats</h1>

      <p>
        This is our internal dashboard to track progress towards 1M/views/day by
        Dec 2022.
      </p>

      <p>&nbsp;</p>

      <div style={{ width, height }}>
        <LineChart width={width} height={height} data={data}>
          {/* <CartesianGrid vertical={false} /> */}
          <XAxis dataKey="date" label="" />
          <YAxis domain={['auto', 'auto']} label="" />
          {/* <YAxis dataKey="ctr" orientation="right" /> */}
          <Tooltip
            wrapperStyle={{
              borderColor: 'white',
            }}
            contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            labelStyle={{ fontWeight: 'bold', color: '#666666' }}
          />

          {[
            'views',
            // 'uniqueViews',
            // 'likes',
            // 'comments',
            // 'shares',
            // 'ctr',
            // 'watchTime',
            // 'watchTimeRate',
          ].map((prop) => (
            <Line dataKey={prop} dot={false} stroke={getRandomColor()} />
          ))}
        </LineChart>
      </div>

      <div style={{ width, height }}>
        <LineChart width={width} height={height} data={data}>
          <XAxis dataKey="date" label="" />
          <YAxis domain={['auto', 'auto']} label="" />
          <Tooltip
            wrapperStyle={{
              borderColor: 'white',
            }}
            contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            labelStyle={{ fontWeight: 'bold', color: '#666666' }}
          />

          {['gmv', 'numberOfPayments', 'subscriptions'].map((prop) => (
            <Line dataKey={prop} dot={false} stroke={getRandomColor()} />
          ))}
        </LineChart>
      </div>
    </Page>
  )
}

export default Progress
