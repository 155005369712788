import { useTranslation } from '../../hooks'
import pages, { generateUrl } from '../../pages'
import { Button, Page } from '../../components'
import { useEffect } from 'react'
import { AR_ENABLED } from '../../config'
import { useHistory } from 'react-router-dom'

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return null
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows'
  } else if (/Android/.test(userAgent)) {
    return null
  } else if (!os && /Linux/.test(platform)) {
    return 'Linux'
  }

  return null
}

const os = getOS()

const UploaderApp = () => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (!AR_ENABLED) {
      history.replace(pages.upload.path)
    }
  }, [])

  return (
    <Page title={t('uploaderAppPageTitle')}>
      <h1>{t('uploaderAppPageTitle')}</h1>

      <p>{t('uploaderAppDesc')}</p>

      {os === 'Mac' ? (
        <p>
          <Button
            href={generateUrl(pages.download.path, { os: os?.toLowerCase() })}
          >
            {t('uploaderAppDownload', { os })}
          </Button>
        </p>
      ) : (
        <p>{t('uploaderAppNotAvailable')}</p>
      )}
    </Page>
  )
}

export default UploaderApp
