import { ComponentType, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Loading } from '../components'
import { useUser } from '../hooks'

export const withAuthCheck = (
  ChildComponent: ComponentType,
  isAnon?: boolean,
) => {
  const WithAuthHOC = () => {
    const { loading, loggedIn } = useUser()

    const router = useHistory()

    useEffect(() => {
      if (!loading) {
        if (!isAnon && !loggedIn) {
          router.replace('/')
        } else if (isAnon && loggedIn) {
          router.replace('/')
        }
      }
    }, [router, loading, isAnon, loggedIn])

    if (
      (isAnon && !loading && !loggedIn) ||
      (!isAnon && !loading && loggedIn)
    ) {
      return <ChildComponent />
    }

    return <Loading />
  }

  WithAuthHOC.displayName = 'WithAuthHOC'

  return WithAuthHOC
}
