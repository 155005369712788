import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Page } from '../components'
import { useTranslation } from '../hooks'

const NotFound = ({ error }: NotFoundProps) => {
  const { t } = useTranslation()

  return (
    <Page
      title={`${error ? t('notFoundError') : t('notFoundTitle')} | DSocial`}
    >
      <h1>{error ? t('notFoundError') : t('notFoundTitle')}</h1>

      <p>
        {error || (
          <Trans i18nKey="notFoundContent">
            This page is not found. Go back to the <Link to="/">homepage</Link>.
          </Trans>
        )}
      </p>
    </Page>
  )
}

interface NotFoundProps {
  error?: string
}

export default NotFound
