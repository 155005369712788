import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { TRANSLATIONS_EN } from './en/translations'
import { TRANSLATIONS_DE } from './de/translations'
import { TRANSLATIONS_ZH } from './zh/translations'
import { TRANSLATIONS_JA } from './ja/translations'
import { TRANSLATIONS_RU } from './ru/translations'
import { TRANSLATIONS_VI } from './vi/translations'
import { TRANSLATIONS_HI } from './hi/translations'

import { setTimeAgoLocale } from '../lib/ago'

export const languages = [
  ['en', '🇬🇧', 'English'],
  ['de', '🇩🇪', 'German'],
  ['zh', '🇨🇳', 'Chinese'],
  ['ja', '🇯🇵', 'Japanese'],
  ['ru', '🇷🇺', 'Russian'],
  ['vi', '🇻🇳', 'Vietnamese'],
  ['hi', '🇮🇳', 'Hindi'],
]

export const changeLanguage = (code: string) => {
  setTimeAgoLocale(code)
  i18n.changeLanguage(code)
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      zh: {
        translation: TRANSLATIONS_ZH,
      },
      ja: {
        translation: TRANSLATIONS_JA,
      },
      ru: {
        translation: TRANSLATIONS_RU,
      },
      vi: {
        translation: TRANSLATIONS_VI,
      },
      hi: {
        translation: TRANSLATIONS_HI,
      },
    },
    fallbackLng: 'en',
  })

setTimeAgoLocale(i18n.language)
