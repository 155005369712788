import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import de from 'javascript-time-ago/locale/de.json'
import zh from 'javascript-time-ago/locale/zh.json'
import ja from 'javascript-time-ago/locale/ja.json'
import ru from 'javascript-time-ago/locale/ru.json'
import vi from 'javascript-time-ago/locale/vi.json'
import hi from 'javascript-time-ago/locale/hi.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(de)
TimeAgo.addLocale(zh)
TimeAgo.addLocale(ja)
TimeAgo.addLocale(ru)
TimeAgo.addLocale(vi)
TimeAgo.addLocale(hi)

let timeAgo = new TimeAgo(navigator.language)

const ago = (when?: bigint) => {
  const newWhen = when ? Number(when) / 1000000 : 0

  if (newWhen) return timeAgo.format(newWhen)

  return ''
}

export const setTimeAgoLocale = (code: string) => {
  timeAgo = new TimeAgo(code)
}

export default ago
