export const idlFactory = ({ IDL }) => {
  const VideoId = IDL.Text;
  const AddCommentResponse = IDL.Record({
    'status' : IDL.Text,
    'newCommentId' : IDL.Text,
  });
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const UserId = IDL.Text;
  const ChannelStats = IDL.Record({
    'ctr' : IDL.Float64,
    'clicks' : IDL.Int,
    'avgViewDuration' : IDL.Float64,
    'shares' : IDL.Int,
    'views' : IDL.Int,
    'watchTime' : IDL.Float64,
    'impressions' : IDL.Int,
    'likes' : IDL.Int,
    'uniqueViews' : IDL.Int,
    'watchTimeRate' : IDL.Float64,
    'subscribers' : IDL.Int,
    'comments' : IDL.Int,
    'totalWatchTimeRate' : IDL.Float64,
  });
  const CommentId__1 = IDL.Text;
  const CommentId = IDL.Text;
  const UserId__1 = IDL.Text;
  const VideoId__1 = IDL.Text;
  const Comment__1 = IDL.Record({
    'id' : CommentId,
    'userId' : UserId__1,
    'when' : IDL.Int,
    'likes' : IDL.Int,
    'comment' : IDL.Text,
    'replies' : IDL.Int,
    'videoId' : VideoId__1,
  });
  const DailyPayments = IDL.Record({
    'count' : IDL.Int,
    'amount' : IDL.Float64,
  });
  const Report = IDL.Record({
    'id' : IDL.Text,
    'userId' : IDL.Text,
    'when' : IDL.Int,
    'reviewedBy' : UserId__1,
    'markedAs' : IDL.Text,
    'reviewed' : IDL.Int,
    'videoId' : VideoId__1,
  });
  const Asset__1 = IDL.Record({ 'url' : IDL.Text, 'category' : IDL.Text });
  const Video = IDL.Record({
    'id' : VideoId__1,
    'thumb' : IDL.Text,
    'title' : IDL.Text,
    'views' : IDL.Int,
    'userId' : UserId__1,
    'desc' : IDL.Text,
    'hash' : IDL.Text,
    'assets' : IDL.Vec(Asset__1),
    'tags' : IDL.Vec(IDL.Text),
    'when' : IDL.Int,
    'likes' : IDL.Int,
    'uniqueViews' : IDL.Int,
    'comments' : IDL.Int,
  });
  const User = IDL.Record({
    'id' : UserId__1,
    'userName' : IDL.Text,
    'displayName' : IDL.Text,
    'profilePhoto' : IDL.Text,
  });
  const VideoAndUser__1 = IDL.Record({
    'verified' : IDL.Bool,
    'video' : Video,
    'user' : User,
    'matches' : IDL.Int,
  });
  const ReportAndVideo = IDL.Record({
    'report' : Report,
    'video' : VideoAndUser__1,
  });
  const UserAndPermissions = IDL.Record({
    'permissions' : IDL.Vec(IDL.Text),
    'user' : User,
  });
  const PaidSubscription = IDL.Record({
    'id' : IDL.Text,
    'userId' : UserId__1,
    'creatorId' : UserId__1,
    'joined' : IDL.Int,
    'amount' : IDL.Float64,
  });
  const UserAndPaidSubscription = IDL.Record({
    'verified' : IDL.Bool,
    'subscription' : PaidSubscription,
    'user' : User,
  });
  const VideoAndUser = IDL.Record({
    'verified' : IDL.Bool,
    'video' : Video,
    'user' : User,
    'matches' : IDL.Int,
  });
  const StateStats = IDL.Record({
    'likeCount' : IDL.Int,
    'videoCount' : IDL.Int,
    'userIdsSize' : IDL.Nat,
    'userNamesSize' : IDL.Nat,
    'usersSize' : IDL.Nat,
    'commentCount' : IDL.Int,
    'userCount' : IDL.Int,
  });
  const SubscriptionPayment = IDL.Record({
    'id' : IDL.Text,
    'when' : IDL.Int,
    'creatorUserId' : UserId__1,
    'subscriberUserId' : UserId__1,
    'amount' : IDL.Float64,
  });
  const TopChannel = IDL.Record({ 'user' : User, 'subscriberCount' : IDL.Int });
  const User__1 = IDL.Record({
    'id' : UserId__1,
    'userName' : IDL.Text,
    'displayName' : IDL.Text,
    'profilePhoto' : IDL.Text,
  });
  const MonitizationSetting__1 = IDL.Record({
    'key' : IDL.Text,
    'value' : IDL.Text,
  });
  const WalletAddress__1 = IDL.Record({
    'id' : IDL.Text,
    'address' : IDL.Text,
  });
  const UserMonitizationSetting = IDL.Record({
    'settings' : IDL.Vec(MonitizationSetting__1),
    'addresses' : IDL.Vec(WalletAddress__1),
    'updated' : IDL.Int,
  });
  const Video__1 = IDL.Record({
    'id' : VideoId__1,
    'thumb' : IDL.Text,
    'title' : IDL.Text,
    'views' : IDL.Int,
    'userId' : UserId__1,
    'desc' : IDL.Text,
    'hash' : IDL.Text,
    'assets' : IDL.Vec(Asset__1),
    'tags' : IDL.Vec(IDL.Text),
    'when' : IDL.Int,
    'likes' : IDL.Int,
    'uniqueViews' : IDL.Int,
    'comments' : IDL.Int,
  });
  const VerifiedAccountStatus = IDL.Record({
    'method' : IDL.Text,
    'verified' : IDL.Bool,
    'userId' : UserId__1,
    'when' : IDL.Int,
  });
  const UserAndVerifiedAccountStatus = IDL.Record({
    'status' : VerifiedAccountStatus,
    'user' : User,
  });
  const Comment = IDL.Record({
    'id' : CommentId,
    'userId' : UserId__1,
    'when' : IDL.Int,
    'likes' : IDL.Int,
    'comment' : IDL.Text,
    'replies' : IDL.Int,
    'videoId' : VideoId__1,
  });
  const CommentInfo = IDL.Record({ 'user' : User, 'comment' : Comment });
  const VideoStats = IDL.Record({
    'ctr' : IDL.Float64,
    'clicks' : IDL.Int,
    'avgViewDuration' : IDL.Float64,
    'shares' : IDL.Int,
    'views' : IDL.Int,
    'watchTime' : IDL.Float64,
    'impressions' : IDL.Int,
    'likes' : IDL.Int,
    'uniqueViews' : IDL.Int,
    'watchTimeRate' : IDL.Float64,
    'comments' : IDL.Int,
    'totalWatchTimeRate' : IDL.Float64,
  });
  const UserPayment = IDL.Record({
    'from' : IDL.Text,
    'when' : IDL.Int,
    'amount' : IDL.Float64,
    'videoId' : VideoId__1,
  });
  const UserAndPayment = IDL.Record({
    'verified' : IDL.Bool,
    'user' : User,
    'payment' : UserPayment,
  });
  const UpdateUserResponse = IDL.Record({
    'status' : IDL.Text,
    'newUserId' : IDL.Text,
  });
  const WalletAddress = IDL.Record({ 'id' : IDL.Text, 'address' : IDL.Text });
  const MonitizationSetting = IDL.Record({
    'key' : IDL.Text,
    'value' : IDL.Text,
  });
  const Asset = IDL.Record({ 'url' : IDL.Text, 'category' : IDL.Text });
  const UploadVideoResponse = IDL.Record({
    'status' : IDL.Text,
    'newVideoId' : IDL.Text,
  });
  const DSocial = IDL.Service({
    'addComment' : IDL.Func(
        [VideoId, IDL.Text, IDL.Text],
        [AddCommentResponse],
        [],
      ),
    'addLike' : IDL.Func([VideoId, IDL.Text], [IDL.Text], []),
    'addPushToken' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'checkUsernameAvailable' : IDL.Func([IDL.Text], [IDL.Bool], ['query']),
    'ci' : IDL.Func([], [IDL.Principal], []),
    'collectCanisterMetrics' : IDL.Func([], [], []),
    'deleteVideo' : IDL.Func([VideoId], [IDL.Text], []),
    'getCallerInfo' : IDL.Func([], [IDL.Principal], ['query']),
    'getCanisterLog' : IDL.Func(
        [IDL.Opt(CanisterLogRequest)],
        [IDL.Opt(CanisterLogResponse)],
        ['query'],
      ),
    'getCanisterMetrics' : IDL.Func(
        [GetMetricsParameters],
        [IDL.Opt(CanisterMetrics)],
        ['query'],
      ),
    'getChannelStats' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(UserId, ChannelStats))],
        ['query'],
      ),
    'getComment' : IDL.Func([CommentId__1], [IDL.Opt(Comment__1)], ['query']),
    'getDailyChannelStats' : IDL.Func(
        [UserId],
        [IDL.Vec(IDL.Tuple(IDL.Text, ChannelStats))],
        ['query'],
      ),
    'getDailyPayments' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, DailyPayments))],
        ['query'],
      ),
    'getDataStats' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Int))],
        ['query'],
      ),
    'getFlaggedVideos' : IDL.Func([], [IDL.Vec(ReportAndVideo)], []),
    'getModerators' : IDL.Func([], [IDL.Vec(UserAndPermissions)], ['query']),
    'getMyPaidSubscriptions' : IDL.Func(
        [],
        [IDL.Vec(UserAndPaidSubscription)],
        [],
      ),
    'getProgress' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, ChannelStats))],
        ['query'],
      ),
    'getRecommended' : IDL.Func(
        [IDL.Nat, IDL.Nat],
        [IDL.Opt(IDL.Vec(VideoAndUser))],
        ['query'],
      ),
    'getRecommendedWithTags' : IDL.Func(
        [IDL.Nat, IDL.Nat, IDL.Vec(IDL.Text), IDL.Bool],
        [IDL.Opt(IDL.Vec(VideoAndUser))],
        ['query'],
      ),
    'getStateStats' : IDL.Func([], [StateStats], ['query']),
    'getSubscriberCount' : IDL.Func([UserId], [IDL.Opt(IDL.Int)], ['query']),
    'getSubscriptionPayments' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, SubscriptionPayment))],
        ['query'],
      ),
    'getSubscriptionPaymentsItems' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, SubscriptionPayment))],
        ['query'],
      ),
    'getSubscriptions' : IDL.Func([], [IDL.Opt(IDL.Vec(UserId))], ['query']),
    'getTopChannels' : IDL.Func([], [IDL.Opt(IDL.Vec(TopChannel))], ['query']),
    'getUser' : IDL.Func([UserId], [IDL.Opt(User__1)], ['query']),
    'getUserData' : IDL.Func([], [IDL.Opt(User__1)], ['query']),
    'getUserId' : IDL.Func([], [UserId], ['query']),
    'getUserIdFromUserName' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(UserId)],
        ['query'],
      ),
    'getUserLikes' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(UserId, IDL.Vec(VideoId)))],
        ['query'],
      ),
    'getUserMonitizationSettings' : IDL.Func(
        [],
        [IDL.Opt(UserMonitizationSetting)],
        ['query'],
      ),
    'getUserMonitizationSettingsById' : IDL.Func(
        [UserId],
        [IDL.Opt(UserMonitizationSetting)],
        ['query'],
      ),
    'getUserMonitizationSettingsList' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(UserId, UserMonitizationSetting))],
        ['query'],
      ),
    'getUserPermissions' : IDL.Func(
        [],
        [IDL.Opt(IDL.Vec(IDL.Text))],
        ['query'],
      ),
    'getUserVideos' : IDL.Func([UserId], [IDL.Vec(Video__1)], ['query']),
    'getUserViews' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(VideoId)))],
        ['query'],
      ),
    'getValue' : IDL.Func([], [IDL.Nat], ['query']),
    'getVerifiedStatus' : IDL.Func([UserId], [IDL.Bool], ['query']),
    'getVerifiedUsers' : IDL.Func(
        [],
        [IDL.Opt(IDL.Vec(UserAndVerifiedAccountStatus))],
        ['query'],
      ),
    'getVideo' : IDL.Func([IDL.Text], [IDL.Opt(Video__1)], ['query']),
    'getVideoComments' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(IDL.Vec(CommentInfo))],
        ['query'],
      ),
    'getVideoRecommendations' : IDL.Func(
        [VideoId, IDL.Text],
        [IDL.Opt(IDL.Vec(VideoAndUser))],
        ['query'],
      ),
    'getVideoStats' : IDL.Func([VideoId], [IDL.Opt(VideoStats)], ['query']),
    'getVideoTips' : IDL.Func([VideoId], [IDL.Vec(UserAndPayment)], ['query']),
    'getVideosWithTagsAndSort' : IDL.Func(
        [IDL.Nat, IDL.Nat, IDL.Vec(IDL.Text), IDL.Bool, IDL.Text, IDL.Int],
        [IDL.Opt(IDL.Vec(VideoAndUser))],
        ['query'],
      ),
    'getViewsChunk' : IDL.Func([IDL.Int], [IDL.Vec(IDL.Int)], ['query']),
    'hasLiked' : IDL.Func([VideoId], [IDL.Bool], ['query']),
    'increment' : IDL.Func([], [IDL.Nat], []),
    'incrementClicks' : IDL.Func([VideoId, IDL.Bool, IDL.Text], [IDL.Text], []),
    'incrementImpressions' : IDL.Func(
        [VideoId, IDL.Bool, IDL.Text],
        [IDL.Text],
        [],
      ),
    'incrementShares' : IDL.Func([VideoId, IDL.Bool, IDL.Text], [IDL.Text], []),
    'incrementTest' : IDL.Func([], [IDL.Bool], []),
    'incrementView' : IDL.Func(
        [VideoId, IDL.Text, IDL.Bool, IDL.Text],
        [IDL.Text],
        [],
      ),
    'incrementWatchTime' : IDL.Func(
        [VideoId, IDL.Bool, IDL.Text, IDL.Float64, IDL.Float64],
        [IDL.Text],
        [],
      ),
    'isPaidSubscriber' : IDL.Func([UserId], [IDL.Bool], ['query']),
    'isSubscribed' : IDL.Func([UserId], [IDL.Bool], ['query']),
    'joinPaidSubscription' : IDL.Func([UserId, IDL.Float64], [IDL.Text], []),
    'optimiseData' : IDL.Func([], [IDL.Int, IDL.Int], []),
    'recordPayment' : IDL.Func(
        [VideoId, IDL.Text, UserId, IDL.Float64],
        [IDL.Text],
        [],
      ),
    'reportVideo' : IDL.Func([VideoId, IDL.Text], [IDL.Text], []),
    'search' : IDL.Func(
        [IDL.Text, IDL.Nat, IDL.Nat],
        [IDL.Opt(IDL.Vec(VideoAndUser))],
        ['query'],
      ),
    'setDailyViews' : IDL.Func([IDL.Text, IDL.Int], [IDL.Bool], []),
    'setUserPermissions' : IDL.Func(
        [IDL.Text, IDL.Vec(IDL.Text)],
        [IDL.Bool],
        [],
      ),
    'setVerifiedStatus' : IDL.Func([IDL.Text], [IDL.Text], []),
    'setVerifiedStatusManual' : IDL.Func([UserId, IDL.Text], [IDL.Text], []),
    'subscribe' : IDL.Func([UserId, IDL.Text], [IDL.Text], []),
    'unsubscribe' : IDL.Func([UserId, IDL.Text], [IDL.Text], []),
    'updateReport' : IDL.Func([IDL.Text, IDL.Text], [IDL.Bool], []),
    'updateTags' : IDL.Func([VideoId, IDL.Vec(IDL.Text)], [IDL.Text], []),
    'updateUser' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [UpdateUserResponse],
        [],
      ),
    'updateUserMonitizationSettings' : IDL.Func(
        [IDL.Vec(WalletAddress), IDL.Vec(MonitizationSetting)],
        [IDL.Text],
        [],
      ),
    'updateVideo' : IDL.Func([VideoId, IDL.Text, IDL.Text], [IDL.Text], []),
    'updateVideoAssets' : IDL.Func(
        [VideoId, IDL.Text, IDL.Text, IDL.Vec(Asset)],
        [IDL.Text],
        [],
      ),
    'uploadVideo' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Vec(Asset)],
        [UploadVideoResponse],
        [],
      ),
    'whoami' : IDL.Func([], [IDL.Principal], []),
  });
  return DSocial;
};
export const init = ({ IDL }) => { return []; };
