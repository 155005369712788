import { useWindowWidth } from '@react-hook/window-size'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import {average,sum} from 'simple-statistics'

import { Loading, Page, Tabs } from '../../components'
import { withAuthCheck } from '../../hoc'
import { useUser } from '../../hooks'
import { getBackend } from '../../lib/icp'
import { ChannelStats } from '../../types/backend/dsocial/dsocial.did'

import styles from './Analytics.module.scss'

const Analytics = () => {
  const screenWidth = useWindowWidth()
  const { userData } = useUser()
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState<string>('impressions')
  const [channelStats, setChannelStats] = useState<[string, ChannelStats][]>()
  const [data, setData] = useState<any[]>([])
  const width = screenWidth - 80
  const height = Math.ceil(width * 0.4)

  const generateDesc = (prop: string) => {
    if (prop === 'ctr') return `average ${average(data.map(item => item.ctr)).toFixed(0)}% click through rate`

    if (prop === 'watchTime') return `${sum(data.map(item => item.watchTime)).toLocaleString()} watch hours`

    if (prop === 'watchTimeRate') return `average ${average(data.map(item => Math.ceil(item.watchTimeRate))).toFixed(0)}% watch rate`

    return `total ${sum(data.map(item => item[prop])).toLocaleString()} ${prop}`
  }

  const generateChart = useCallback((prop: string, percent?: boolean) => {
    return (
      <div>
        <p>
          In last 90 days you got {generateDesc(prop)}.
        </p>
        <div style={{ width, height }}>
          <LineChart width={width} height={height} data={data}>
            {/* <CartesianGrid vertical={false} /> */}
            <XAxis dataKey="date" label="" />
            <YAxis domain={['auto', 'auto']} label="" tickFormatter={(val) => percent ? `${val}%` : val.toLocaleString()} />
            {/* <YAxis dataKey="ctr" orientation="right" /> */}
            <Tooltip
              wrapperStyle={{
                borderColor: 'white',
              }}
              contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
              labelStyle={{ fontWeight: 'bold', color: '#666666' }}
            />

            <Line dataKey={prop} dot={false} stroke="blue" />
          </LineChart>
        </div>
      </div>
    )
  }, [width, height, data])

  useEffect(() => {
    getBackend().then(({ getDailyChannelStats }) => {
      getDailyChannelStats(userData?.id!)
        .then((res) => {
          console.log(res)
          res.sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
          setChannelStats(res)
          setData(
            res
              .filter(([dt]) => new Date(dt).getTime() >= moment().subtract(90, 'days').valueOf())
              .map(
              ([
                dt,
                {
                  ctr,
                  clicks,
                  avgViewDuration,
                  shares,
                  views,
                  watchTime,
                  impressions,
                  likes,
                  uniqueViews,
                  watchTimeRate,
                  comments,
                },
              ]: [string, ChannelStats]) => {    
                return {
                  date: moment(dt).format('LL'),
                  ctr: Math.ceil(Number(ctr) * 100),
                  shares: Number(shares),
                  views: Number(views),
                  watchTime: Math.round(Number(watchTime) * 60),
                  impressions: Number(impressions),
                  likes: Number(likes),
                  uniqueViews: Number(uniqueViews),
                  watchTimeRate: Math.ceil(Number(watchTimeRate) * 100),
                  comments: Number(comments),
                  subscribers: Number(comments),
                }
              },
            ),
          )
          setLoading(false)
        })
        .catch((err) => console.error(err))
    })
  }, [userData?.id])

  if (loading) return <Loading />

  return (
    <Page title={`Analytics | DSocial`}>
      <h1>Analytics</h1>
      
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={[
          {
            id: 'impressions',
            display: 'Impressions',
            content: () => generateChart('impressions'),
          },
          {
            id: 'ctr',
            display: 'Click-through rate',
            content: () => generateChart('ctr', true),
          },
          {
            id: 'views',
            display: 'Views',
            content: () => generateChart('views'),
          },
          {
            id: 'watchTime',
            display: 'Watch Time',
            content: () => generateChart('watchTime'),
          },
          {
            id: 'watchTimeRate',
            display: '% Watched',
            content: () => generateChart('watchTimeRate', true),
          },
          // {
          //   id: 'subscribers',
          //   display: 'Subscribers',
          //   content: () => generateChart('subscribers'),
          // }
        ]}
      />
    </Page>
  )
}

export default withAuthCheck(Analytics)
