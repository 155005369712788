import { createContext, ReactNode, useEffect, useState } from 'react'
import {
  isAuthenticated,
  addAuthChangedListener,
  removeAuthChangedListener,
} from '../../lib/icp'
import { UserPermission } from '../../types'
import { User } from '../../types/backend/dsocial/dsocial.did'

const initialValue: IUserContext = {
  loggedIn: false,
  loading: false,
  userData: null,
  userPermissions: [],
}

export const UserContext = createContext<IUserContext>(initialValue)

export const UserProvider = ({ children }: UserProviderProps) => {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [currentUserData, setUserData] = useState<User | undefined | null>(null)
  const [currentUserPermissions, setUserPermissions] = useState<
    UserPermission[]
  >([])

  useEffect(() => {
    isAuthenticated().then(
      ({ userData, isLoggedIn: isAuthenticatedResult, userPermissions }) => {
        setLoggedIn(!!isAuthenticatedResult)
        setUserPermissions(userPermissions)
        setUserData(userData)
        setLoading(false)
      },
    )

    const authWatcher = (
      updateIsLoggedIn: boolean,
      newUserData?: User | null,
    ) => {
      // window.alert(JSON.stringify(newUserData))
      setLoggedIn(updateIsLoggedIn)
      setUserData(newUserData)
      setLoading(false)
    }

    addAuthChangedListener(authWatcher)

    return () => {
      removeAuthChangedListener(authWatcher)
    }
  }, [])

  const value: IUserContext = {
    loggedIn,
    loading,
    userData: currentUserData,
    userPermissions: currentUserPermissions,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

interface IUserContext {
  loggedIn: boolean
  loading: boolean
  userData?: User | null
  userPermissions: UserPermission[]
}

interface UserProviderProps {
  children: ReactNode
}
