import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../../components'
import { getFeedbackBackend } from '../../lib/icp'
import {
  User,
  UserMonitizationSetting,
} from '../../types/backend/dsocial/dsocial.did'
import { Feedback__1 } from '../../types/backend/feedback/feedback.did'

const FirstPayable = () => {
  const [list, setList] = useState<[string, Feedback__1][]>()
  useEffect(() => {
    const load = async () => {
      const backend = await getFeedbackBackend()
      const feedbackList = await backend.getFeedbackList()

      setList(feedbackList)
    }

    load()
  }, [])
  return (
    <Page title="Feedback">
      <p>{list?.length} items</p>
      <table>
        <tr>
          <td>Type</td>
          <td>Name</td>
          <td>Feedback</td>
        </tr>
        {list?.map(([key, { name, category, feedback, when }]) => (
          <tr key={key}>
            <td>{name}</td>
            <td>{category}</td>
            <td>{moment(Number(when) / 1000000).format('LL HH:mm')}</td>
            <td>{feedback}</td>
          </tr>
        ))}
      </table>
    </Page>
  )
}

export default FirstPayable
