import { useEffect, useState } from 'react'
import { ColorScheme } from '../types'

const stored = window ? window.localStorage.getItem('colorScheme') : ''
const defaultColorScheme =
  stored !== null && typeof stored !== 'undefined' ? parseInt(stored, 10) : 1

export const useColorScheme = (): ColorScheme => {
  const cs = defaultColorScheme as ColorScheme
  const [colorScheme, setColorScheme] = useState<ColorScheme>(cs)

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => {
      const newColorScheme = e.matches ? ColorScheme.Dark : ColorScheme.Light
      setColorScheme(newColorScheme)
    }
    if (window.matchMedia) {
      const mediaWatch = window.matchMedia('(prefers-color-scheme: dark)')

      const value = mediaWatch.matches ? ColorScheme.Dark : ColorScheme.Light
      window.localStorage.setItem('colorScheme', value.toString())
      setColorScheme(value)

      mediaWatch.addEventListener('change', handleChange)

      return () => {
        mediaWatch.removeEventListener('change', handleChange)
      }
    }

    return () => {
      console.log('window.matchMedia not supported')
    }
  }, [])

  return colorScheme
}
