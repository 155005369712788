export const TRANSLATIONS_VI = {
  homePageTitle:
    'Kênh truyền thông xã hội phi tập trung dành cho tất cả mọi người',
  footer: 'Được phát triển bởi <1>@DSocialApp<1>',

  headerMyChannel: 'Kênh của tôi',
  headerUpload: 'Tải lên',
  headerMyAccount: 'Tài khoản của tôi',
  headerSignUpOrLogin: 'Đăng ký / Đăng nhập',

  accountErrorUserNameEmpty: 'Xin hãy nhập tên người dùng',
  accountErrorProfilePhotoFailed:
    'Hình đại diện tải lên thất bại, Xin hãy thử lại sau',
  accountErrorUserNameAlreadyTaken:
    'Tên người dùng đã được sử dụng, Xin hãy sử dụng một tên khác',
  accountError: 'Một lỗi đã xảy ra, Xin hãy thử lại',
  accountErrorValidImage: 'Vui lòng sử dụng các tệp JPG/PNG khả dụng',
  accountErrorFailedResize:
    'Không thay đổi kích thước của ảnh được, vui lòng thử lại',
  accountPageTitle: 'Tài khoản của tôi',
  accountUpdated: 'Tài khoản của bạn đã được cập nhập',
  accountUserNameLabel: 'Tên người dùng',
  accountChannelNameLabel: 'Tên kênh',
  accountProfilePhotoLabel: 'Ảnh đại diện',
  accountResizingPhoto: 'Đang thay đổi kích thước ảnh...',
  accountDone: 'Hoàn tất',
  accountCancel: 'Hủy bỏ',
  accountLoadingPhoto: 'Đang tải ảnh...',
  accountChoosePhoto: 'Chọn ảnh',
  accountUploadingPhoto: 'Đang tải ảnh đại diện...',
  accountUpdatingAccountDetails: 'Đang cập nhập thông tin tài khoản...',
  accountUpdateAccountDetails: 'Cập nhập thông tin tài khoản',
  accountLogout: 'Đăng xuất',

  channelFailedToLoad: 'Tải thông tin kênh thất bại, vui lòng tải lại trang',
  channelFailedToLoadVideos: 'Tải video thất bại, vui lòng tải lại trang',
  subscribers: '{{number}} người đăng ký',
  subscribersK: '{{number}}K người đăng ký',
  subscribersM: '{{number}}M người đăng ký',
  subscribersNone: 'Chưa có người đăng ký',
  subscribe: 'Đăng ký',
  subscribed: 'Đã đăng ký',
  channelNoVideos: 'Chưa có video nào được tải lên.',

  views: '{{number}} lượt xem',
  viewsK: '{{number}}K lượt xem',
  viewsM: '{{number}}M lượt xem',
  viewsNone: 'Chưa có lượt xem nào',

  loginPageTitle: 'Đăng nhập/Đăng ký',
  loginIfNotRegistered: 'Nếu bạn chưa đăng ký tài khoản với DSocial:',
  loginSignUpButton: 'Đăng ký với Internet Identity',
  loginIfRegistered: 'Nếu bạn đã đăng ký tài khoản với DSocial:',
  loginButton: 'Đăng nhập với Internet Identity',

  uploadDropHere: 'Kéo thả ở đây...',
  uploadFailedResize:
    'Không thay đổi kích thước của ảnh được, vui lòng thử lại',
  uploadErrorSelectThumbnail: 'Vui lòng tải ảnh bìa (JPG/PNG, 1280x720)',
  uploadErrorTransactionId: 'Vui lòng nhập video ID',
  uploadErrorVideoFile:
    'Vui lòng chọn một video (chỉ khả dụng với MP4, tối đa 1080p)',
  uploadErrorFailedUpload: 'Không tải được ảnh bìa, vui lòng thử lại...',
  uploadErrorVideoFailedUpload: 'Không tải được video, vui lòng thử lại...',
  uploadPageTitle: 'Tải video của bạn',
  uploadInstallArConnect:
    'Vui lòng cài đặt bộ mở rộng ArConnect Extension. Vui lòng đọc hướng dẫn tại mục <1>Bắt đầu</1>.',
  uploadFirstTime:
    'Nếu đây là lần đầu bạn tải video lên DSocial, Vui lòng đọc hướng dẫn tại mục <1>Bắt đầu</1>.',
  uploadThumbnailUploaded: 'Ảnh bìa đã được tải lên',
  uploadThumbnailUploading:
    'Ảnh bìa đang được tải lên, {{progress}}% hoàn thành...',
  uploadVideoUploaded: 'Video đã được tải lên',
  uploadVideoUploading: 'Video đang được tải lên, {{progress}}% hoàn thành...',
  uploadVideoCreated: 'Video đã được khởi tạo',
  uploadVideoCreating: 'Đang tạo hồ sơ video...',
  uploadSelectThumbnail: 'Chọn ảnh bìa:',
  uploadResizingPhoto: 'Đang thay đổi kích thước ảnh...',
  uploadDone: 'Hoàn tất',
  uploadCancel: 'Hủy',
  uploadDropLabel: 'Kéo thả ảnh bìa tại đây (kích thước lý tưởng 1280x720)...',
  uploadSelectThumbnailError: 'Vui lòng chọn các tệp JPG/PNG làm ảnh bìa',
  uploadYourVideoWith:
    'Tải video lên với <1>App tải lên của DSocial</1>, khi đã tải lên thành công, hãy sao chép mã video ID và dán vào bên dưới.',
  uploadSelectVideo: 'Chọn video (Chỉ khả dụng với MP4, tối đa 1080p):',
  uploadPlaceholderTransactionId: 'Dán mã video ID của bạn tại đây...',
  uploadDropVideo:
    'Kéo thả video của bạn tại đây (Chỉ khả dụng với MP4, tối đa 1080p)...',
  uploadErrorDropVideo:
    'Vui lòng chọn video (Chỉ khả dụng với MP4, tối đa 1080p)',
  uploadEnterTitle: 'Nhập tựa đề cho video:',
  uploadPlaceholderTitle: 'Nhập tựa đề cho video...',
  uploadEnterVideoDesc: 'Nhập mô tả cho video (tùy chọn):',
  uploadPlaceholderDesc: 'Nhập mô tả...',
  uploadButton: 'Tải lên',
  uploadFileTooBig: 'Tải lên video được giới hạn trong 2GB',

  video1Like: '1 lượt thích',
  videoLikes: '{{likes}} lượt thích',
  videoLike: 'Lượt thích',
  videoComments: '{{comments}} bình luận',
  videoAddPublicComment: 'Thêm một bình luận công khai...',
  videoCancelButton: 'Hủy',
  videoSubmitButton: 'Bình luận',

  notFoundError: 'Có lỗi xảy ra',
  notFoundTitle: 'Không tìm thấy trang',
  notFoundContent: 'Không tìm thấy trang này. Trở lại <1>Trang chủ</1>.',

  topTitle: 'Các kênh hàng đầu',

  searchPlaceholder: 'Tìm kiếm video ...',

  sorting_recommended: 'Đăng ký',
  sorting_hot: 'Nóng',
  sorting_most_viewed: 'Xem nhiều nhất',
  sorting_top_rated: 'Xếp hạng hàng đầu',
  sorting_newest: 'Mới nhất',
  sorting_daily: 'Hằng ngày',
  sorting_weekly: 'Hàng tuần',
  sorting_monthly: 'Hàng tháng',
  sorting_yearly: 'Hàng năm',
  sorting_all_time: 'Tất cả thời gian',

  feedbackSendFeedback: 'Gửi phản hồi',
  feedbackCloseForm: 'Hình thức gần gũi',
  feedbackFeedbackType: 'Gõ phím',
  feedbackName: 'Tên',
  feedback: 'Phản hồi',
  feedbackPlaceholder: 'Nhập phản hồi của bạn vào đây...',
  feedbackSendFeedbackFormButton: 'Gửi phản hồi 👋',
  feedbackFeedbackType_general: 'Chung',
  feedbackFeedbackType_bug: 'Sâu bọ',
  feedbackFeedbackType_idea: 'Ý tưởng',
  feedbackSubmitted: 'Cảm ơn bạn!Phản hồi được gửi',

  sideMenuHome: 'Nhà',
  sideMenuUpload: 'Tải lên',
  sideMenuMyChannel: 'Kênh của tôi',
  sideMenuMyAccount: 'Tài khoản của tôi',
  sideMenuLoginOrRegister: 'Đăng nhập',
  sideMenuOpen: 'Mở',
  sideMenuClose: 'Đóng',
  sideMenuReviewFlagged: 'Xem lại nội dung được gắn cờ',
  sideMenuLogout: 'Đăng xuất',
}
