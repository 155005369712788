import { Link, useHistory } from 'react-router-dom'
import slugify from 'slugify'
import { LazyImage } from 'react-lazy-images'
import { Player, ControlBar } from 'video-react'
import { useWindowWidth } from '@react-hook/window-size'
import useMobileDetect from 'use-mobile-detect-hook'

import { ASSET_BASE_URL } from '../../config'
import ago from '../../lib/ago'
import renderNumbers from '../../lib/numbers'
import generateVideoUrl from '../../lib/generateVideoUrl'
import generateProfilePhotoUrl from '../../lib/profilePhoto'
import pages, { generateUrl } from '../../pages'
import { User, Video } from '../../types/backend/dsocial/dsocial.did'
import { ReactComponent as Verified } from '../../assets/verified.svg'

import generateThumbnailUrl from '../../lib/generateThumbnailUrl'
import { useTranslation } from '../../hooks'
import CachedImage from '../CachedImage'

import styles from './VideoBoxHome.module.scss'
import convertDuration from '../../lib/duration'
import { useCallback, useState } from 'react'
import HlsSource from '../HlsSource'
import TrackImpression from '../TrackImpression'
import trackClick from '../../lib/trackClick'
import { Tags } from '../../config/tags'
import Button from '../Button'

const SourceTag = (props: any) => <source src={props.src} />

const MiniVideo = ({ video }: { video: Video }) => {
  const screenWidth = useWindowWidth()
  const thumbUrl = generateThumbnailUrl(video)
  const videoUrl = generateVideoUrl(video)
  const hlsEnabled = video.hash === 'ar'
  const Source = hlsEnabled ? HlsSource : SourceTag
  const width = Math.floor(screenWidth * 0.22959375)
  const height = Math.floor(width * 0.560534912209065)

  return (
    <Player
      playsInline
      poster={thumbUrl}
      className={styles.video}
      width={width}
      height={height}
      autoPlay
      loop
      fluid={false}
    >
      <Source
        src={videoUrl}
        // @ts-ignore
        isVideoChild
        autoPlay
      />
      {/* <ControlBar disableDefaultControls disableCompletely /> */}
    </Player>
  )
}

const useStyles = () => {
  const screenWidth = useWindowWidth()
  const padding = 16 * 2
  let wrapperWidth = 0
  let imageWidth = 0
  const ratio = 9 / 16

  wrapperWidth = screenWidth * 0.254

  if (screenWidth <= 500) {
    wrapperWidth = screenWidth
    imageWidth = screenWidth
  } else if (screenWidth <= 770) {
    wrapperWidth = screenWidth * 0.48
  } else if (screenWidth <= 900) {
    wrapperWidth = screenWidth * 0.31
  } else if (screenWidth <= 1000) {
    wrapperWidth = screenWidth * 0.32
  } else if (screenWidth <= 1200) {
    wrapperWidth = screenWidth * 0.33
  }

  if (imageWidth <= 0) imageWidth = wrapperWidth - padding

  return {
    wrapperStyles: { maxWidth: wrapperWidth },
    imageStyles: { width: imageWidth, height: imageWidth * ratio },
  }
}

const tagsList = [
  Tags.Approved,
  Tags.Nsfw,
  Tags.CopyrightedContent,
  Tags.Illegal,
]

const tagColors: { [key: string]: string } = {
  [Tags.Approved]: 'rgb(48, 209, 88)',
}

const VideoBoxHome = ({
  video,
  user: { displayName, userName, profilePhoto },
  hideChannelInfo,
  verified,
  onVideoMouseOver,
  onVideoMouseOut,
  preview,
  approvalMode,
  updateTag,
  edit,
}: VideoBoxHomeProps) => {
  const detect = useMobileDetect()
  const { t } = useTranslation()
  const history = useHistory()
  const { id, title, hash, views, when, assets, tags } = video
  const videoLengthRecord =
    assets && assets.find(({ category }) => category === 'length')
  const duration =
    videoLengthRecord &&
    videoLengthRecord.url &&
    Number(videoLengthRecord.url) > 0
      ? Math.ceil(Number(videoLengthRecord.url))
      : 0
  const { wrapperStyles, imageStyles } = useStyles()
  const flagged = tags.filter(
    (tag) => [Tags.CopyrightedContent, Tags.Illegal, Tags.Nsfw].some(prohibited => tag === prohibited),
  )

  return (
    <TrackImpression
      videoId={id}
      className={styles.videoBoxWrapper}
      style={wrapperStyles}
    >
      <Link
        to={generateUrl(pages.video.path, {
          videoId: id,
          videoSlug: slugify(title, { lower: true, strict: true }),
        })}
        className={styles.videoBoxHome}
        onMouseOver={() => onVideoMouseOver && onVideoMouseOver(id)}
        onMouseOut={() => onVideoMouseOut && onVideoMouseOut(id)}
        title={title}
        onClick={() => trackClick(id)}
      >
        <div className={styles.thumb}>
          {!detect.isMobile() && preview ? (
            <MiniVideo video={video} />
          ) : (
            <>
              {flagged.length > 0 ? (
                <div className={styles.placeholder} style={imageStyles}>
                  Flagged as "{flagged[0]}"
                </div>
              ) : (
                <LazyImage
                  src={generateThumbnailUrl(video)}
                  alt={title}
                  placeholder={({ ref }) => (
                    <div
                      ref={ref}
                      className={styles.placeholder}
                      style={imageStyles}
                    />
                  )}
                  actual={({ imageProps }) => (
                    <img {...imageProps} style={imageStyles} />
                  )}
                />
              )}
              {duration ? (
                <span className={styles.duration}>
                  {convertDuration(duration)}
                </span>
              ) : null}
            </>
          )}
        </div>
        {approvalMode && (
          <div className={styles.approvalTools}>
            {tagsList.map((tag) => (
              <>
                <Button
                  small
                  backgroundColor={tagColors[tag] || 'red'}
                  onClick={(e) => {
                    console.log('tag hit', tag)
                    e.preventDefault()
                    updateTag && updateTag(id, tag)
                  }}
                >
                  {tag}
                </Button>
              </>
            ))}
          </div>
        )}
        {edit && (
          <div className={styles.approvalTools}>
           <Button
              small
              onClick={(e) => {
                e.preventDefault()
                history.push(generateUrl(pages.editVideo.path, { videoId: id }))
              }}
            >
              Edit
            </Button>
          </div>
        )}
        <div className={styles.info}>
          {!hideChannelInfo && (
            <div className={styles.left}>
              <Link to={generateUrl(pages.channel.path, { userName })}>
                <LazyImage
                  src={generateProfilePhotoUrl(profilePhoto)}
                  alt={displayName || userName}
                  placeholder={({ ref }) => (
                    <div ref={ref} className={styles.profilePlaceholder} />
                  )}
                  actual={({ imageProps }) => <CachedImage {...imageProps} />}
                />
              </Link>
            </div>
          )}
          <div className={styles.right}>
            <h3>{title}</h3>
            {!hideChannelInfo && (
              <p>
                <Link to={generateUrl(pages.channel.path, { userName })}>
                  {displayName || userName}
                  {verified && <Verified className={styles.verified} />}
                </Link>
              </p>
            )}
            <p className={styles.viewsAndWhen}>
              <span className={styles.views}>
                {renderNumbers(views, 'views', t)}
              </span>
              <span className={styles.seperator}>{'\u2B24'}</span>
              <span className={styles.ago}>{ago(when)}</span>
            </p>
          </div>
        </div>
      </Link>
    </TrackImpression>
  )
}

interface VideoBoxHomeProps {
  video: Video
  user: User
  hideChannelInfo?: boolean
  verified?: boolean
  onVideoMouseOver?: (videoId: string) => void
  onVideoMouseOut?: (videoId: string) => void
  preview?: boolean
  approvalMode?: boolean
  updateTag?: (videoId: string, tag: string) => void
  edit?: boolean
}

export default VideoBoxHome
