import { useEffect, useCallback, useState, FormEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import slugify from 'slugify'
import { FiExternalLink } from 'react-icons/fi'
import moment from 'moment'

import pages, { generateUrl } from '../../pages'
import { Button, Loading, Page, Tabs } from '../../components'
import { getBackend } from '../../lib/icp'
import {
  ReportAndVideo,
  UserAndPermissions,
} from '../../types/backend/dsocial/dsocial.did'
import { Tags } from '../../config/tags'
import { useUser } from '../../hooks'
import { UserPermission } from '../../types'
import { withAuthCheck } from '../../hoc'

const tagsList = [
  Tags.Approved,
  Tags.Nsfw,
  Tags.CopyrightedContent,
  Tags.Illegal,
]
const tagColors: { [key: string]: string } = {
  [Tags.Approved]: 'rgb(48, 209, 88)',
}

const ContentModeration = () => {
  const history = useHistory()
  const [loadingFlagged, setLoadingFlagged] = useState(true)
  const [loadingModerators, setLoadingModerators] = useState(true)
  const { loading: loadingUser, loggedIn, userPermissions } = useUser()
  const [videos, setVideos] = useState<ReportAndVideo[]>([])
  const [moderators, setModerators] = useState<UserAndPermissions[]>([])
  const [selectedTab, setSelectedTab] = useState<string>('content-mod')
  const [updatingPerms, setUpdatingPerms] = useState<{
    [userId: string]: boolean
  }>({})
  const [newModeratorUserName, setNewModeratorUserName] = useState('')
  const [newModeratorPerms, setNewModeratorPerms] = useState<
    UserPermission | null | undefined
  >()
  const [error, setError] = useState('')
  const [addingModerator, setAddingModerator] = useState(false)

  const loadUsers = useCallback(async () => {
    const backend = await getBackend()
    const mods = await backend.getModerators()
    setModerators(
      mods.filter(({ permissions }) =>
        permissions.every((perm) => perm !== UserPermission.Owner),
      ),
    )
    setLoadingModerators(false)
  }, [setModerators, setLoadingModerators])

  useEffect(() => {
    if (!loadingUser && loggedIn && userPermissions) {
      if (
        userPermissions.indexOf(UserPermission.Admin) !== -1 ||
        userPermissions.indexOf(UserPermission.Owner) !== -1 ||
        userPermissions.indexOf(UserPermission.Moderator) !== -1
      ) {
        const loadFlagged = async () => {
          const backend = await getBackend()
          const flagged = await backend.getFlaggedVideos()
          setVideos(flagged)
          setLoadingFlagged(false)
        }
        loadFlagged()
        loadUsers()
      } else {
        history.replace('/')
      }
    }
  }, [loadingUser, loggedIn, userPermissions])

  const updateTag = useCallback(
    async (reportId: string, videoId: string, tag: Tags) => {
      const success = window.confirm(
        `Are you sure this video should be marked as ${tag}? This cannot be reversed`,
      )

      if (success) {
        const newVideosList = [...videos]
        newVideosList.splice(
          newVideosList.findIndex(
            ({ video: { video } }) => video.id === videoId,
          ),
          1,
        )
        setVideos(newVideosList)

        const backend = await getBackend()
        const start = Date.now()
        const result = await backend.updateTags(videoId, [Tags.Reviewed, tag])
        console.log(
          `updateTags(${videoId}, ${tag}) took ${Date.now() - start}ms`,
          result,
        )
        const reportResult = await backend.updateReport(reportId, tag)
        console.log(
          `updateReport(${reportId}, ${tag}) took ${Date.now() - start}ms`,
          reportResult,
        )
      }
    },
    [setVideos, videos],
  )

  const setPermission = useCallback(
    (userId: string, newPermission: UserPermission) => {
      const idx = moderators.findIndex(({ user: { id } }) => id === userId)

      if (idx !== -1) {
        const newModerators = [...moderators]
        newModerators[idx] = {
          ...newModerators[idx],
          permissions: [newPermission],
        }
        setModerators(newModerators)
      }
    },
    [setModerators, moderators],
  )

  const deleteMod = useCallback(
    async (userId) => {
      const success = window.confirm(
        'Are you sure you want to remove this moderator?',
      )

      if (success) {
        setLoadingModerators(true)
        await updatePerms(userId)
        await loadUsers()
      }
    },
    [setUpdatingPerms, updatingPerms],
  )

  const updatePerms = useCallback(
    async (userId: string, newPermission?: UserPermission) => {
      setUpdatingPerms({
        ...updatingPerms,
        [userId]: true,
      })

      const backend = await getBackend()
      const success = await backend.setUserPermissions(
        userId,
        newPermission ? [newPermission] : [],
      )
      console.log('setUserPermissions', success)

      setUpdatingPerms({
        ...updatingPerms,
        [userId]: false,
      })
    },
    [setUpdatingPerms, updatingPerms],
  )

  const submitModeratorForm = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      if (!newModeratorUserName || !newModeratorUserName.trim()) {
        setError('Enter a username')
        return
      }

      if (
        newModeratorPerms !== UserPermission.Admin &&
        newModeratorPerms !== UserPermission.Moderator
      ) {
        setError('Set a valid permission')
        return
      }

      setAddingModerator(true)

      try {
        const backend = await getBackend()
        console.log('newModeratorUserName', newModeratorUserName)
        const userIdResults = await backend.getUserIdFromUserName(
          newModeratorUserName,
        )
        console.log('userIdResults', userIdResults)

        if (
          !userIdResults ||
          userIdResults.length === 0 ||
          userIdResults[0].length === 0
        ) {
          setError('Username is not found, try again')
          setAddingModerator(false)
          return
        }

        const userId = userIdResults && userIdResults[0]
        console.log('userId', userId)

        const user = await backend.getUser(userId)
        console.log('user', user)

        const success = await backend.setUserPermissions(userId, [
          newModeratorPerms,
        ])
        console.log('setUserPermissions', success)

        setNewModeratorUserName('')
        setNewModeratorPerms(UserPermission.Moderator)

        await loadUsers()
      } catch (e) {
        // @ts-ignore
        setError(e.message)
        console.log(e)
      }

      setAddingModerator(false)
    },
    [
      setAddingModerator,
      setError,
      newModeratorUserName,
      setNewModeratorUserName,
      newModeratorPerms,
      setNewModeratorPerms,
    ],
  )

  return (
    <Page title="Content Moderation">
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={[
          {
            id: 'content-mod',
            display: 'contentModerationFlaggedContent',
            content: () =>
              loadingFlagged ? (
                <Loading />
              ) : (
                <>
                  {videos.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <td>Video</td>
                          <td>Flagged</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {videos.map(
                          ({
                            video: {
                              video: { id: videoId, title },
                            },
                            report: { id: reportId, when },
                          }) => (
                            <tr key={reportId}>
                              <td>
                                <Link
                                  to={generateUrl(pages.video.path, {
                                    videoId,
                                    videoSlug: slugify(title, {
                                      lower: true,
                                      strict: true,
                                    }),
                                  })}
                                  target="_blank"
                                >
                                  {title}
                                  <FiExternalLink />
                                </Link>
                              </td>
                              <td>
                                {moment(Number(when) / 1000000).format(
                                  'LL HH:mm',
                                )}
                              </td>
                              <td>
                                {tagsList.map((tag) => (
                                  <Button
                                    small
                                    backgroundColor={tagColors[tag] || 'red'}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      updateTag(reportId, videoId, tag)
                                    }}
                                  >
                                    {tag}
                                  </Button>
                                ))}
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p>No videos to check right now. Come back later.</p>
                  )}
                </>
              ),
          },
          {
            id: 'user-mgmt',
            display: 'contentModerationUserManagement',
            content: () =>
              loadingModerators ? (
                <Loading />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <td>User</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {moderators.map(({ user, permissions }) => (
                      <tr key={user.id}>
                        <td>{user.userName}</td>
                        <td>
                          <select
                            onChange={(e) =>
                              setPermission(
                                user.id,
                                e.target.value as UserPermission,
                              )
                            }
                            value={permissions[0]}
                            disabled={updatingPerms[user.id]}
                          >
                            <option value={UserPermission.Admin}>Admin</option>
                            <option value={UserPermission.Moderator}>
                              Moderator
                            </option>
                          </select>
                          <Button
                            small
                            onClick={() =>
                              updatePerms(
                                user.id,
                                permissions[0] as UserPermission,
                              )
                            }
                            loading={updatingPerms[user.id]}
                          >
                            Update
                          </Button>
                          <Button
                            backgroundColor="red"
                            small
                            onClick={() => deleteMod(user.id)}
                            loading={updatingPerms[user.id]}
                          >
                            Delete Moderator
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2}>
                        <p>
                          <strong>Add new moderator or admin:</strong>
                        </p>
                        <p>
                          <form onSubmit={submitModeratorForm}>
                            {error && (
                              <div style={{ color: 'red' }}>Error: {error}</div>
                            )}
                            <input
                              type="text"
                              placeholder="Enter username..."
                              disabled={addingModerator}
                              value={newModeratorUserName}
                              onChange={(e) => {
                                setError('')
                                setNewModeratorUserName(e.target.value)
                              }}
                            />
                            <select
                              onChange={(e) =>
                                setNewModeratorPerms(
                                  e.target.value as UserPermission,
                                )
                              }
                              value={
                                newModeratorPerms
                                  ? newModeratorPerms.toString()
                                  : ''
                              }
                              disabled={addingModerator}
                            >
                              <option value={UserPermission.Admin}>
                                {UserPermission.Admin}
                              </option>
                              <option value={UserPermission.Moderator}>
                                {UserPermission.Moderator}
                              </option>
                            </select>
                            <Button small type="submit">
                              Add
                            </Button>
                          </form>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ),
            shouldShow: () =>
              !!userPermissions &&
              (userPermissions.indexOf(UserPermission.Admin) !== -1 ||
                userPermissions.indexOf(UserPermission.Owner) !== -1),
          },
        ]}
      />
    </Page>
  )
}

export default withAuthCheck(ContentModeration)
