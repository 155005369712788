import { useHistory } from 'react-router-dom'

import { Button, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import pages from '..'
import { login } from '../../lib/icp'
import { useTranslation } from '../../hooks'

const Register = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const signUp = () => {
    login(() => {
      history.push(pages.account.path)
    })
  }

  return (
    <Page title={`${t('loginPageTitle')} | DSocial`}>
      <h1>{t('loginPageTitle')}</h1>

      <p>{t('loginIfNotRegistered')}</p>

      <p>
        <Button onClick={signUp} secondary>
          {t('loginSignUpButton')}
        </Button>
      </p>

      <p>{t('loginIfRegistered')}</p>

      <p>
        <Button onClick={signUp}>{t('loginButton')}</Button>
      </p>
    </Page>
  )
}

export default withAuthCheck(Register, true)
