import { ActorSubclass } from '@dfinity/agent'
import { AuthClient } from '@dfinity/auth-client'
import { Usergeek } from 'usergeek-ic-js'

import { getBackendActor, getFeedbackBackendActor } from './agent'
import { IDENTITY_PROVIDER_URL } from '../config'
import { DSocial, User } from '../types/backend/dsocial/dsocial.did'
import { UserPermission } from '../types'

let loadAuthClientPromise: Promise<void> | null = null
let authClient: AuthClient | null | undefined = null

let loadBackendActorPromise: Promise<ActorSubclass<DSocial>> | null = null
let backend: ActorSubclass<DSocial> | null = null

export const loadAuthClient = () => {
  if (!loadAuthClientPromise) {
    loadAuthClientPromise = new Promise(async (resolve) => {
      authClient = await AuthClient.create()
      resolve()
    })
  }

  return loadAuthClientPromise
}

export const getAuthClient = () => authClient

export const loadBackendActor = () => {
  if (!loadBackendActorPromise) {
    loadBackendActorPromise = new Promise(async (resolve) => {
      // window.alert('load auth')
      await loadAuthClient()
      // window.alert('load backend')
      backend = await getBackendActor(authClient!)
      // window.alert('loaded')
      resolve(backend)
    })
  }

  return loadBackendActorPromise
}

loadAuthClient()
loadBackendActor()

export const isAuthenticated = async () => {
  await loadAuthClient()
  const isLoggedIn = await authClient?.isAuthenticated()
  const userData = await getUserData()
  let userPermissions: UserPermission[] = []

  if (isLoggedIn) {
    const principal = authClient?.getIdentity().getPrincipal()

    if (principal && !principal.isAnonymous()) {
      const backend = await getBackend()
      const userPermissionsResult = await backend.getUserPermissions()
      console.log('userPermissionsResult', userPermissionsResult)
      if (
        userPermissionsResult &&
        userPermissionsResult.length > 0 &&
        userPermissionsResult[0]
      ) {
        const [rawUserPermissions] = userPermissionsResult
        userPermissions = rawUserPermissions.map(
          (perm) => perm as UserPermission,
        )
        console.log('userPermissions', userPermissions)
      }
      Usergeek.setPrincipal(principal)
      Usergeek.trackSession()
    }
  } else {
    // @ts-ignore
    Usergeek.setPrincipal(null)
  }

  return { isLoggedIn, userData, userPermissions }
}

type Callback = (isLoggedIn: boolean, userData?: User | null) => void
const callbacks: Callback[] = []

export const addAuthChangedListener = (callback: Callback) => {
  if (callbacks.indexOf(callback) === -1) {
    callbacks.push(callback)
  }
}

export const removeAuthChangedListener = (callback: Callback) => {
  const index = callbacks.indexOf(callback)
  if (index !== -1) {
    callbacks.splice(index, 1)
  }
}

export const updateAuthChangedListeners = (
  isLoggedIn: boolean,
  userData?: User | null,
) => {
  callbacks.forEach((callback) => {
    try {
      callback(isLoggedIn, userData)
    } catch (e) {
      // do nothing
    }
  })
}

let clientId: string | null | undefined = ''
const CLIENT_ID_KEY = 'dsocial:client:id'

export const getClientId = () => {
  if (!clientId) {
    clientId = window.localStorage.getItem(CLIENT_ID_KEY)

    if (!clientId) {
      clientId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        (c) => {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        },
      )
      window.localStorage.setItem(CLIENT_ID_KEY, clientId)
    }
  }

  return clientId
}

export const login = async (onSuccess: () => void) => {
  await loadAuthClient()
  const daysToAdd = 31
  const expiry = Date.now() + daysToAdd * 86400000
  await authClient?.login({
    onSuccess: async () => {
      window.location.href = '/'
    },
    identityProvider: IDENTITY_PROVIDER_URL,
    maxTimeToLive: BigInt(expiry * 1000000),
  })
}

export const getBackend = async () => await loadBackendActor()
export const getFeedbackBackend = async () => {
  await loadAuthClient()
  return await getFeedbackBackendActor(authClient!)
}

export const getUserData = async () => {
  const backend = await getBackend()
  const userResults = await backend.getUserData()
  return userResults && userResults.length > 0 ? userResults[0] : null
}

export const logout = async () => {
  await loadAuthClient()
  await authClient?.logout({ returnTo: '/' })
}
