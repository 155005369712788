import moment from 'moment'
import { useEffect, useState } from 'react'

import { Loading, Page } from '../../components'
import { withAuthCheck } from '../../hoc'
import { getBackend } from '../../lib/icp'
import { UserAndPaidSubscription } from '../../types/backend/dsocial/dsocial.did'

import styles from './PaidSubscriptions.module.scss'

const PaidSubscriptions = () => {
  const [loading, setLoading] = useState(true)
  const [subs, setSubs] = useState<UserAndPaidSubscription[]>([])
  useEffect(() => {
    getBackend().then(({ getMyPaidSubscriptions }) => {
      getMyPaidSubscriptions()
        .then((results) => {
          console.warn('getMyPaidSubscriptions result', results)
          setSubs(results)
          setLoading(false)
        })
        .catch((err) => {
          console.warn('error: getMyPaidSubscriptions', err)
        })
    })
  }, [])

  if (loading) return <Loading />

  return (
    <Page title={`Paid Subscriptions | DSocial`}>
      <h1>Paid Subscriptions</h1>

      {subs.length === 0 && <p>You don't have any paid subscriptions yet.</p>}

      <div className={styles.paidSubs}>
        {subs.map((rec, i) => (
          <div className={styles.sub}>
            <h2>{rec.user.displayName || rec.user.userName}</h2>
            <p>Joined: {moment(Number(rec.subscription.joined) / 1000000).format('LL')}</p>
            <p>${rec.subscription.amount.toFixed(2)}/month</p>
          </div>
        ))}
      </div>
    </Page>
  )
}

export default withAuthCheck(PaidSubscriptions)
