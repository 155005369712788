import { ComponentType } from 'react'

import Home from './Home'
import Logout from './logout'
import Login from './Login'
import Account from './Account'
import Upload from './Upload'
import Video from './Video'
import Channel from './Channel'
import GettingStarted from './GettingStarted'
import UploaderApp from './UploaderApp'
import Download from './Download'
import Top from './Top'
import Migrate from './Migrate'
import GetVerified from './GetVerified'
import SetVerifiedStatus from './SetVerifiedStatus'
import ThankYou from './ThankYou'
import Search from './Search'
import Progress from './Progress'
import Subscriptions from './Subscriptions'
import Rpc from './Rpc'
import MobileAuth from './MobileAuth'
import Privacy from './Privacy'
import FirstPayable from './FirstPayable'
import Feedback from './Feedback'
import ContentModeration from './ContentModeration'
import Admin from './Admin'
import Monetisation from './Monetisation'
import PaidSubscriptions from './PaidSubscriptions'
import Analytics from './Analytics'
import EditVideo from './EditVideo'

const pages: {
  [key: string]: InternalRoute
} = {
  login: {
    path: '/login',
    component: Login,
  },
  account: {
    path: '/account',
    component: Account,
  },
  thankYou: {
    path: '/thank-you',
    component: ThankYou,
  },
  upload: {
    path: '/upload',
    component: Upload,
  },
  channel: {
    path: '/@:userName',
    component: Channel,
  },
  gettingStarted: {
    path: '/getting-started',
    component: GettingStarted,
  },
  uploaderApp: {
    path: '/uploader-app',
    component: UploaderApp,
  },
  logout: {
    path: '/logout',
    component: Logout,
  },
  top: {
    path: '/top',
    component: Top,
  },
  contentModeration: {
    path: '/content-moderation',
    component: ContentModeration,
  },
  admin: {
    path: '/admin',
    component: Admin,
  },
  setVerifiedStatus: {
    path: '/set-verified-manual',
    component: SetVerifiedStatus,
  },
  verified: {
    path: '/verify',
    component: GetVerified,
  },
  migrate: {
    path: '/migrate',
    component: Migrate,
  },
  download: {
    path: '/download/:os',
    component: Download,
  },
  embed: {
    path: '/embed/:videoId',
    component: Video,
  },
  editVideo: {
    path: '/edit-video/:videoId',
    component: EditVideo,
  },
  search: {
    path: '/search',
    component: Search,
  },
  progress: {
    path: '/progress',
    component: Progress,
  },
  subscriptions: {
    path: '/subscriptions',
    component: Subscriptions,
  },
  paidSubscriptions: {
    path: '/paid-subscriptions',
    component: PaidSubscriptions,
  },
  analytics: {
    path: '/analytics',
    component: Analytics,
  },
  monetisation: {
    path: '/monetisation',
    component: Monetisation,
  },
  rpc: {
    path: '/rpc',
    component: Rpc,
  },
  mobileAuth: {
    path: '/mobile-auth',
    component: MobileAuth,
  },
  privacy: {
    path: '/privacy',
    component: Privacy,
  },
  firstPayable: {
    path: '/first-payable',
    component: FirstPayable,
  },
  feedback: {
    path: '/feedback',
    component: Feedback,
  },
  video: {
    path: '/:videoId/:videoSlug?',
    component: Video,
  },
  home: {
    path: '/',
    component: Home,
  },
}

interface InternalRoute {
  path: string
  component: ComponentType
}

export const generateUrl = <T>(path: string, params: T) => {
  let newPath = path

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(`:${key}?`, value).replace(`:${key}`, value)
  })

  return newPath
}

export default pages
