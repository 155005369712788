import { getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import * as Sentry from '@sentry/react'

import { getRef } from './db'

export const uploadToCloud = async (
  fileName: string,
  file: File,
  progressFn?: (newProgress: number) => void,
  blob?: Blob,
): Promise<string> => {
  try {
    const ref = getRef(fileName)

    const uploadTask = uploadBytesResumable(ref, blob || file, {
      contentType: file.type,
    })

    const downloadUrl = await new Promise<string>((resolve) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.max(
            1,
            Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
          )
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
          }
          if (progressFn) progressFn(progress)
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error)
          resolve('')
        },
        async () => {
          const newUrl = await getDownloadURL(ref)
          resolve(newUrl || '')
        },
      )
    })

    return downloadUrl
  } catch (e) {
    console.log('upload failed', e)
    Sentry.captureException(e)
    return ''
  }
}
