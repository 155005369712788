// import { useUser } from './useUser'

export enum LoginResponse {
  GetCoinbaseFailed,
  SigningFailed,
  UserDoesNotExist,
  NonceFailed,
  AuthenticateFailed,
  Success,
}

export const useLogin = () => {
  // const { contract } = useBlockchain()
  // const { setLoggedIn } = useUser()

  const login = async (): Promise<LoginResponse> => {
    // const success = await contract.login()

    // if (success) {
    //   const userId = await contract.getUserId()

    //   if (userId > 0) {
    //     const coinbase = await eth.getCoinbase()
    //     if (!coinbase) {
    //       return LoginResponse.GetCoinbaseFailed
    //     }

    //     const publicAddress = coinbase.toLowerCase()
    //     const { nonce, error: nonceError } = await postNonce(
    //       userId,
    //       publicAddress,
    //     )
    //     let signature: string = ''

    //     if (nonceError) {
    //       return LoginResponse.NonceFailed
    //     }

    //     try {
    //       signature = await eth.personal.sign(
    //         createTextSignature(nonce),
    //         publicAddress,
    //         '', // MetaMask will ignore the password argument here
    //       )
    //     } catch (err) {
    //       return LoginResponse.SigningFailed
    //     }

    //     const { accessToken, error: authError } = await postAuthenticate(
    //       userId,
    //       publicAddress,
    //       signature,
    //     )

    //     if (authError) {
    //       return LoginResponse.AuthenticateFailed
    //     }

    //     window.localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)

    //     setLoggedIn(true)

    //     return LoginResponse.Success
    //   }
    // }

    if (true) {
      // do ntohing
      //
      const a = 1
      const b = a - 1
      console.log(b)
    }

    return LoginResponse.UserDoesNotExist
  }

  return {
    login,
  }
}
