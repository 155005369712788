export const TRANSLATIONS_ZH = {
  homePageTitle: '每个人的去中心化社交媒体',
  footer: '由<1>@DSocialApp</1> 构建',

  headerMyChannel: '我的频道',
  headerUpload: '上传',
  headerMyAccount: '我的账户',
  headerSignUpOrLogin: '注册登录',

  accountErrorUserNameEmpty: '请填入一个用户名',
  accountErrorProfilePhotoFailed:
    'New profile photo failed to upload, please try again',
  accountErrorUserNameAlreadyTaken: '新的个人资料照片上传失败，请重试',
  accountError: '发生错误，请重试',
  accountErrorValidImage: '请选择一个有效的 JPG/PNG 文件',
  accountErrorFailedResize: '无法调整照片大小，请重试',
  accountPageTitle: '我的账户',
  accountUpdated: '您的帐户现已更新',
  accountUserNameLabel: '用户名',
  accountChannelNameLabel: '频道名称',
  accountProfilePhotoLabel: '头像照片',
  accountResizingPhoto: '调整照片大小...',
  accountDone: '完毕',
  accountCancel: '取消',
  accountLoadingPhoto: '正在加载照片...',
  accountChoosePhoto: '选择照片',
  accountUploadingPhoto: '正在上传个人资料照片...',
  accountUpdatingAccountDetails: '正在更新帐户详细信息...',
  accountUpdateAccountDetails: '更新帐户详细信息',
  accountLogout: '登出',

  channelFailedToLoad: '加载频道信息失败，请刷新页面',
  channelFailedToLoadVideos: '加载视频失败，请刷新页面',
  subscribers: '{{number}} 个订阅者',
  subscribersK: '{{number}}K 个订阅者',
  subscribersM: '{{number}}M 个订阅者',
  subscribersNone: '没有订阅者',
  subscribe: '订阅',
  subscribed: '已订阅',
  channelNoVideos: '还没有上传视频',

  views: '{{number}} 次观看',
  viewsK: '{{number}}K 次观看',
  viewsM: '{{number}}M 次观看',
  viewsNone: '没有意见',

  loginPageTitle: '登录/注册',
  loginIfNotRegistered: '如果您之前没有注册过：',
  loginSignUpButton: '注册',
  loginIfRegistered: '如果您已经注册',
  loginButton: '登录',

  uploadDropHere: '丢这里...',
  uploadFailedResize: '无法调整照片大小，请重试',
  uploadErrorSelectThumbnail: '请选择缩略图（JPG/PNG，1280x720）',
  uploadErrorTransactionId: '请输入视频ID',
  uploadErrorVideoFile: '请选择一个视频（仅限 MP4，最大 1080p）',
  uploadErrorFailedUpload: '缩略图上传失败，请重试...',
  uploadErrorVideoFailedUpload: '视频上传失败，请重试...',
  uploadPageTitle: '上传您的视频',
  uploadInstallArConnect:
    '请先安装 ArConnect 扩展。 如果您还没有，请按照<1>入门</1>指南进行操作',
  uploadFirstTime:
    '如果这是您第一次上传到 DSocial，请先阅读此<1>入门</1>指南。',
  uploadThumbnailUploaded: '已上传缩略图',
  uploadThumbnailUploading: '缩略图上传中，{{progress}}% 已完成...',
  uploadVideoUploaded: '视频上传',
  uploadVideoUploading: '视频上传中，{{progress}}% 已完成...',
  uploadVideoCreated: '视频已创建',
  uploadVideoCreating: '正在创建视频记录...',
  uploadSelectThumbnail: '选择缩略图：',
  uploadResizingPhoto: '调整照片大小...',
  uploadDone: '完毕',
  uploadCancel: '取消',
  uploadDropLabel: '将您的缩略图放在这里（最好是 1280x720）...',
  uploadSelectThumbnailError: '请选择 JPG/PNG 缩略图',
  uploadYourVideoWith:
    'Upload your video with <1>DSocial Uploader App</1>, once uploaded, copy the video ID and paste below.',
  uploadSelectVideo: '选择视频（仅限 MP4，最大 1080p）：',
  uploadPlaceholderTransactionId: '在此处粘贴您的视频 ID...',
  uploadDropVideo: '将您的视频放在这里（仅限 MP4，最大 1080p）...',
  uploadErrorDropVideo: '请选择视频（仅限 MP4，最大 1080p）',
  uploadEnterTitle: '输入视频标题：',
  uploadPlaceholderTitle: '输入视频标题...',
  uploadEnterVideoDesc: '输入视频描述（可选）：',
  uploadPlaceholderDesc: '输入说明...',
  uploadButton: '上传',
  uploadFileTooBig: '视频上传限制为2GB',

  uploaderAppPageTitle: '下载 DSocial 上传器应用程序',
  uploaderAppDesc:
    'DSocial 将权力还给人民。 使用社交上传应用程序处理准备流式传输的视频并将您的视频直接上传到 ARWeave 区块链',
  uploaderAppDownload: '下载 {{os}}',
  uploaderAppNotAvailable:
    'DSocial Uploader App 目前仅适用于 Mac，Windows 和 Linux 版本即将推出。',

  video1Like: '1 喜欢',
  videoLikes: '{{likes}} 点赞',
  videoLike: '喜欢',
  videoComments: '{{comments}} 评论',
  videoAddPublicComment: '添加公开评论...',
  videoCancelButton: '取消',
  videoSubmitButton: '评论',

  gettingStartedTitle: '作为创作者入门',
  gettingStartedPara1: '欢迎！',
  gettingStartedPara2:
    'DSocial 上的视频和缩略图存储在 ARWeave 区块链上。 这是一个专门用于持久存储的抗审查区块链，这意味着您的视频和缩略图将比您活得更久。 因此，您需要一个带有 $AR 代币的钱包才能将视频上传到 DSocial，别担心，这很简单。 如果您有任何问题，请通过 Twitter 上的 <1>@DSocialApp</1> 与我们联系。',
  gettingStartedPara3:
    '如果您的钱包中已经有 $AR 代币，您可以返回<1>上传页面</1>',
  gettingStartedHeading1: '第 1 步：安装 ArConnect 扩展并创建/加载您的钱包',
  gettingStartedPara4:
    '安装 ArConnect 扩展来管理您的数字钱包。 它很容易在任何 Chrome/Firefox 浏览器上使用。',
  gettingStartedGetArConnect: '在 ArConnect 上创建/加载钱包',
  gettingStartedHeading2: '第 2 步：使用 $AR 代币充值钱包',
  gettingStartedPara5:
    '使用 Ramp Network 或 Transak 可以轻松地使用借记卡/信用卡添加 $AR 代币。 但是，如果您更喜欢使用其中一个交易所，ARDrive 会在<1>此处</1>汇总一份支持 $AR 代币的有用交易所列表。',
  gettingStartedPara6:
    '注意：DSocial 不会从您使用这些服务中获得任何佣金或利益。 这些只是充值 $AR 代币的最简单方法。',
  gettingStartedTransak: '使用 Transak 购买 AR',
  gettingStartedRamp: '使用 RampNetwork 购买 AR',
  gettingStartedHeading3: '第 3 步：安装 DSocialUploaderApp',
  gettingStartedPara7: '要上传视频，您需要安装 <1>DSocial Uploader App</1>',
  gettingStartedHeading4: '第 3 步：将您的视频添加到社交',
  gettingStartedPara8: '返回<1>上传页面</1>并上传您的第一个视频！',

  notFoundError: '错误发生',
  notFoundTitle: '找不到网页',
  notFoundContent: '找不到此页面。 返回<1>主页</1>',

  topTitle: '热门频道',

  searchPlaceholder: '搜索视频...',

  sorting_recommended: '订阅',
  sorting_hot: '趋势',
  sorting_most_viewed: '最受关注',
  sorting_top_rated: '评分最高',
  sorting_newest: '最新',
  sorting_daily: '日常',
  sorting_weekly: '每周',
  sorting_monthly: '月刊',
  sorting_yearly: '每年',
  sorting_all_time: '整天',

  feedbackSendFeedback: '发送反馈',
  feedbackCloseForm: '关闭表格',
  feedbackFeedbackType: '类别',
  feedbackName: '姓名',
  feedback: '回馈',
  feedbackPlaceholder: '在此处输入您的反馈...',
  feedbackSendFeedbackFormButton: '发送反馈 👋',
  feedbackFeedbackType_general: '一般的',
  feedbackFeedbackType_bug: '漏洞',
  feedbackFeedbackType_idea: '主意',
  feedbackSubmitted: '谢谢！ 已提交反馈',

  sideMenuHome: '家',
  sideMenuUpload: '上传',
  sideMenuMyChannel: '我的频道',
  sideMenuMyAccount: '我的账户',
  sideMenuLoginOrRegister: '登录',
  sideMenuOpen: '打开',
  sideMenuClose: '关闭',
  sideMenuReviewFlagged: '查看标记的内容',
  sideMenuLogout: '登出',
}
