import { ReactNode, useState } from 'react'
import { useInViewEffect } from 'react-hook-inview'
import { IS_LOCAL } from '../../config'

import getDt from '../../lib/dt'
import { getBackend } from '../../lib/icp'

const trackCount: { [videoId: string]: number } = {}

const trackImpression = async (videoId: string) => {
  if (IS_LOCAL) return
  const start = Date.now()
  trackCount[videoId] = trackCount[videoId] || 0
  trackCount[videoId] += 1
  console.log(`tracking impression for ${videoId}`, trackCount[videoId])

  try {
    const backend = await getBackend()
    const caller = await backend.getCallerInfo()
    const res = await backend.incrementImpressions(
      videoId,
      caller.isAnonymous(),
      getDt(),
    )
    console.log(`incrementImpressions took ${Date.now() - start}ms`)
  } catch (e) {
    console.warn('incrementImpressions failed', e)
  }
}

interface TrackImpressionProps {
  videoId: string
  children: ReactNode
  className?: string
  style?: any
}

const TrackImpression = ({
  videoId,
  children,
  className,
  style,
}: TrackImpressionProps) => {
  const [lastTrack, setLastTrack] = useState(0)
  const [trackTimeout, setTrackTimeout] = useState<NodeJS.Timeout | null>()
  const ref = useInViewEffect(
    ([entry]) => {
      if (entry.isIntersecting) {
        // console.log(`TrackImpression, videoId: ${videoId}: IN VIEW`)
        if (
          !trackTimeout &&
          (lastTrack === 0 || lastTrack < Date.now() - 10000)
        ) {
          // console.log(`TrackImpression, videoId: ${videoId}: QUEUING TRACKING`)
          const cb = setTimeout(() => {
            trackImpression(videoId)
            setTrackTimeout(null)
          }, 1000)
          setTrackTimeout(cb)
          setLastTrack(Date.now())
        }
      } else {
        if (trackTimeout) {
          // console.log(`TrackImpression, videoId: ${videoId}: CLEARING TRACKING TIMEOUT`)
          clearTimeout(trackTimeout)
        }
      }
    },
    { threshold: 0.5 },
    [lastTrack, trackTimeout],
  )

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  )
}

export default TrackImpression
