import { ReactNode, useCallback, useState } from 'react'
import classname from 'classnames'

import { useTranslation, useUser } from '../../hooks'

import styles from './Feedback.module.scss'
import { getFeedbackBackend } from '../../lib/icp'

interface ItemProps {
  label: string
  children: ReactNode
  htmlFor?: string
}

const Item = ({ label, children, htmlFor }: ItemProps) => (
  <div className={styles.item}>
    <label htmlFor={htmlFor}>{label}</label>
    {children}
  </div>
)

const types = ['general', 'bug', 'idea']

const Feedback = () => {
  const embedMode = window.location.pathname.toLowerCase().startsWith('/embed')
  const { userData } = useUser()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [currentType, setType] = useState(types[0])
  const [name, setName] = useState(userData?.userName || '')
  const [feedback, setFeedback] = useState('')

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const submit = useCallback(async () => {
    setSubmitted(true)
    setTimeout(() => setOpen(false), 2500)
    const backend = await getFeedbackBackend()
    const result = await backend.addFeedback(currentType, name, feedback)
  }, [currentType, name, feedback])

  if (embedMode) return null

  return (
    <div className={styles.container}>
      <form className={open ? styles.open : ''}>
        {!submitted ? (
          <>
            <Item label={t('feedbackFeedbackType')}>
              <div className={styles.types}>
                {types.map((type, i) => (
                  <div
                    className={classname(styles.feedbackType, {
                      [styles.first]: i === 0,
                      [styles.last]: i === types.length - 1,
                      [styles.selected]: type === currentType,
                    })}
                    onClick={() => setType(type)}
                  >
                    {t(`feedbackFeedbackType_${type}`)}
                  </div>
                ))}
              </div>
            </Item>
            <Item label={t('feedbackName')} htmlFor="name">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
              />
            </Item>
            <Item label={t('feedback')} htmlFor="feedback">
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                id="feedback"
                placeholder={t('feedbackPlaceholder')}
              />
            </Item>
            <button onClick={submit}>
              {t('feedbackSendFeedbackFormButton')}
            </button>
          </>
        ) : (
          <div className={styles.submitted}>
            <p>{t('feedbackSubmitted')}</p>
          </div>
        )}
      </form>
      <button onClick={toggleOpen}>
        {open ? t('feedbackCloseForm') : t('feedbackSendFeedback')}
      </button>
    </div>
  )
}

export default Feedback
